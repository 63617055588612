import { useQuery } from 'react-query';
import { GET_BUSINESS_WEBSHOP_CATEGORIES, getBusinessWebshopCategories } from '../endpoints';
import { useBusiness } from '@/web/hooks/use-business';

export const useWebshopCategories = ({ page, size }: { page: number; size: number }) => {
  const { businessUuid } = useBusiness();
  const { data: categories, isFetching: isFetchingCategories } = useQuery(
    [GET_BUSINESS_WEBSHOP_CATEGORIES, businessUuid],
    async () => {
      if (businessUuid) {
        return getBusinessWebshopCategories(businessUuid, { page, size });
      }

      return null;
    },
    { staleTime: Infinity },
  );

  return {
    categories: (categories?.content || []).filter((cat) => !!cat.name),
    isFetchingCategories,
  };
};
