import { getTranslatorStringForPlurals } from '@/common/utils';
import { BringAFriendHeader } from '@/features/bring-a-friend/components/BringAFriendHeader/BringAFriendHeader';
import { BringAFriendInvitedList } from '@/features/bring-a-friend/components/BringAFriendInvitedList/BringAFriendInvitedList';
import { BringAFriendOverviewBasicCard } from '@/features/bring-a-friend/components/BringAFriendOverviewCards/BringAFriendOverviewBasicCard';
import { InvitedFriend } from '@/features/bring-a-friend/types/InvitedFriend';
import { useTranslation } from 'react-i18next';

interface Props {
  percentage: number;
  invitesLeft: number;
  totalInvites: number;
  invitations: InvitedFriend[];
}

export const BringAFriendOverviewBasic = ({ percentage, invitesLeft, totalInvites, invitations }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mt-6 mb-8">
        <BringAFriendHeader
          title={t(getTranslatorStringForPlurals('invitesLeftThisMonth', invitesLeft !== 1), {
            count: invitesLeft,
          })}
        />
      </div>
      <BringAFriendOverviewBasicCard percentage={percentage} invitesLeft={invitesLeft} totalInvites={totalInvites} />
      <div className="mt-8">
        <BringAFriendInvitedList invitations={invitations} />
      </div>
    </div>
  );
};
