import { Title } from '@/design-system/v3/title';
import desktopAlert from './desktop-alert-1.png';
import desktopAlert2 from './desktop-alert-2.png';

export const DesktopAlert = () => {
  return (
    <div className="h-screen w-screen flex flex-col items-center text-center">
      <div className="max-w-[438px] pt-[80px] flex flex-col items-center">
        <Title className="text-5xl mb-6 leading-[60px]">Find your next class with Gymly</Title>
        <p className="max-w-[368px]">Resize this window or copy link to your mobile for the best experience.</p>
      </div>
      <div className="absolute bottom-0 max-w-[420px] w-full">
        <img src={desktopAlert} className="max-w-[260px] relative z-10" alt="iphone screenshot" />
        <img
          src={desktopAlert2}
          className="max-w-[280px] absolute left-[200px] bottom-0 z-[5]"
          alt="iphone screenshot 2"
        />
        <div
          className="w-[290px] h-[290px] rounded-full absolute z-0 top-[60px] -left-[90px]"
          style={{
            background: 'radial-gradient(76.98% 79.19% at -8.4% 35.89%, #E8CBFF 38.02%, #F6B1E3 100%)',
            filter: 'blur(200px)',
          }}
        />
      </div>
    </div>
  );
};
