import { createContext, ReactNode } from 'react';
import { ManageCardsPanelPage } from '@/web/modules/home-card/enums/ManageCardsPanelPage.enum';

interface ManageCardsPanelContext {
  page: ManageCardsPanelPage;
  setPage: (page: ManageCardsPanelPage) => void;
  onBack: (() => void) | null;
  setOnBack: (onBack: (() => void) | null) => void;
  actions: ReactNode | null;
  setActions: (actions: ReactNode | null) => void;
}

export const ManageCardsPanelContext = createContext<ManageCardsPanelContext>({
  page: ManageCardsPanelPage.START,
  setPage: () => {},
  onBack: null,
  setOnBack: () => {},
  actions: null,
  setActions: () => {},
});
