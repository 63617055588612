type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

export type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

export type UUID = string;
export type DateTime = string;
export type ISOHoursMinutes = `${number}:${number}`;
export type ISOHoursMinutesSeconds = `${number}:${number}:${number}`;
export type ISOWeekRange = IntRange<1, 8>;

export type PeriodType = 'month' | 'quarter' | 'year' | 'custom';

export type Period = {
  type: PeriodType;
  startDate: Date;
  endDate: Date;
};

export interface Paginator<T> {
  content: T[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    pageSize: number;
    pageNumber: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalElements: number;
  totalPages: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  first: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  empty: boolean;
}

export enum ErrorCode {
  BAD_CREDENTIALS = 'bad_credentials',
  VALIDATION_FAILED = 'validation_failed',
  ACCOUNT_TOKEN_EXPIRED = 'account_token_expired',
  ACCOUNT_TOKEN_NOT_FOUND = 'account_token_not_found',
  ACCOUNT_EMAIL_ALREADY_IN_USE = 'account_email_already_in_use',
}

export interface ErrorResponse {
  code: Omit<ErrorCode, ErrorCode.VALIDATION_FAILED>;
}

export interface ValidationErrorResponse {
  code: ErrorCode.VALIDATION_FAILED;
  errors: {
    field: string;
    message: string;
  }[];
  message: string;
}

export interface AppLinkEvent {
  url: string;
}
