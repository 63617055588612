import { useQuery } from 'react-query';
import { getTermsAndConditions } from '@/business/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { TermsAndConditions } from '@/web/types';

export const FETCH_BUSINESS_TERMS_AND_CONDITIONS = 'FETCH_BUSINESS_TERMS_AND_CONDITIONS';

export const useTermsAndConditions = (): {
  termsAndConditions: TermsAndConditions | null;
  isLoading: boolean;
} => {
  const { businessUuid } = useBusiness();

  const { data, isLoading } = useQuery(
    [FETCH_BUSINESS_TERMS_AND_CONDITIONS, businessUuid],
    async () => {
      if (!businessUuid) {
        return;
      }

      return getTermsAndConditions(businessUuid);
    },
    {
      staleTime: 21600, // 6 hours.
    },
  );

  return {
    termsAndConditions: data ?? null,
    isLoading,
  };
};
