import { GInput } from '@/design-system/v3/g-input';
import { useTranslation } from 'react-i18next';
import Button from '@/design-system/v3/button';
import { GSwitch } from '@/design-system/v3/g-switch';
import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { IconWrapper } from '@/common/components/icon-wrapper';
import Upload from '@/common/components/icon/icons/v2/upload.svg?react';
import { useMutation, useQueryClient } from 'react-query';
import { createHomeCard, patchHomeCard, removeHomeCard, uploadHomeCardImage } from '@/web/modules/home-card/api';
import { useBusiness } from '@/web/hooks/use-business';
import { HomeCardType, HomeCardVariant, LinkHomeCard } from '@/web/modules/home-card/types';
import { handleValidationError } from '@/common/utils';
import { Controller, useForm } from 'react-hook-form';
import { useSnack } from '@/common/hooks/use-snack';
import { getLinkValidator, getRequiredValidator } from '@/common/validators';
import { FETCH_BUSINESS_HOME_CARDS } from '@/common/views/home-discovery';
import { ManageCardsPanelPage } from '@/web/modules/home-card/enums/ManageCardsPanelPage.enum';
import { ManageCardsPanelContext } from '@/web/modules/home-card/panel/ManageCardsPanelContext';

enum Step {
  DETAILS,
  IMAGE,
}

type Props = {
  onConfirm: () => void;
  card?: LinkHomeCard | null;
};

type FormData = {
  label: string;
  url: string;
  newWindow: boolean;
};

export const AddEditLinkCardPage = ({ onConfirm, card }: Props) => {
  const { businessUuid } = useBusiness();
  const { t } = useTranslation();
  const { successMessage, errorMessage } = useSnack();
  const { setPage, setOnBack, setActions } = useContext(ManageCardsPanelContext);
  const queryClient = useQueryClient();
  const [step, setStep] = useState<Step>(Step.DETAILS);
  const [preview, setPreview] = useState<string | null>(card?.imageUrl || null);
  const [image, setImage] = useState<File | null>(null);
  const el = useRef<HTMLInputElement | null>(null);

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      label: card?.label,
      url: card?.link.url,
      newWindow: card?.link.newWindow ?? false,
    },
  });

  const handleImageUpload = () => {
    if (el.current) {
      if (el.current.hasAttribute('capture')) {
        el.current.removeAttribute('capture');
      }
      el.current.click();
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setPreview(URL.createObjectURL(file));
      setImage(file);
    }
  };

  const { mutate, isLoading } = useMutation<unknown, unknown, FormData>(async (data) => {
    if (!businessUuid || (!card && !image)) {
      return;
    }

    let newCard: HomeCardVariant;

    try {
      if (card) {
        newCard = await patchHomeCard(businessUuid, card.id, {
          label: data.label,
          link: {
            url: data.url,
            newWindow: data.newWindow,
          },
        });
      } else {
        newCard = await createHomeCard(businessUuid, {
          ...data,
          type: HomeCardType.LINK,
        });
      }
    } catch (e) {
      handleValidationError(e, setError, errorMessage);
      return;
    }

    if (image) {
      try {
        await uploadHomeCardImage(businessUuid, newCard.id, image);
      } catch (e) {
        handleValidationError(e, setError, errorMessage);
        return;
      }
    }

    onConfirm();
    successMessage('homeCard.snack.success.title', {
      detailedMessage: 'homeCard.snack.success.message',
    });
    queryClient.invalidateQueries([FETCH_BUSINESS_HOME_CARDS, businessUuid]);
  });

  const { mutate: removeCard, isLoading: isRemoving } = useMutation(async () => {
    if (!card) {
      return;
    }

    try {
      await removeHomeCard(businessUuid as string, card.id);
    } catch (e) {
      handleValidationError(e, undefined, errorMessage);
      return;
    }

    queryClient.invalidateQueries([FETCH_BUSINESS_HOME_CARDS, businessUuid]);
    setPage(ManageCardsPanelPage.ALL_CARDS);
  });

  useEffect(() => {
    setOnBack(() => () => {
      if (step === Step.IMAGE) {
        setStep(Step.DETAILS);
      } else {
        setPage(ManageCardsPanelPage.START);
      }
    });
  }, [step, setOnBack, setPage]);

  useEffect(() => {
    setActions(null);
  }, [setActions]);

  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-col flex-1 space-y-5 text-typo-primary">
        {step === Step.DETAILS && (
          <>
            <div className="flex flex-col space-y-5 px-4">
              <GInput
                {...register('url', { ...getLinkValidator() })}
                type="url"
                title={t('homecard.addLinkCard.link.title')}
                helperText={t('homecard.addLinkCard.link.helperText')}
                placeholder="https://"
                error={errors.url?.message?.toString()}
                required
              />

              <GInput
                {...register('label', { ...getRequiredValidator() })}
                title={t('homecard.addLinkCard.label.title')}
                helperText={t('homecard.addLinkCard.label.helperText')}
                error={errors.label?.message?.toString()}
                required
              />
            </div>

            <div className="relative flex flex-row items-center justify-between space-x-4 border-y border-gray-200 p-4">
              <div>{t('openInNewWindow')}</div>
              <div>
                <Controller
                  name="newWindow"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <label htmlFor="add-link-card-open-new-tab">
                      <GSwitch id="add-link-card-open-new-tab" checked={value} onChange={onChange} />
                    </label>
                  )}
                />
              </div>
            </div>
          </>
        )}

        {step === Step.IMAGE && (
          <div className="flex items-center justify-between px-4 border-b border-gray-200 pb-4">
            <div className="flex items-center justify-center h-16 w-24 rounded-lg overflow-hidden bg-gray-50">
              {preview && <img src={preview} alt="preview" className="h-full w-full object-cover" />}
            </div>
            <div>
              <input
                ref={el}
                type="file"
                accept="image/*"
                id="uploadImage"
                className="hidden"
                onChange={handleImageChange}
              />
              <Button
                variant="secondary"
                onClick={handleImageUpload}
                className="justify-start"
                startIcon={<IconWrapper icon={Upload} />}
              >
                {t('uploadPicture')}
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col items-center mt-4 px-4 space-y-3">
        {step === Step.DETAILS && (
          <div className="flex flex-col space-y-2 w-full">
            <Button variant="primary" disabled={!isValid || isRemoving} onClick={() => setStep(Step.IMAGE)} fullWidth>
              {t('nextStep')}
            </Button>
            {card && (
              <Button
                variant="secondary"
                className="text-typo-negative focus:text-typo-negative hover:text-typo-negative"
                onClick={removeCard}
                loading={isRemoving}
                fullWidth
              >
                {t('remove')}
              </Button>
            )}
          </div>
        )}
        {step === Step.IMAGE && (
          <Button
            type="submit"
            variant="primary"
            disabled={!isValid || (!card && !image) || isRemoving}
            loading={isLoading}
            onClick={handleSubmit(mutate)}
          >
            {t('confirm')}
          </Button>
        )}

        <div className="flex flex-row space-x-2">
          <div
            className={clsx('w-2 h-2 rounded-full', {
              'bg-primary': step === Step.DETAILS,
              'bg-gray-300': step !== Step.DETAILS,
            })}
          />
          <div
            className={clsx('w-2 h-2 rounded-full', {
              'bg-primary': step === Step.IMAGE,
              'bg-gray-300': step !== Step.IMAGE,
            })}
          />
        </div>
      </div>
    </div>
  );
};
