export const gStore = 'gymly.';

const DEMO_BASE = 'https://demo-dot-gymly-337710.ew.r.appspot.com';
const isDemo = window.location.origin.includes('demo.');
export const BASE = isDemo ? DEMO_BASE : import.meta.env.VITE_APP_API_URL;
export const TOKEN_STORE = gStore + 'token';
export const API = `${BASE}/api`;
export const DEFAULT_TABLE_ROW_SIZE = 10;

export const ONBOARDING_BUSINESS_ID = gStore + 'onboardingBusinessId';
export const ONBOARDING_BUSINESS_LOCATION_ID = gStore + 'onboardingBusinessLocationId';
export const ONBOARDING_BUSINESS_MEMBERSHIP_ID = gStore + 'onboardingBusinessMembershipId';

export const LAST_SELECTED_BUSINESS_UUID = gStore + 'lastSelectedBusinessUuid';
export const MENU_COLLAPSED_STORE = gStore + 'menuCollapsed';
export const REPORT_MENU_COLLAPSED_STORE = gStore + 'reportMenuCollapsed';
