import { isToday, isYesterday, parseISO } from 'date-fns';
import { GAvatar } from '@/design-system/g-avatar';
import clsx from 'clsx';
import Text from '@/design-system/v3/text';
import { Notification } from '@/web/views/inbox/types';
import i18next from 'i18next';
import { formatWithT } from '@/i18n/utils';

export const formatNotifDate = (datestring: string) => {
  const parsed = parseISO(datestring);

  return isToday(parsed)
    ? i18next.t('bookingFlow.navigation.today')
    : isYesterday(parsed)
      ? i18next.t('bookingFlow.navigation.yesterday')
      : formatWithT(parsed, 'MMM dd');
};

const shorterText = (content: string, limit = 200) => {
  return content.length > limit - 10 ? `${content.substring(0, limit)}...` : content;
};

interface Props {
  notification: Notification;
  onClick: () => void;
}

export const InboxItem = ({ notification, onClick }: Props) => {
  const imageId = notification?.creator?.profileImageId;
  const path = imageId ? `/api/v1/user/media/images/${imageId}` : null;
  const itemClasses = clsx(
    'relative text-left p-5 w-full border-b border-borders-secondary break-all active:bg-gray-100',
  );
  return (
    <button key={notification.notificationId} className={itemClasses} onClick={onClick}>
      <div className="absolute flex items-center top-5 right-5 text-typo-secondary gap-2">
        <Text variant="extra-small">{formatNotifDate(notification.createdAt)}</Text>
        {notification?.notificationId && !notification.read && (
          <div className="w-2 h-2 min-w-[8px] bg-error-300 rounded-full" />
        )}
      </div>
      <div className="flex flex-row space-x-4">
        <GAvatar path={path} />
        <div className={clsx('flex flex-col', notification.read ? 'text-typo-secondary' : 'text-typo-primary')}>
          <Text variant="small">{notification.creator.fullName}</Text>
          <Text variant="small" className="max-h-[40px] overflow-hidden">
            {shorterText(notification.content)}
          </Text>
        </div>
      </div>
    </button>
  );
};
