import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface TabsProps {
  layoutId?: string;
  className?: string;
  tabs: {
    content: ReactNode;
    isSelected: boolean;
    onClick: () => void;
  }[];
}

const getAllOffsetWidth = (el: HTMLElement | null, width = 0): number => {
  if (el) {
    width += el.offsetWidth;
    return getAllOffsetWidth(el.previousSibling as HTMLElement, width);
  }

  return width;
};

export const Tabs = ({ tabs, className }: TabsProps) => {
  const [selectedTabRef, setSelectedTabRef] = useState<HTMLButtonElement | null>(null);

  const getTranslateX = () => {
    if (selectedTabRef) {
      return (
        (window.innerWidth - (selectedTabRef.offsetWidth || 0)) / 2 -
        16 -
        getAllOffsetWidth(selectedTabRef.previousSibling as HTMLElement)
      );
    }

    return 0;
  };

  return (
    <div className={twMerge('flex w-full bg-white', className)}>
      <motion.div
        className="flex"
        animate={{
          x: getTranslateX(),
        }}
      >
        {tabs.map((tab, index) => (
          <button
            ref={tab.isSelected ? setSelectedTabRef : null}
            key={`tab-${index}`}
            className={clsx([
              'flex',
              'flex-col',
              'items-center',
              'justify-center',
              'relative',
              'py-4',
              'font-neutral',
              'px-5',
              'text-base',
              'transition-colors',
              'whitespace-nowrap',
            ])}
            disabled={tab.isSelected}
            onClick={tab.onClick}
          >
            <div className={clsx('leading-4', tab.isSelected ? 'text-typo-primary' : 'text-typo-secondary opacity-50')}>
              {tab.content}
            </div>
          </button>
        ))}
      </motion.div>
    </div>
  );
};
