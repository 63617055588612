import axios from 'axios';
import { useQuery } from 'react-query';
import { setInStorage } from '../utils';
import { useSnack } from '../hooks/use-snack';
import Button from '@/design-system/v3/button';
import { useTranslation } from 'react-i18next';

export const VersionCheck = () => {
  const { successMessage } = useSnack();
  const { t } = useTranslation();

  useQuery(
    ['version-check'],
    async () => {
      const response = await axios.get<{ version: string }>('/version.json');
      const nextVersion = response.data.version;
      const currentVersion = localStorage.getItem('version');

      if (!currentVersion) {
        setInStorage('version', nextVersion);

        return false;
      }

      if (currentVersion !== nextVersion) {
        successMessage('versionCheck.title', {
          persist: true,
          preventDuplicate: true,
          icon: <div className="text-2xl">✨</div>,
          action: () => {
            return (
              <Button
                className="whitespace-nowrap"
                variant="secondary"
                onClick={() => {
                  setInStorage('version', nextVersion);
                  window.location.reload();
                }}
              >
                {t('getIt')}
              </Button>
            );
          },
        });

        return nextVersion;
      }

      return false;
    },

    {
      staleTime: Infinity,
      refetchInterval: 60000,
    },
  );

  return null;
};
