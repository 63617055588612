import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';

type Props = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  description?: string;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * TODO: Implement focus state.
 */
export const GSwitch = forwardRef<HTMLInputElement, Props>(({ title, description, onChange, ...rest }, ref) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    triggerHapticFeedback(HapticFeedbackType.IMPACT_MEDIUM);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="inline-flex flex-row space-x-3 cursor-pointer">
      <div className="relative block h-6 w-10 rounded-full group/switch">
        <input type="checkbox" className="-left-[9999px] absolute peer" onChange={handleOnChange} ref={ref} {...rest} />
        <span
          className={clsx(
            'absolute peer z-10 inset-y-0 top-0.5 left-0.5 inline-block h-5 w-5 rounded-full transition-all duration-300 shadow-md bg-white translate-x-0',
            'peer-checked:translate-x-4',
          )}
        />
        <div
          className={clsx(
            'absolute left-0 inline-block h-6 w-full align-middle select-none transition duration-200 ease-in rounded-full bg-gray-100',
            'group-hover/switch:bg-gray-200',
            'peer-checked:bg-primary-600 peer-checked:hover:bg-primary-700',
          )}
        ></div>
      </div>
      {title && (
        <div className="flex flex-col">
          <span className="font-medium text-gray-700">{title}</span>
          {description && <span className="text-gray-700">{description}</span>}
        </div>
      )}
    </div>
  );
});

GSwitch.displayName = 'GSwitch';
