import styled from 'styled-components';

export const REGULAR_FONT_FAMILY = 'Inter';

const TextBase = styled('div')`
  line-height: 1.5;
`;

export const Text16 = styled(TextBase)`
  font-size: 16px;
  line-height: 24px;
`;

export const Text14 = styled(TextBase)`
  font-size: 14px;
  line-height: 22px;
`;

export const Text12 = styled(TextBase)`
  font-size: 12px;
`;

export const Text11 = styled(TextBase)`
  font-size: 11px;
`;
