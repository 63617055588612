import { BusinessRoles, MembershipEntriesControl, MembershipPaymentType, Role } from '@/web/types';
import { DateTime } from '../common/types';

export type Roles = 'ROLE_ADMIN' | 'ROLE_USER';

export interface JWT {
  email: string;
  roles: Array<Roles>;
  sub: string;
  iat: number;
  exp: number;
}

export enum Language {
  EN = 'EN', // English
  NL = 'NL', // Dutch
  DE = 'DE', // German
}

export interface LoginResponse {
  jwt: string;
  account: LoginAccount;
}

export interface LoginAccount {
  id: string;
  email: string;
  roles: Array<Roles>;
}

export interface MembershipConsumptionDetails {
  type: MembershipEntriesControl;
  limit: number;
  consumed: number;
}

export interface UserBusinessMembership {
  id: string;
  membershipId: string;
  name: string;
  type: MembershipPaymentType;
  entries: number | null;
  consumed: number | null;
  consumptionDetails: MembershipConsumptionDetails | null;
  expiresDays: number;
  expiresAt: DateTime;
  entriesControl: MembershipEntriesControl;
  timeslots: {
    time: string[];
    days: number[];
  }[];
}

export interface BusinessUserStamp {
  entries: number;
  consumed: number;
}

export interface UserBusiness {
  businessId: string;
  businessIcon: string | null;
  businessLogo: string | null;
  businessName: string;
  businessUserId: string;
  primaryLocationId: string;
  stamps: BusinessUserStamp[];
  userType: Role;
  memberships: UserBusinessMembership[];
}

export interface Address {
  street: string;
  city: string;
  postalCode: string;
  country: string;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export const GenderToLabel: { [key in Gender]: string } = {
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female',
  [Gender.OTHER]: 'Other',
  [Gender.NOT_SPECIFIED]: 'Not specified',
};

export interface User {
  id: string;
  username: string;
  privacyPreferences: {
    anonymous: boolean;
    showPicture: boolean;
    showFullName: boolean;
  } | null;
  firstName: string;
  insertion: string | null;
  lastName: string;
  fullName: string;
  displayName: string;
  dateOfBirth: string;
  address: null | Address;
  email: string;
  roles: Array<Roles>;
  profileImagePath: null | string;
  profileImageUrl: null | string;
  onboarded: boolean;
  createdAt: string;
  updatedAt: string;
  gender: Gender | null;
  language: Language;
  phoneNumber: string | null;
  businesses: Array<UserBusiness>;
  businessRoles: Array<BusinessRoles>;
}

export interface Goals {
  weeklyVisits: null | number;
}

export interface ProrateResult {
  daysLeft: number;
  amount: number;
  discountAmount: number | null;
  fullAmount: number;
}
