import { DatepickerProps, GDatepicker } from '@/design-system/v3/g-datepicker';

interface Props extends Omit<DatepickerProps, 'value'> {
  gtitle?: string;
  value: string;
  optionalLabel?: boolean;
}

const BasicDatePicker = ({ gtitle, title, ...props }: Props) => {
  return <GDatepicker {...props} title={title || gtitle} />;
};

export default BasicDatePicker;
