import React, { useCallback, useEffect, useState } from 'react';
import { GLoader } from '../../../design-system/g-loader';
import { Paginator } from '@/common/types';

export type ResultWithTokenPagination<T> = {
  data: T[];
  token: string | null;
};

export type FetchWithTokenPagination<T> = (token: string | null) => Promise<ResultWithTokenPagination<T>>;

export type FetchWithPagePagination<T> = (page: number, size: number) => Promise<Paginator<T>>;

const isFetchWithTokenPagination = <T,>(
  fetchData: FetchWithTokenPagination<T> | FetchWithPagePagination<T>,
): fetchData is FetchWithTokenPagination<T> => {
  return (fetchData as FetchWithTokenPagination<T>).length === 1;
};

const isFetchWithPagePagination = <T,>(
  fetchData: FetchWithTokenPagination<T> | FetchWithPagePagination<T>,
): fetchData is FetchWithPagePagination<T> => {
  return (fetchData as FetchWithPagePagination<T>).length === 2;
};

type Props<T> = {
  container: HTMLDivElement;
  fetchData: FetchWithTokenPagination<T> | FetchWithPagePagination<T>;
  render: (item: T) => React.ReactElement;
  getId: (item: T) => string;
  onEmpty?: React.ReactElement;
  pageSize?: number;
};

export const InfiniteScroll = <T,>({ fetchData, container, render, getId, onEmpty, pageSize }: Props<T>) => {
  const [data, setData] = useState<T[]>([]);
  const [token, setToken] = useState<string | null>(null);
  const [page, setPage] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ended, setEnded] = useState(false);

  const fetchNextDataWithPage = useCallback(async () => {
    if (isLoading || !isFetchWithPagePagination(fetchData)) return;

    const newPage = page === null ? 0 : page + 1;

    setIsLoading(true);
    const newData = await fetchData(newPage, pageSize ?? 10);
    setData((prevData) => [...prevData, ...newData.content]);

    if (newData.content.length === 0) {
      setEnded(true);
    }

    setPage(newPage);
    setIsLoading(false);
  }, [page, pageSize, isLoading, fetchData]);

  const fetchNextDataWithToken = useCallback(
    async (customToken?: string | null) => {
      if (isLoading || !isFetchWithTokenPagination(fetchData)) return;

      setIsLoading(true);
      const newData = await fetchData(customToken === undefined ? token : customToken);
      setData((prevData) => [...prevData, ...newData.data]);

      if (newData.data.length === 0) {
        setEnded(true);
      }

      setToken(newData.token);
      setIsLoading(false);
    },
    [token, fetchData, isLoading],
  );

  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = container;

    if (scrollTop + clientHeight >= scrollHeight) {
      fetchNextDataWithPage();
      fetchNextDataWithToken();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetchNextDataWithToken, container, isLoading]);

  useEffect(() => {
    if (ended) {
      return;
    }

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [handleScroll, ended]);

  useEffect(() => {
    setData([]);

    fetchNextDataWithPage();
    fetchNextDataWithToken(null);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetchData]);

  return (
    <>
      {data.map((item) => (
        <div key={getId(item)}>{render(item)}</div>
      ))}
      {!isLoading && data.length === 0 && onEmpty}
      {isLoading && (
        <div className="flex w-full justify-center">
          <GLoader />
        </div>
      )}
    </>
  );
};
