import { useAuth } from '@/auth/hooks/use-auth';
import { useBusiness } from '@/web/hooks/use-business';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SHOP_PATH } from '@/web/routes';
import { GProgressBar } from '@/design-system/v3/g-progress-bar/GProgressBar';
import { MembershipConsumptionDetails } from '@/auth/types';
import { MembershipEntriesControl } from '@/web/types';
import { lowerCaseFirstLetter } from '@/common/utils';

export const ActiveProducts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { memberships } = useAuth();
  const { hasPurchasableMemberships } = useBusiness();

  const consumableMemberships = (memberships || []).filter((membership) => {
    return membership.consumptionDetails !== null;
  });

  const handleViewProducts = () => navigate(`${SHOP_PATH}`);

  if (consumableMemberships.length === 0) {
    return <></>;
  }

  return (
    <div className="px-4">
      <span className="flex text-typo-secondary font-medium uppercase text-sm mb-2">
        {t('profile.activeProducts.title')}
      </span>
      <div className="flex flex-col border border-gray-200 rounded-lg bg-white shadow-sm">
        {consumableMemberships.map((userMembership, i) => {
          const details = userMembership.consumptionDetails as MembershipConsumptionDetails;
          const percentage = (1 - details.consumed / details.limit) * 100;

          return (
            <div
              key={`${userMembership.id}-${i}`}
              className="flex flex-col space-y-2 px-5 pt-4 pb-2 border-b border-gray-200"
            >
              <div className="text-gray-900 font-semibold">{userMembership.name}</div>
              <GProgressBar size="sm" percentage={percentage} />
              <div className="flex justify-between items-center text-typo-tertiary text-sm">
                <span>
                  {details.consumed}/{details.limit}
                  {details.type !== MembershipEntriesControl.FIXED_AMOUNT && ' '}
                  {details.type === MembershipEntriesControl.DAILY && lowerCaseFirstLetter(t('perDay'))}
                  {details.type === MembershipEntriesControl.WEEKLY && lowerCaseFirstLetter(t('perWeek'))}
                  {details.type === MembershipEntriesControl.MONTHLY && lowerCaseFirstLetter(t('perMonth'))}
                </span>
                <span>{t('nRemaining', { count: details.limit - details.consumed })}</span>
              </div>
            </div>
          );
        })}
        {hasPurchasableMemberships && (
          <div className="flex justify-center items-center py-2">
            <button className="font-semibold text-secondary hover:text-secondary-800" onClick={handleViewProducts}>
              {t('profile.activeProducts.button')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
