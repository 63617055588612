import { useBusiness } from '@/web/hooks/use-business';
import { CourseCard, CourseCardSkeleton } from '@/common/components/booking-flow/course-card';
import { isSameDay, isToday, isTomorrow } from 'date-fns';
import { useQuery, useQueryClient } from 'react-query';
import { CuteSeparator } from '../course-detail';
import { FETCH_UPCOMING_COURSES, getUserUpcomingCourses } from '@/web/endpoints';
import { NoResultsFound } from '@/common/components/no-results-found';
import { useTranslation } from 'react-i18next';
import { formatWithT } from '@/i18n/utils';
import { CourseMemberStatus } from '@/web/types';
import { useCallback, useMemo } from 'react';
import { ConditionalWrapper } from '@/common/components/ConditionalWrapper';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { BackToButton } from '@/web/views/extra-menu';
import { PullToRefresh } from '@/common/components/pull-to-refresh';

type Props = {
  wrapped?: boolean;
};

export const useUpcomingReservations = () => {
  const { businessUuid, businessActivitiesMap } = useBusiness();
  const { data: courses, isFetching } = useQuery(
    [FETCH_UPCOMING_COURSES, businessUuid, businessActivitiesMap],
    async () => {
      if (businessUuid && businessActivitiesMap) {
        return getUserUpcomingCourses(businessUuid, businessActivitiesMap);
      }

      return null;
    },
  );

  return { courses, isFetching, nrOfCourses: courses?.length || null };
};

export const ReservationView = ({ wrapped = false }: Props) => {
  const { t } = useTranslation();
  const { courses, isFetching } = useUpcomingReservations();
  const queryClient = useQueryClient();

  const handleRefresh = useCallback(() => {
    queryClient.invalidateQueries(FETCH_UPCOMING_COURSES);
  }, [queryClient]);

  const content = useMemo(() => {
    if (isFetching) {
      return (
        <>
          {Array.from({ length: 4 })
            .fill(null)
            .map((_, idx) => (
              <CourseCardSkeleton key={`ccs-${idx}`} />
            ))}
        </>
      );
    }

    if (!(courses && courses.length)) {
      return (
        <div className="flex items-center justify-center h-contentHeight">
          <NoResultsFound label={t('noResults.reservations')} />
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        {courses.map((member, index) => {
          const course = member.courseDetails;
          const isTodayCheck = isToday(course.startAtDate);
          const isTomorrowCheck = isTomorrow(course.startAtDate);
          const dateFormatted = isTodayCheck
            ? t('bookingFlow.navigation.today')
            : isTomorrowCheck
              ? t('bookingFlow.navigation.tomorrow')
              : formatWithT(course.startAtDate, 'MMM dd');
          const prevCourse = courses[index - 1];

          let cuteSeparator: null | JSX.Element = <CuteSeparator label={dateFormatted} />;

          if (prevCourse) {
            if (isSameDay(course.startAtDate, prevCourse.startAtDate)) {
              cuteSeparator = null;
            }
          }

          return (
            <div className="flex flex-col" key={course.uniqueUuid}>
              {cuteSeparator}
              <CourseCard
                course={course}
                withJoinStatus={false}
                cancelled={member.status === CourseMemberStatus.CANCELLED}
              />
            </div>
          );
        })}
      </div>
    );
  }, [courses, isFetching, t]);

  return (
    <ConditionalWrapper
      condition={wrapped}
      render={(children) => (
        <PageWrapper
          header={<PageHeader title={t('homeView.reservations.title')} leftAction={<BackToButton />} />}
          content={children}
          contentClasses="px-0"
        />
      )}
    >
      <PullToRefresh onRefresh={handleRefresh} className="flex flex-col h-contentHeight">
        {content}
      </PullToRefresh>
    </ConditionalWrapper>
  );
};
