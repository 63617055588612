import { onLogout } from '@/auth/utils';
import { useEffect } from 'react';

export const LogoutView = () => {
  useEffect(() => {
    onLogout();
  }, []);

  return null;
};
