import { PageWrapper } from '@/common/components/page-wrapper';
import { ExtraMenu } from '../../extra-menu';
import { ActiveProducts } from '@/web/views/profile/components/active-products';
import { ProfileHeader } from '@/web/views/profile/components/profile-header';

export const ProfileHome = () => {
  return (
    <PageWrapper
      className={window.safeAreaInsets ? 'bg-gray-50' : 'bg-white'}
      contentClasses="px-0"
      content={
        <div className="flex flex-col space-y-6">
          <ProfileHeader />
          <ActiveProducts />
          <ExtraMenu />
        </div>
      }
    />
  );
};
