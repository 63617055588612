import { LAST_SELECTED_BUSINESS_UUID, TOKEN_STORE } from '../common/constants';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { JWT } from './types';
import { removeFromStorage } from '@/common/utils';
import { captureEvent, captureException } from '@sentry/react';

export const getLocalToken = () => localStorage.getItem(TOKEN_STORE);

export const getRequestHeaders = (additionalHeaders = {}) => {
  const token = getLocalToken();

  return {
    Authorization: `Bearer ${token}`,
    ...additionalHeaders,
  };
};

export const isTokenValid = () => {
  try {
    const token = getLocalToken();
    if (token) {
      const now = Math.floor(Date.now() / 1000);
      const jwt: JWT = jwtDecode(token);

      return jwt.exp > now;
    }
  } catch {
    onLogout();
    return false;
  }
};

export const shouldTokenRefresh = () => {
  const token = getDecodedToken();
  if (token) {
    const nowInSeconds = Date.now() / 1000;
    const secondsLimit = 259200; // 3 days

    return token.exp < nowInSeconds + secondsLimit;
  }

  return false;
};

export const getDecodedToken = (): JWT | null => {
  const token = getLocalToken();

  if (token) {
    return jwtDecode(token);
  }

  return null;
};

export const hasToken = () => !!getDecodedToken();

export const onLogout = () => {
  const token = getLocalToken();

  let jwtPayload: JwtPayload | null = null;

  if (token) {
    jwtPayload = jwtDecode(token);
  }

  captureEvent({
    message: 'User logged out',
    level: 'info',
    extra: {
      jwtPayload,
      time: new Date().toISOString(),
    },
  });

  captureException(new Error('User logged out'), {
    level: 'info',
  });

  removeFromStorage(LAST_SELECTED_BUSINESS_UUID);
  removeFromStorage(TOKEN_STORE);

  window['ReactNativeWebView']?.postMessage?.(JSON.stringify({ type: 'RESET_AUTH_TOKEN' }));

  window.location.reload();
};
