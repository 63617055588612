import { getProrateAmount } from '@/auth/endpoints';
import { GCard } from '@/design-system/v3/g-card';
import Text from '@/design-system/v3/text';
import { useQuery } from 'react-query';
import { useBusiness } from '@/web/hooks/use-business';
import { useContext } from 'react';
import { OnboardingFormContext } from '..';
import { formatAmount } from '@/common/utils';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@/common/hooks/use-debounce';

export const useProrateResult = ({
  locationId,
  membershipId,
  discountCode,
}: {
  locationId: string;
  membershipId: string;
  discountCode: string | null;
}) => {
  const { businessUuid, businessLocations, businessMemberships } = useBusiness();
  const { setProrateResult } = useContext(OnboardingFormContext);
  const location = businessLocations?.find((loc) => loc.id === locationId);
  const membership = businessMemberships?.find((mem) => mem.id === membershipId);

  const { data: prorateResult, isFetching } = useQuery(
    ['FETCH_PRORATE_RESULT', location?.id, membership?.id, discountCode],
    async () => {
      if (!membership || membership.amount === 0) {
        return null;
      }

      if (businessUuid && location?.id) {
        const result = await getProrateAmount(businessUuid, location.id, membership.id, null, discountCode);
        setProrateResult(result);

        return result;
      }

      return null;
    },
  );

  return { prorateResult, isFetching };
};

export const OnboardingChoices = () => {
  const { t } = useTranslation();
  const { businessLocations, businessMemberships } = useBusiness();
  const { primaryLocationId, membershipId, setLocation, setMembership, prorateResult } =
    useContext(OnboardingFormContext);
  const location = businessLocations?.find((loc) => loc.id === primaryLocationId);
  const membership = businessMemberships?.find((mem) => mem.id === membershipId);

  return (
    <div>
      {location && (
        <GCard className="first:rounded-t-md last:border-b last:rounded-b-md border-b-0 rounded-none">
          <GCard.Content>
            <div className="flex justify-between items-center">
              <div>
                <div className="text-gray-600">{t('gym')}</div>
                <Text bold className="text-typo-primary">
                  {location?.name}
                </Text>
              </div>
              <button onClick={() => setLocation('')}>{t('edit')}</button>
            </div>
          </GCard.Content>
        </GCard>
      )}

      {membership && (
        <GCard className="first:rounded-t-md last:border-b last:rounded-b-md border-b-0 rounded-none">
          <GCard.Content>
            <div className="flex justify-between items-center">
              <div>
                <div className="text-gray-600">{t('membership')}</div>
                <Text bold className="text-typo-primary">
                  {membership?.name}
                </Text>
              </div>
              <div className="text-right">
                <button onClick={() => setMembership('')}>{t('edit')}</button>
                <Text variant="small" bold className="text-typo-primary">
                  {prorateResult && prorateResult.discountAmount !== null && (
                    <div className="flex flex-row space-x-1">
                      <span>{formatAmount(prorateResult.discountAmount)}</span>
                      <span className="line-through">{formatAmount(membership.amount)}</span>
                    </div>
                  )}
                  {(!prorateResult || prorateResult.discountAmount === null) && formatAmount(membership.amount)}
                </Text>
              </div>
            </div>
          </GCard.Content>
        </GCard>
      )}
    </div>
  );
};
