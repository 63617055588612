import React, { useContext, useEffect, useState } from 'react';
import { useBusiness } from '@/web/hooks/use-business';
import { HomeCardType, HomeCardVariant, PatchCardRequest } from '@/web/modules/home-card/types';
import { FETCH_BUSINESS_HOME_CARDS, useHomeCards } from '@/web/modules/home-card/hooks/useHomeCards';
import { Reorder, useDragControls } from 'framer-motion';
import DotGrid from '@/common/components/icon/icons/v2/dot-grid.svg?react';
import { handleValidationError } from '@/common/utils';
import { useSnack } from '@/common/hooks/use-snack';
import Button from '@/design-system/v3/button';
import { patchAllHomeCard } from '@/web/modules/home-card/api';
import { useMutation, useQueryClient } from 'react-query';
import { ManageCardsPanelContext } from '@/web/modules/home-card/panel/ManageCardsPanelContext';
import { ManageCardsPanelPage } from '@/web/modules/home-card/enums/ManageCardsPanelPage.enum';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';
import { useTranslation } from 'react-i18next';

type Props = {
  onSort: () => void;
  onEdit: (card: HomeCardVariant) => void;
};

const Item = ({ card, onEdit }: { card: HomeCardVariant; onEdit: () => void }) => {
  const controls = useDragControls();

  const startDrag = (e: React.PointerEvent) => {
    controls.start(e);
    triggerHapticFeedback(HapticFeedbackType.IMPACT_MEDIUM);
  };

  return (
    <Reorder.Item
      value={card}
      dragListener={false}
      dragControls={controls}
      className="flex items-center justify-between p-4 bg-white border-y border-gray-200 -mb-px first:border-t-0"
    >
      <div className="flex items-center space-x-4">
        <DotGrid
          className="flex reorder-handle text-gray-400"
          onPointerDown={startDrag}
          style={{ touchAction: 'none' }}
        />
        <div className="flex w-[72px] h-[48px]">
          {card.imageUrl && <img src={card.imageUrl} alt={card.label} className="object-cover w-full h-full" />}
        </div>
        <div className="flex flex-col">
          <span className="font-semibold text-typo-primary">{card.label}</span>
          <span className="text-gray-500">
            {card.type === HomeCardType.ACTIVITY && 'Activity'}
            {card.type === HomeCardType.LINK && 'Link'}
          </span>
        </div>
      </div>

      <div>
        {card.type !== HomeCardType.ACTIVITY && (
          <Button variant="secondary" onClick={onEdit}>
            Edit
          </Button>
        )}
      </div>
    </Reorder.Item>
  );
};

export const AllCardsPage = ({ onSort, onEdit }: Props) => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const { errorMessage } = useSnack();
  const { setPage, setOnBack, setActions } = useContext(ManageCardsPanelContext);
  const initialCards = useHomeCards();
  const queryClient = useQueryClient();
  const [cards, setCards] = useState<HomeCardVariant[]>([]);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setCards(initialCards);
  }, [initialCards]);

  const handleReorder = (newOrder: HomeCardVariant[]) => {
    setCards(newOrder);
    triggerHapticFeedback(HapticFeedbackType.IMPACT_LIGHT);
    setIsDirty(true);
  };

  const { mutate, isLoading } = useMutation(async () => {
    const patches: Record<string, PatchCardRequest> = {};

    cards.forEach((card, index) => {
      patches[card.id] = { order: index };
    });

    try {
      await patchAllHomeCard(businessUuid as string, patches);
    } catch (e) {
      handleValidationError(e, undefined, errorMessage);
      return;
    }

    onSort();
    queryClient.invalidateQueries([FETCH_BUSINESS_HOME_CARDS, businessUuid]);
  });

  useEffect(() => {
    setOnBack(() => () => {
      setPage(ManageCardsPanelPage.START);
    });
  }, [setOnBack, setPage]);

  useEffect(() => {
    setActions(
      <div className="flex py-4 w-full">
        <Button variant="primary" onClick={mutate} disabled={!isDirty} loading={isLoading}>
          {t('save')}
        </Button>
      </div>,
    );
  }, [setActions, mutate, isDirty, isLoading, t]);

  return (
    <div className="flex flex-col justify-between -mt-4">
      <Reorder.Group axis="y" values={cards} onReorder={handleReorder} layoutScroll>
        {cards.map((card) => (
          <Item card={card} key={card.id} onEdit={() => onEdit(card)} />
        ))}
      </Reorder.Group>
    </div>
  );
};
