import { Title } from '@/design-system/v3/title';
import { ReactNode, useContext } from 'react';
import { OnboardingChoices } from '../onboarding-choices';
import SplitScreenImageLayout from '@/common/layouts/SplitScreenImageLayout';
import { OnboardingFormContext } from '..';

export const OnboardingStepsLayout = ({
  subTitleComponent,
  children,
  title,
}: {
  children: ReactNode;
  title: ReactNode;
  subTitleComponent?: ReactNode;
}) => {
  const { primaryLocationId } = useContext(OnboardingFormContext);

  return (
    <SplitScreenImageLayout locationId={primaryLocationId}>
      <OnboardingChoices />
      {subTitleComponent}
      <Title className="my-8">{title}</Title>
      <div>{children}</div>
    </SplitScreenImageLayout>
  );
};
