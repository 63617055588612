import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export type TextVariant = 'regular' | 'small' | 'extra-small';

interface TextProps extends React.HTMLAttributes<HTMLElement> {
  as?: 'p' | 'label' | 'strong' | 'div' | 'span';
  bold?: boolean;
  children?: React.ReactNode;
  variant?: TextVariant;
}

const Text = ({ as, variant = 'regular', bold, children, ...props }: TextProps) => {
  const Tag = as || 'div';

  const classes = clsx('font-body leading-normal font-normal', {
    'text-base': variant === 'regular',
    'text-sm': variant === 'small',
    'text-xs': variant === 'extra-small',
    'font-neutral': bold,
  });

  return <Tag className={twMerge(classes, props.className)}>{children}</Tag>;
};

export default Text;
