import React, { useMemo } from 'react';
import { GAvatar } from '@/design-system/g-avatar';
import Button from '@/design-system/v3/button';
import { useTranslation } from 'react-i18next';
import { InvitationStatus } from '../../enums/InvitationStatus.enum';
import { InvitedFriend } from '../../types/InvitedFriend';

import LoadingIcon from '@/icons/loader.svg?react';
import CheckCircleIcon from '@/icons/check-circle.svg?react';
import { twMerge } from 'tailwind-merge';
import GAvatarSkeleton from '@/design-system/g-avatar/GAvatarSkeleton';
import { skeletonAnimationClasses } from '@/web/utils';

export const BringAFriendInvitedItemSkeleton = () => {
  return (
    <div className="flex justify-between items-center p-4">
      <div className="flex items-center space-x-4">
        <GAvatarSkeleton size="lg" />

        <div>
          <div className={twMerge('w-24 h-4', skeletonAnimationClasses)} />
          <div className="flex items-center">
            <div className={twMerge('h-3 w-12 mt-1', skeletonAnimationClasses)} />
          </div>
        </div>
      </div>
    </div>
  );
};
