import { useQuery } from 'react-query';
import { useBusiness } from '@/web/hooks/use-business';
import { Room } from '@/web/types';
import { getRooms } from '@/business/endpoints';

const FETCH_BUSINESS_ROOMS = 'FETCH_BUSINESS_ROOMS';

export const useRooms = (): Room[] => {
  const { businessUuid } = useBusiness();

  const { data: rooms } = useQuery([FETCH_BUSINESS_ROOMS, businessUuid], () => {
    if (businessUuid) {
      return getRooms(businessUuid);
    }
  });

  return rooms || [];
};
