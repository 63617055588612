import { usePrivacyPolicy } from '@/web/hooks/use-privacy-policy';
import { RichTextContent } from '@/common/components/rich-text-content';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackToButton } from '@/web/views/extra-menu';
import { getProfilePath, PRIVACY_PATH } from '@/web/routes';

export const PrivacyPolicyDetail = () => {
  const { t } = useTranslation();
  const { privacyPolicy } = usePrivacyPolicy();

  if (!privacyPolicy) {
    return <></>;
  }

  return (
    <PageWrapper
      header={
        <PageHeader title={t('privacyPolicy')} leftAction={<BackToButton path={getProfilePath(PRIVACY_PATH)} />} />
      }
      content={
        <div className="relative overflow-scroll py-4">
          <RichTextContent html={privacyPolicy.content} />
        </div>
      }
    />
  );
};
