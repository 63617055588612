import { createContext, ReactNode } from 'react';
import { CourseDetailWithHelpers } from '@/web/types';

interface CourseOptionsMenuContext {
  course: CourseDetailWithHelpers;
  courseDate: Date;
  setActions: (actions: ReactNode | null) => void;
  onClose: () => void;
}

export const CourseOptionsMenuContext = createContext<CourseOptionsMenuContext>({
  course: {} as CourseDetailWithHelpers,
  courseDate: new Date(),
  setActions: () => {},
  onClose: () => {},
});
