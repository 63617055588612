import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export type TitleVariant = 'title1' | 'title2' | 'title3' | 'title4' | 'title5';

interface TitleProps extends React.HTMLAttributes<HTMLElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children?: React.ReactNode;
  variant?: TitleVariant;
}

export const Title = ({ as, variant = 'title1', children, ...props }: TitleProps) => {
  const Tag = as || 'h1';

  const classes = clsx('font-neutral text-typo-primary', {
    'text-2xl': variant === 'title1',
    'text-xl': variant === 'title2',
    'text-lg': variant === 'title3',
    'text-base': variant === 'title4',
    'text-sm': variant === 'title5',
  });

  return <Tag className={twMerge(classes, props.className)}>{children}</Tag>;
};
