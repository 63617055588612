import Button from '@/design-system/v3/button';
import GiftIcon from '@/icons/gift.svg?react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCourseDetail } from '@/web/views/course-detail';
import { BRING_A_FRIEND_PATH } from '@/web/routes';
import clsx from 'clsx';
import useBringAFriend from '../../hooks/useBringAFriend';
import { useMemo } from 'react';

export const BringAFriendActionButton = () => {
  const { t } = useTranslation();
  const { course, isMember, isTeacher } = useCourseDetail();
  const navigate = useNavigate();
  const { invitationsQuery, limit } = useBringAFriend();

  const { data, isFetching } = invitationsQuery({
    enabled: !!course,
  });

  const { friends, isLoaded, totalInvited } = useMemo(() => {
    if (!course || !data) {
      return {
        friends: null,
        isLoaded: false,
        totalInvited: null,
      };
    }

    const friends = data.filter(
      (invitation) => invitation.course.id === course.id && course.startAt === invitation.course.date,
    );
    const friendsNames = friends.map((friend) => friend.friend.details.firstName);

    if (friendsNames.length === 0) {
      return {
        friends: null,
        isLoaded: true,
        totalInvited: data.length,
      };
    }

    return {
      friends: friendsNames.join(', '),
      isLoaded: true,
      totalInvited: data.length,
    };
  }, [course, data]);

  const onClick = () => {
    if (!course) {
      return;
    }

    navigate(`${BRING_A_FRIEND_PATH}/courses/${course?.id}/${course?.startAt}`);
  };

  const isDisabled =
    (!isTeacher && !isMember) ||
    !course ||
    !!friends ||
    isFetching ||
    totalInvited === null ||
    !limit ||
    totalInvited === limit;
  const isLoading = !course || isFetching || !isLoaded;

  const buttonText = useMemo(() => {
    if (totalInvited && limit && totalInvited >= limit) {
      return t('bringAFriend.limitReached');
    } else if (friends) {
      return t('bringAFriend.invitedFriend', { friend: friends });
    } else {
      return t('courseActions.button.bringAFriend');
    }
  }, [t, friends, totalInvited, limit]);

  if (!limit || limit === 0) {
    return <></>;
  }

  return (
    <Button
      disabled={isDisabled}
      loading={isLoading}
      className="flex items-center text-primary-contrast-text"
      startIcon={
        <GiftIcon
          className={clsx('w-5 h-5 text-primary-contrast-text', {
            '!text-typo-secondary': isDisabled,
          })}
        />
      }
      gsize="large"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};
