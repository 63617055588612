import { GInput } from '@/design-system/v3/g-input';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import 'flag-icons/css/flag-icons.min.css';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  error?: string;
  helperText?: ReactNode;
}

export const GPhone = forwardRef<HTMLInputElement, Props>(({ title, helperText, className, ...rest }, ref) => {
  const number = parsePhoneNumber((rest.value || '') as string);

  return (
    <GInput
      ref={ref}
      title={title || ''}
      helperText={helperText}
      placeholder="Phone number"
      className={className}
      {...rest}
      icon={
        number?.country ? (
          <span className={`fi fi-${number.country.toLowerCase()}`}></span>
        ) : (
          <span className="h-[14px] w-[18px] bg-gray-200 rounded-sm" />
        )
      }
    />
  );
});

GPhone.displayName = 'GPhone';
