import i18next from 'i18next';
import { atom } from 'recoil';
import { LOCALE_STORAGE, getDefaultLocale } from './utils';

export const selectedLocaleState = atom<string>({
  key: 'selectedLocale',
  default: getDefaultLocale(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        i18next.changeLanguage(newValue);
        localStorage.setItem(LOCALE_STORAGE, newValue);
      });
    },
  ],
});
