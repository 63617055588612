import { BackToButton } from '../../../../web/views/extra-menu';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { t } from 'i18next';
import { BringAFriendOverviewEarnings } from './variants/BringAFriendOverviewEarnings';
import { BringAFriendOverviewBasic } from './variants/BringAFriendOverviewBasic';
import BringAFriendOverviewBasicSkeleton from './variants/BringAFriendOverviewSkeleton';
import useBringAFriend from '../../hooks/useBringAFriend';

export const BringAFriendOverview = () => {
  const BringAFriendType = 'basic' as 'basic' | 'discount' | 'credit';
  const { invitationsQuery, limit } = useBringAFriend();
  const { data: invitations, isFetching } = invitationsQuery();

  const invitesSend = invitations?.length || 0;
  const totalInvites = limit ?? 0;
  const invitesLeft = totalInvites - invitesSend;
  const percentage = (invitesSend / totalInvites) * 100;

  return (
    <PageWrapper
      headerType="transparent"
      header={<PageHeader leftAction={<BackToButton path={'/profile'} label={t('profileMenuView.profile.title')} />} />}
      className="bg-gray-50"
      contentClasses="px-0"
      disableFooter
      footerClasses="px-4 py-4 mb-0 shadow-footerCourseDetail"
      content={
        <section id="bring-a-friend-overview" className="px-4">
          {isFetching ? (
            <BringAFriendOverviewBasicSkeleton />
          ) : BringAFriendType !== 'basic' ? (
            <BringAFriendOverviewEarnings
              percentage={percentage}
              invitations={invitations ?? []}
              totalInvites={totalInvites}
              invitesSend={invitesSend}
              rewardPrice={10}
              rewardType={BringAFriendType as 'discount' | 'credit'}
              totalEarnead={178.6}
            />
          ) : (
            <BringAFriendOverviewBasic
              percentage={percentage}
              invitations={invitations ?? []}
              invitesLeft={invitesLeft}
              totalInvites={totalInvites}
            />
          )}
        </section>
      }
    />
  );
};
