import { useQuery } from 'react-query';
import { getPrivacyPolicy } from '@/business/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { PrivacyPolicy } from '@/web/types';
import { useMemo } from 'react';

const GYMLY_PRIVACY_POLICY_URL = 'https://gymly.io/privacy';

export const FETCH_BUSINESS_PRIVACY_POLICY = 'FETCH_BUSINESS_PRIVACY_POLICY';

export const usePrivacyPolicy = (): { privacyPolicy: PrivacyPolicy | null; url: string | null } => {
  const { businessUuid } = useBusiness();
  const { data: privacyPolicy } = useQuery([FETCH_BUSINESS_PRIVACY_POLICY, businessUuid], async () => {
    if (!businessUuid) {
      return;
    }

    return getPrivacyPolicy(businessUuid);
  });

  return useMemo(() => {
    if (privacyPolicy) {
      return {
        privacyPolicy,
        url: null,
      };
    }

    return {
      privacyPolicy: null,
      url: GYMLY_PRIVACY_POLICY_URL,
    };
  }, [privacyPolicy]);
};
