import { downloadPicture } from '@/auth/endpoints';
import { createRef, useEffect, useState } from 'react';
import { CloudArrowUp } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string | null | undefined;
  path?: string;
  onChange: (image: string) => void;
}

export const ImageUpload = (props: Props) => {
  const { t } = useTranslation();
  const [image, _setImage] = useState<string | null | undefined>(props.value);
  const inputFileRef = createRef<HTMLInputElement>();

  const cleanup = () => {
    URL.revokeObjectURL(image as string);
    (inputFileRef.current as HTMLInputElement).value = '';
  };

  const setImage = (newImage: string | null) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  const handleOnChange = (event: any) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      setImage(URL.createObjectURL(newImage));
    }

    props.onChange(newImage);
  };

  useEffect(() => {
    _setImage(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.path) {
      (async () => {
        if (props.path) {
          const blob = await downloadPicture(props.path);
          _setImage(URL.createObjectURL(blob));
        }
      })();
    }
  }, [props.path]);

  return (
    <div className="flex items-center justify-center w-full border border-gray-200 text-gray-600 rounded-xl py-4 text-center">
      <label className="w-full flex flex-col items-center justify-center cursor-pointer">
        <div>
          <div
            className="rounded-full w-10 h-10 bg-cover bg-center flex items-center justify-center bg-gray-100 ring-4 ring-gray-50"
            style={{
              backgroundImage: image ? `url(${image})` : undefined,
            }}
          >
            {!image && <CloudArrowUp size={16} weight="bold" className="text-gray-600" />}
          </div>
        </div>
        <input ref={inputFileRef} accept=".jpg, .jpeg" hidden type="file" onChange={handleOnChange} />
        <div className="space-y-1 mt-3">
          <div>
            <span className="text-primary dark:text-black-10 text-sm">{t('uploadPicture')}</span>
          </div>
          <div className="text-xs">JPG (max. 800x400px)</div>
        </div>
      </label>
    </div>
  );
};
