import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

export const queryInvalidator = async (queries: string | string[]) => {
  if (Array.isArray(queries)) {
    queries.forEach((query) => queryClient.invalidateQueries(query));

    return;
  }

  queryClient.invalidateQueries(queries);
};
