import { authTokenSelector, authUserState } from '@/auth/atoms';
import { isTokenValid } from '@/auth/utils';
import { hasCompletedOnboarding } from '@/auth/views/onboarding/utils';
import { useBusiness } from '@/web/hooks/use-business';
import { selectUserType } from '@/business/selectors';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';

export const useAuth = () => {
  const token = useRecoilValue(authTokenSelector);
  const user = useRecoilValue(authUserState);
  const isLoggedIn = isTokenValid();
  const isUserOnboarded = hasCompletedOnboarding(user);
  const { businessUuid } = useBusiness();
  const userType = selectUserType(user, businessUuid);
  const stamps = user?.businesses?.find((business) => business.businessId === businessUuid)?.stamps;
  const memberships = user?.businesses?.find((business) => business.businessId === businessUuid)?.memberships;

  const remainingEntries = stamps?.length
    ? stamps.reduce((acc, stamp) => {
        acc += stamp.entries - stamp.consumed;
        return acc;
      }, 0)
    : 0;
  const isMember = userType === 'MEMBER';
  const isTeacher = userType === 'TEACHER';
  const isStaffMember = userType === 'STAFF' || userType === 'ADMIN';
  const isAdminMember = userType === 'ADMIN';
  const isPosMember = userType === 'POS';
  const canSeeWeb = (isMember || isTeacher || isStaffMember || isAdminMember) && isUserOnboarded;

  return useMemo(
    () => ({
      token,
      user,
      isMember,
      isLoggedIn,
      isUserOnboarded,
      isStaffMember,
      isTeacher,
      isAdminMember,
      isPosMember,
      canSeeWeb,
      userType,
      stamps,
      remainingEntries,
      memberships,
    }),
    [
      canSeeWeb,
      isAdminMember,
      isLoggedIn,
      isMember,
      isUserOnboarded,
      isPosMember,
      isStaffMember,
      isTeacher,
      remainingEntries,
      stamps,
      token,
      user,
      userType,
      memberships,
    ],
  );
};
