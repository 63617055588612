import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Notification } from '../types';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { BackToButton } from '../../extra-menu';
import { INBOX_PATH } from '@/web/routes';
import Text from '@/design-system/v3/text';
import { GLoader } from '@/design-system/g-loader';
import { formatNotifDate } from '@/common/components/inbox-item';
import { Markup } from '@/common/components/markup';
import { readUserNotification } from '@/web/endpoints';
import { useBusiness } from '@/web/hooks/use-business';

export const MessageView = () => {
  const { businessUuid } = useBusiness();
  const { id } = useParams();
  const [notification, setNotification] = useState<null | Notification>(null);

  useEffect(() => {
    (async () => {
      if (id && !notification && businessUuid) {
        const response = await readUserNotification(businessUuid, id);
        setNotification(response);
      }
    })();
  }, [businessUuid, id, notification]);

  if (!id) {
    return null;
  }

  return (
    <PageWrapper
      header={<PageHeader title={notification?.title} leftAction={<BackToButton path={INBOX_PATH} />} />}
      content={
        !notification ? (
          <div className="flex items-center justify-center h-contentHeight">
            <GLoader variant="secondary" />
          </div>
        ) : (
          <>
            <Text variant="extra-small" className="py-4 text-center text-typo-secondary">
              {formatNotifDate(notification.createdAt)}
            </Text>
            <Markup className="text-sm leading-[21px] text-typo-primary" content={notification.content} />
          </>
        )
      }
    />
  );
};
