import clsx from 'clsx';
import { motion } from 'framer-motion';

interface GProgressBarProps {
  percentage: number;
  size?: 'sm' | 'md';
}

export const GProgressBar = ({ percentage, size = 'md' }: GProgressBarProps) => {
  return (
    <div
      className={clsx('w-full bg-gray-100 shadow-inner rounded-full overflow-hidden', {
        'h-3': size === 'sm',
        'h-4': size === 'md',
      })}
    >
      <motion.div
        className="bg-primary-500 h-full rounded-full"
        initial={{ width: 0 }}
        animate={{ width: `${percentage}%` }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      />
    </div>
  );
};
