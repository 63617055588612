import { skeletonAnimationClasses } from '@/web/utils';

const BringAFriendInvitationHeaderSkeleton = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <div className={`h-16 w-16 mb-6 ${skeletonAnimationClasses}`} />
      <div className={`w-32 h-8 ${skeletonAnimationClasses}`} />
      <div className={`w-full h-5 mt-1 ${skeletonAnimationClasses}`} />
    </div>
  );
};

export default BringAFriendInvitationHeaderSkeleton;
