import { Transition } from '@headlessui/react';
import { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';

export const OnboardingTransition = ({ children, disabled = false }: { children: ReactNode; disabled?: boolean }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Transition
      show={show}
      as="div"
      enter={clsx('delay-100 duration-500', {
        'transition-all': !disabled,
      })}
      enterFrom="opacity-0 translate-y-10"
      enterTo="opacity-100 translate-y-0"
    >
      {children}
    </Transition>
  );
};
