import { getFromStorage, setInStorage } from '@/common/utils';
import { CourseWithHelpers } from '@/web/types';
import { atom, selector } from 'recoil';

export type BookingFlowCourses = {
  [key: string]: CourseWithHelpers;
};

export const bookingFlowLocation = atom<string | null>({
  key: 'bookingFlowLocation',
  default: getFromStorage('gymly.bookingFlowLocation') || null,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue) {
          setInStorage('gymly.bookingFlowLocation', newValue);
        }
      });
    },
  ],
});

export const bookingFlowDate = atom<Date>({
  key: 'bookingFlowDate',
  default: new Date(),
});

export const bookingFlowTypeAtom = atom<string | null>({
  key: 'bookingFlowTypeAtom',
  default: null,
});

export const bookingFlowType = selector<string | null>({
  key: 'bookingFlowType',
  get: ({ get }) => {
    return get(bookingFlowTypeAtom);
  },
  set: ({ set }, newValue) => {
    // Reset subType when changing type.
    set(bookingFlowTypeAtom, newValue);
    set(bookingFlowSubType, null);
  },
});

export const bookingFlowSubType = atom<string | null>({
  key: 'bookingFlowSubType',
  default: null,
});

export const bookingFlowRoom = atom<string | null>({
  key: 'bookingFlowRoom',
  default: null,
});
