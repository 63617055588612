import React, { ReactNode, useMemo, useState } from 'react';
import { useBusiness } from '@/web/hooks/use-business';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { GSegmentedControl } from '@/design-system/v3/g-segmented-control';
import { SHOP_PATH } from '@/web/routes';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ShopContext } from '@/web/views/shop/context';
import clsx from 'clsx';

enum ShopTab {
  WEBSHOP = 'WEBSHOP',
  MEMBERSHIPS = 'MEMBERSHIPS',
}

export const ShopView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [header, setHeader] = useState<ReactNode | null>(null);
  const { businessFeatures, hasPurchasableMemberships } = useBusiness();

  const tab = useMemo<ShopTab>(() => {
    switch (location.pathname) {
      case `${SHOP_PATH}/webshop`:
        return ShopTab.WEBSHOP;
      case SHOP_PATH:
      default:
        return ShopTab.MEMBERSHIPS;
    }
  }, [location]);

  const handleTabChange = (tab: React.ChangeEvent<HTMLButtonElement>) => {
    setHeader(null);

    switch (tab.target.value as ShopTab) {
      case ShopTab.WEBSHOP:
        navigate(`${SHOP_PATH}/webshop`);
        break;
      case ShopTab.MEMBERSHIPS:
        navigate(`${SHOP_PATH}`);
        break;
    }
  };

  const TABS = useMemo(
    () => [
      { value: ShopTab.MEMBERSHIPS, label: t('memberships') },
      { value: ShopTab.WEBSHOP, label: t('webshop') },
    ],
    [t],
  );

  const showTabs = useMemo<boolean>(() => {
    return businessFeatures && businessFeatures.includes('Webshop') && hasPurchasableMemberships;
  }, [businessFeatures, hasPurchasableMemberships]);

  const contextValue = useMemo(() => ({ setHeader }), [setHeader]);

  return (
    <ShopContext.Provider value={contextValue}>
      <PageWrapper
        header={
          <div
            className={clsx('flex flex-col space-y-2', {
              'px-4 py-4': showTabs,
            })}
          >
            {showTabs && <GSegmentedControl value={tab} options={TABS} onChange={handleTabChange} />}
            {!showTabs && businessFeatures && businessFeatures.includes('Webshop') && (
              <PageHeader title={t('webshop')} />
            )}
            {!showTabs && hasPurchasableMemberships && <PageHeader title={t('memberships')} />}
            {header !== undefined && header}
          </div>
        }
        content={<Outlet />}
        contentClasses="px-0"
      />
    </ShopContext.Provider>
  );
};
