import { useTranslation } from 'react-i18next';
import React, { ReactNode, useMemo } from 'react';
import PaymentMethodIdealIcon from '@/common/components/icon/icons/v2/payment-method-ideal.svg?react';
import PaymentMethodSepaIcon from '@/common/components/icon/icons/v2/payment-method-sepa.svg?react';
import PaymentMethodMcIcon from '@/common/components/icon/icons/v2/payment-method-mc.svg?react';
import PaymentMethodVisaIcon from '@/common/components/icon/icons/v2/payment-method-visa.svg?react';
import PaymentMethodPayByBankIcon from '@/common/components/icon/icons/v2/payment-method-pbb.svg?react';

export const UserPaymentMethods = () => {
  const { t, i18n } = useTranslation();

  const methods = useMemo<ReactNode[]>(() => {
    const result: ReactNode[] = [];

    switch (i18n.language) {
      case 'de':
        result.push(<PaymentMethodPayByBankIcon key="method-pbb" className="h-6 w-auto" />);
        break;
      default:
        result.push(<PaymentMethodIdealIcon key="method-ideal" className="h-6 w-auto" />);
        break;
    }

    return [
      ...result,
      <PaymentMethodSepaIcon key="method-sepa" className="h-6 w-auto" />,
      <PaymentMethodMcIcon key="method-mc" className="h-6 w-auto" />,
      <PaymentMethodVisaIcon key="method-visa" className="h-6 w-auto" />,
    ];
  }, [i18n.language]);

  return (
    <div className="flex items-center space-x-1">
      <span className="text-xs text-typo-primary">{t('payWith')}</span>
      {methods}
    </div>
  );
};
