import { LAST_SELECTED_BUSINESS_UUID } from '@/common/constants';
import { getFromStorage } from '@/common/utils';

export const sendPirschEvent = (eventName: string, metadata?: Record<string, boolean | string>) => {
  const data: Record<string, boolean | string> = {
    businessUuid: getFromStorage(LAST_SELECTED_BUSINESS_UUID) || '',
    ...metadata,
  };
  if (window.pirsch) {
    window.pirsch(eventName, data);
  } else {
    console.log(`Pirsch event: ${eventName}`, { metadata: data, hasSent: !!window.pirsch });
  }
};
