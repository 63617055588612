import styled from 'styled-components';

export const BOLD_FONT_FAMILY = 'Inter, sans-serif';

export const HeadingBase = styled('div')`
  margin: 0;
  font-family: ${BOLD_FONT_FAMILY};
  font-weight: 600;
`;

export const Heading48 = styled(HeadingBase)`
  font-size: 48px;
  line-height: 60px;
`;

export const Heading32 = styled(HeadingBase)`
  font-size: 32px;
  line-height: 40px;
`;

export const Heading24 = styled(HeadingBase)`
  font-size: 24px;
  line-height: 30px;
`;

export const Heading18 = styled(HeadingBase)`
  font-size: 18px;
  line-height: 28px;
`;

export const Heading20 = styled(HeadingBase)`
  font-size: 20px;
  line-height: 30px;
`;

export const Heading16 = styled(HeadingBase)`
  font-size: 16px;
`;

export const Heading14 = styled(HeadingBase)`
  font-size: 14px;
`;

export const Heading12 = styled(HeadingBase)`
  font-size: 12px;
`;
