import Button from '@/design-system/v3/button';
import { ReactNode, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { appState } from '@/common/atoms';
import GymlySymbol from '@/gymly-symbol.svg?react';
import { gaxios, handleRequestError } from '@/api';
import { AxiosError } from 'axios';

export const MaintenanceScreen = ({ children }: { children: ReactNode }) => {
  const [{ maintenance }, setAppState] = useRecoilState(appState);

  useEffect(() => {
    gaxios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response && error.response.status === 503) {
          const data = error.response.data as { message: string | null };
          setAppState((prev) => ({ ...prev, maintenance: data.message ?? '' }));

          return;
        }

        handleRequestError(error);
      },
    );
  }, [setAppState]);

  if (maintenance !== null) {
    return (
      <div className="h-screen justify-center flex items-center p-4">
        <div className="text-center flex flex-col items-center justify-center">
          <GymlySymbol className="w-16 h-16 text-[#6F04F4] mb-14" />
          <h1 className="text-xl text-gray-900 font-semibold">Maintenance</h1>
          <div className="text-gray-600 text-base mt-2 px-5">{maintenance}</div>
          <Button variant="secondary" className="w-full mt-14" onClick={() => window.location.reload()}>
            Retry
          </Button>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
