import { useRecoilState } from 'recoil';
import { panelState } from '../../atoms';
import { GPanel } from '@/design-system/v3/g-panel';

export const Panels = () => {
  const [panel, setPanel] = useRecoilState(panelState);
  const Content = panel?.component;
  const props = panel?.props;

  const handleClose = () => {
    setPanel(null);
  };

  return (
    <GPanel
      title={panel ? panel.title : ''}
      open={panel ? panel.isOpen : false}
      closeButtonVariant={panel?.closeButtonVariant}
      autoHeight={panel?.autoHeight}
      onClose={handleClose}
      actions={panel?.actions}
      childrenClassName={panel?.childrenClassName}
      drag={panel?.draggable !== undefined ? panel.draggable : true}
    >
      {Content && <Content {...props} />}
    </GPanel>
  );
};
