import { Controller, useForm } from 'react-hook-form';
import { GFormControlLabel } from '@/design-system/v3/g-form-control-label';
import { Textarea } from '@/design-system/v3/textarea';
import Button from '@/design-system/v3/button';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { CourseOptionsMenuContext } from '@/web/views/course-detail/course-options-menu/context';
import { cancelCourse, FETCH_COURSE_DETAIL_QUERY } from '@/web/endpoints';
import { handleValidationError } from '@/common/utils';
import { useSnack } from '@/common/hooks/use-snack';
import { useBusiness } from '@/web/hooks/use-business';

type FormData = {
  message: string | null;
};

type RequestData = {
  startAt: string;
  recurringUpdateMode: 'SINGLE';
  notify: boolean;
  notifyMessage: string | null;
};

export const CancelMode = () => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const { course, onClose } = useContext(CourseOptionsMenuContext);
  const { errorMessage } = useSnack();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      message: null,
    },
  });

  const { mutate, isLoading } = useMutation<unknown, unknown, RequestData>(
    async (data) => {
      return cancelCourse(businessUuid as string, course, data);
    },
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY, course.id]);
      },
      onError: (e) => {
        console.log(e);
        handleValidationError(e, setError, errorMessage);
      },
    },
  );

  const onSubmit = (data: FormData) => {
    mutate({
      startAt: course.startAt,
      recurringUpdateMode: 'SINGLE',
      notify: true,
      notifyMessage: data.message,
    });
  };

  const onSubmitWithoutMessage = () => {
    mutate({
      startAt: course.startAt,
      recurringUpdateMode: 'SINGLE',
      notify: false,
      notifyMessage: null,
    });
  };

  return (
    <div className="px-4 space-y-4">
      <Controller
        name="message"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            <GFormControlLabel
              control={
                <Textarea
                  placeholder={t('courseCancel.message.placeholder')}
                  onChange={onChange}
                  value={value}
                  error={errors.message?.message?.toString()}
                  className="min-h-[160px]"
                />
              }
              label={`${t('message')} (${t('optional').toLowerCase()})`}
            />
          </div>
        )}
      />
      <Button variant="primary" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
        {t('confirm')}
      </Button>
      <Button variant="secondary" onClick={handleSubmit(onSubmitWithoutMessage)} disabled={isLoading}>
        {t('confirmWithoutMessage')}
      </Button>
    </div>
  );
};
