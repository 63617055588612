import { useState, useLayoutEffect } from 'react';
import { type RouterProps, Router as ReactRouterRouter } from 'react-router-dom';
import { type History } from 'history';

interface Props extends Omit<RouterProps, 'location' | 'navigationType' | 'navigator'> {
  history: History;
}

export const Router = ({ history, ...props }: Props) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <ReactRouterRouter {...props} location={state.location} navigationType={state.action} navigator={history} />;
};
