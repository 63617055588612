export enum HapticFeedbackType {
  IMPACT_LIGHT = 'impactLight',
  IMPACT_MEDIUM = 'impactMedium',
  IMPACT_HEAVY = 'impactHeavy',
  NOTIFICATION_SUCCESS = 'notificationSuccess',
  NOTIFICATION_WARNING = 'notificationWarning',
  NOTIFICATION_ERROR = 'notificationError',
}

/**
 * Triggers haptic feedback on the native side.
 *
 * @param type The type of haptic feedback to trigger.
 */
export const triggerHapticFeedback = (type: HapticFeedbackType) => {
  if (!window['ReactNativeWebView']) {
    return;
  }

  const payload = {
    type: 'HAPTIC',
    payload: { type },
  };
  window['ReactNativeWebView'].postMessage(JSON.stringify(payload));
};
