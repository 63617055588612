import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GInput } from '@/design-system/v3/g-input';
import { GFormControlLabel } from '@/design-system/v3/g-form-control-label';

export interface Props {
  error?: string | boolean;
  required?: boolean;
  optionalLabel?: boolean;
  value?: string;
  onChange?: (value: string | null) => void;
}

export const GDateOfBirth = ({ error, required, onChange, optionalLabel = false }: Props) => {
  const { t } = useTranslation();
  const [date, setDate] = useState({ day: '', month: '', year: '' });
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState<{
    day: boolean;
    month: boolean;
    year: boolean;
  }>({
    day: false,
    month: false,
    year: false,
  });

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    // Ensure month is between 1 and 12.
    if (type === 'month' && value !== '' && (value < 1 || value > 12)) {
      return;
    }

    // Ensure year is not in the future.
    if (type === 'year' && value !== '' && value > new Date().getFullYear()) {
      return;
    }

    // Ensure day is valid within the month.
    if (type === 'day' && value !== '' && date.month !== '' && date.year !== '') {
      const daysInMonth = new Date(parseInt(date.year, 10), parseInt(date.month, 10), 0).getDate();

      if (value > daysInMonth) {
        return;
      }
    }

    // Ensure input is only numeric and restrict length.
    if (
      isNaN(value) ||
      (type === 'day' && value.length > 2) ||
      (type === 'month' && value.length > 2) ||
      (type === 'year' && value.length > 4)
    ) {
      return;
    }

    // Update the date state.
    setDate({ ...date, [type]: value });

    // Move focus to the next input field based on the length of the value.
    if (
      (type === 'day' && (value.length === 2 || value > 3)) ||
      (type === 'month' && (value.length === 2 || value > 1))
    ) {
      const nextInput = type === 'day' ? 'month' : 'year';
      document.getElementById(nextInput)?.focus();
    }
  };

  useEffect(() => {
    let dayValid = true;
    let monthValid = true;
    let yearValid = true;

    // Check if day exists for month/year.
    if (date.day !== '' && date.month !== '' && date.year !== '') {
      const daysInMonth = new Date(parseInt(date.year, 10), parseInt(date.month, 10), 0).getDate();

      if (parseInt(date.day, 10) > daysInMonth) {
        dayValid = false;
      }
    }

    // When year is current year, month should be less than or equal to current month.
    const year = parseInt(date.year, 10);

    if (year === new Date().getFullYear()) {
      if (parseInt(date.month, 10) > new Date().getMonth() + 1) {
        monthValid = false;
      }
    }

    // Year should not be in the future.
    if (year > new Date().getFullYear()) {
      yearValid = false;
    }

    const errors = {
      day: !dayValid,
      month: !monthValid,
      year: !yearValid,
    };

    setErrors(errors);

    if (!onChange) {
      return;
    }

    const isValid = !(
      errors.day ||
      errors.month ||
      errors.year ||
      date.day === '' ||
      date.month === '' ||
      date.year === '' ||
      year < new Date().getFullYear() - 130
    );

    if (!isValid) {
      if (isDirty) {
        onChange('');
      }
      return;
    }

    const days = date.day.length === 1 ? `0${date.day}` : date.day;
    const months = date.month.length === 1 ? `0${date.month}` : date.month;
    setIsDirty(true);
    onChange(`${date.year}-${months}-${days}`);
  }, [date, onChange, isDirty]);

  return (
    <div>
      <GFormControlLabel
        label={t('authView.form.dob.title')}
        optionalLabel={optionalLabel}
        required={required}
        control={
          <div className="inline-flex flex-row items-center space-x-2">
            <GInput
              title={t('day')}
              labelSize="sm"
              error={!!error || errors.day}
              type="text"
              id="day"
              value={date.day}
              onChange={(e) => handleInputChange(e, 'day')}
              maxLength={2}
            />
            <GInput
              type="text"
              title={t('month')}
              labelSize="sm"
              error={!!error || errors.month}
              id="month"
              value={date.month}
              onChange={(e) => handleInputChange(e, 'month')}
              maxLength={2}
            />
            <GInput
              type="text"
              id="year"
              title={t('year')}
              error={!!error || errors.year}
              labelSize="sm"
              value={date.year}
              onChange={(e) => handleInputChange(e, 'year')}
              maxLength={4}
            />
          </div>
        }
      />
      {error && typeof error === 'string' && (
        <div className="space-y-1 mt-1">
          <div className="text-error">{error}</div>
        </div>
      )}
    </div>
  );
};
