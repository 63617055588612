import { twMerge } from 'tailwind-merge';
import { skeletonAnimationClasses } from '@/web/utils';
import { GCard } from '@/design-system/v3/g-card';
import { BringAFriendInvitedItemSkeleton } from './BringAFriendInvitedItemSkeleton';

export const BringAFriendInvitedListSkeleton = () => {
  return (
    <div>
      <div className={twMerge('font-medium text-base mb-3 h-4 w-32', skeletonAnimationClasses)} />
      <GCard variant="secondary">
        <div className="divide-y">
          <BringAFriendInvitedItemSkeleton />
        </div>
      </GCard>
    </div>
  );
};
