type Props = {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export const PanelNavItem = ({ label, icon, onClick }: Props) => {
  return (
    <button
      className="flex items-center justify-between bg-gray-50 px-4 py-4 w-full first:rounded-t-xl last:rounded-b-xl"
      onClick={onClick}
    >
      <div className="flex flex-row items-center space-x-3 text-typo-primary">
        {icon}
        <span>{label}</span>
      </div>
    </button>
  );
};
