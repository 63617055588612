import { generatePath } from 'react-router-dom';
import { COURSE_DETAIL_PATH, INBOX_MESSAGE_PATH } from '@/web/routes';
import { history } from '@/common/history';
import { TOKEN_STORE } from '@/common/constants';
import { registerDeviceFCMToken } from './endpoints';

if ('ReactNativeWebView' in window) {
  window.navigateToCourse = function ({ uuid, startAt }) {
    if (uuid && startAt) {
      const path = generatePath(COURSE_DETAIL_PATH, { uuid, startAt });
      history.push(path);
    }
  };

  window.navigateToInboxMessage = function ({ uuid }: { uuid: string }) {
    const path = generatePath(INBOX_MESSAGE_PATH, { id: uuid });
    history.push(path);
  };

  window.setAuthToken = function (token: string) {
    window.localStorage.setItem(TOKEN_STORE, token);
  };

  window.registerDeviceFCMToken = async function (token: string) {
    try {
      await registerDeviceFCMToken(token);

      window.ReactNativeWebView?.postMessage?.(JSON.stringify({ type: 'DEVICE_FMC_TOKEN_REGISTERED' }));
    } catch (error) {
      window.ReactNativeWebView?.postMessage?.(JSON.stringify({ type: 'DEVICE_FMC_TOKEN_REGISTERED_ERROR' }));
    }
  };
}
