import { Black10, Dark100, DarkBlue10, DarkBlue100, DarkBlue60, White100 } from '../../../design-system/colors';
import { Text14 } from '../../../design-system/text';
import { useTranslation } from 'react-i18next';

type Props = {
  total: number;
  consumed: number;
  contained?: boolean;
};

/**
 * Widget for showing the amount of entries left on a stamp card.
 *
 * @param stamps The stamp cards of the user.
 * @constructor
 */
export const StampsWidget = ({ total, consumed, contained = false }: Props) => {
  const { t } = useTranslation();

  const getEntryBackground = (active: boolean) => {
    if (contained) {
      return active ? DarkBlue60 : White100;
    }

    return active ? DarkBlue100 : DarkBlue10;
  };

  return (
    <div
      className="flex flex-col space-y-2"
      style={{
        padding: contained ? '24px 20px' : undefined,
        background: contained ? DarkBlue100 : undefined,
      }}
    >
      <div className="flex flex-row space-x-1">
        {new Array(total).fill(0).map((_, index) => (
          <div
            key={`stamp-${index}`}
            className="flex flex-col"
            style={{
              background: getEntryBackground(index < consumed),
              flex: 1,
              height: '4px',
              borderRadius: '4px',
            }}
          />
        ))}
      </div>
      <Text14 style={{ color: contained ? Black10 : Dark100 }}>
        {t('stampsWidget.remainingEntries', { count: total - consumed })}
      </Text14>
    </div>
  );
};
