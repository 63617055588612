import { useAuth } from '@/auth/hooks/use-auth';
import logo from '@/gymly-symbol.svg';
import clsx from 'clsx';

export const ProfileAvatar = ({ className }: { className?: string }) => {
  const { user } = useAuth();

  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-full w-5 h-5 bg-center bg-cover bg-gray-200 border-[1.5px] border-typo-primary',
        className,
      )}
      style={{ backgroundImage: `url(${user?.profileImageUrl || logo}` }}
    ></div>
  );
};
