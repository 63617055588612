import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { BackToButton } from '../extra-menu';
import Text from '@/design-system/v3/text';
import { Title } from '@/design-system/v3/title';
import { GoalsPanel, useGoals } from '@/common/components/booking-flow/goals-panel';
import { GLoader } from '@/design-system/g-loader';

export const StreaksView = () => {
  const { isFetching, data } = useGoals();
  const gridNr = data?.weeklyVisits || 0;

  const content = isFetching ? (
    <div className="flex items-center justify-center h-contentHeight">
      <GLoader variant="secondary" />
    </div>
  ) : data?.weeklyVisits === null ? (
    <div className="pt-12">
      <GoalsPanel />
    </div>
  ) : (
    <div className="pt-12">
      <Text variant="extra-small" className="text-gray">
        Goal this week
      </Text>
      <Title variant="title1" className="mb-4">
        Go {gridNr} times to the gym
      </Title>
      <div
        className={`grid gap-1 rounded-md overflow-hidden`}
        style={{ gridTemplateColumns: `repeat(${gridNr}, 1fr)` }}
      >
        {Array.from({ length: gridNr }, (_, idx) => (
          <div key={`streak-${idx}`} className="bg-primary dark:bg-black-10 h-2" />
        ))}
        {/* <div className="bg-gray-100 dark:bg-gray-700 h-2" /> */}
      </div>
      <Text variant="extra-small" className="text-gray mt-2 mb-12">
        2/{gridNr} visits
      </Text>
      <GoalsPanel />
    </div>
  );
  return (
    <PageWrapper header={<PageHeader title="Streaks" leftAction={<BackToButton path="/" />} />} content={content} />
  );
};
