import { DS } from '@/design-system';
import symbol from '@/gymly-symbol.svg';

export const NotFound = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="flex flex-col space-y-4 items-center">
      <img src={symbol} alt="logo" style={{ width: '60px' }} />
      <DS.Heading16>404</DS.Heading16>
      <DS.Text14>We could not find your page</DS.Text14>
    </div>
  </div>
);
