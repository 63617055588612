import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import Text from '../text';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  subtitle?: ReactNode;
  value: string;
  error?: string;
  as?: 'div';
}

export const GRadio = forwardRef<HTMLInputElement, Props>(
  ({ title, label, subtitle, error, className, as, ...rest }, ref) => {
    const Wrapper = as || 'label';
    return (
      <Wrapper className="flex gap-2 group cursor-pointer relative">
        <input type="radio" className="-left-[9999px] absolute peer" ref={ref} {...rest} />
        <div
          className="
          w-5 
          min-w-[20px]
          h-5 
          relative
          top-px
          flex
          items-center justify-center
          rounded-full
          border border-gray-300 
          peer-focus:shadow-inputFocus 
          group-active:border-primary-600
          group-active:bg-primary-100 
          peer-checked:bg-primary-100
          peer-disabled:bg-gray-50 
          peer-disabled:border-gray-300 
          peer-disabled:text-typo-secondary
          text-pink-500
          group"
        >
          <div className="hidden peer-checked:group-[]:block w-2 h-2 rounded-full bg-primary-600 peer-disabled:group-[]:bg-gray-600" />
        </div>
        <div className="group">
          {(title || label) && (
            <Text variant="small" className="text-gray-700 font-median peer-disabled:group-[]:text-typo-secondary">
              {title || label}
            </Text>
          )}
          {subtitle && (
            <Text variant="small" className="text-gray peer-disabled:group-[]:text-typo-secondary">
              {subtitle}
            </Text>
          )}
        </div>
      </Wrapper>
    );
  },
);

GRadio.displayName = 'GRadio';
