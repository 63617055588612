import { Controller, useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { activateGuest, login } from '../../endpoints';
import { getEmailValidator, getPasswordValidator, getRequiredValidator } from '../../../common/validators';
import logo from '../../../logo.svg';
import { getDecodedToken } from '@/auth/utils';
import { useQuery } from 'react-query';
import { getBusiness } from '@/business/endpoints';
import { useSetRecoilState } from 'recoil';
import { authJWTState, authTokenSelector } from '@/auth/atoms';
import { GGrid } from '@/design-system/v3/g-grid';
import Button from '@/design-system/v3/button';
import { GInput } from '@/design-system/v3/g-input';
import { Title } from '@/design-system/v3/title';
import { handleValidationError, isErrorResponse } from '@/common/utils';
import { useSnack } from '@/common/hooks/use-snack';
import { useTranslation } from 'react-i18next';

export const GuestActivationView = () => {
  const { successMessage, errorMessage } = useSnack();
  const setAuthToken = useSetRecoilState(authTokenSelector);
  const setAuthJWT = useSetRecoilState(authJWTState);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const guestId = searchParams.get('guestId');
  const email = searchParams.get('email');
  const businessUuid = searchParams.get('businessId');
  const shouldRedirect = !email || !businessUuid || !guestId;
  const fromPath = (location?.state as any)?.fromPath?.pathname || '/';
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: email || '',
      password: '',
      confirmPassword: '',
    },
  });
  const { data, isFetching } = useQuery('FETCH_GUEST_DATA', async () => {
    if (businessUuid) {
      const business = await getBusiness(businessUuid);

      return { business };
    }

    return null;
  });

  const passwordsValues = watch(['password', 'confirmPassword']);
  const onSubmit = async (data: any) => {
    try {
      await activateGuest(businessUuid as string, {
        password: data.password,
        guestId,
      });
      const jwtResponse = await login(data.email, data.password);

      setAuthToken(jwtResponse.jwt);
      setAuthJWT(getDecodedToken());
      successMessage('signUp.succesfull');
      navigate(fromPath);
    } catch (e) {
      if (isErrorResponse(e)) {
        if (e?.response?.data.code === 'guest_not_found') {
          errorMessage('guestActivation.error.title', {
            detailedMessage: 'guestActivation.error.message',
          });
        } else {
          handleValidationError(e, () => null, errorMessage);
        }
      }
    }
  };

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  if (isFetching) {
    return null;
  }

  return (
    <GGrid container>
      <GGrid item xs></GGrid>
      <GGrid item xs={12}>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-full max-w-[320px] text-center">
            <img src={logo} alt="logo" className="py-10 max-w-[120px] mx-auto my-0" />
            <Title className="mb-10" as="h3">
              {t('guestActivation.title', { businessName: data?.business?.name })}
            </Title>

            <form>
              <div className="flex flex-col space-y-3">
                <Controller
                  name={'email'}
                  control={control}
                  rules={getEmailValidator()}
                  render={({ field: { onChange, value } }) => (
                    <GInput
                      autoComplete="off"
                      readOnly
                      error={errors.email?.message?.toString()}
                      type="email"
                      title={t('authView.form.email.title')}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                <Controller
                  name={'password'}
                  control={control}
                  rules={getPasswordValidator()}
                  render={({ field: { onChange, value } }) => (
                    <GInput
                      type="password"
                      autoComplete="new-password"
                      error={errors.password?.message?.toString()}
                      title={t('authView.form.password.title')}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                <Controller
                  name={'confirmPassword'}
                  control={control}
                  rules={getRequiredValidator()}
                  render={({ field: { onChange, value } }) => (
                    <GInput
                      type="password"
                      autoComplete="new-password"
                      error={
                        errors.confirmPassword?.message?.toString() ||
                        (passwordsValues[0] !== value ? t('authView.form.password.notMatch') : undefined)
                      }
                      title={t('authView.form.password.confirm.title')}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                <Button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || passwordsValues[0] !== passwordsValues[1]}
                  loading={isSubmitting}
                >
                  {t('guestActivation.btn')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </GGrid>
    </GGrid>
  );
};
