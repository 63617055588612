import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef, useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  options: {
    label: string;
    value: string;
  }[];
  size?: 'sm' | 'md';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const GSegmentedControl = forwardRef<HTMLInputElement, Props>(
  ({ onChange, options, size = 'md', value, ...rest }, ref) => {
    const id = useMemo(() => {
      return `sg-${Math.random().toString(36).substr(2, 9)}`;
    }, [options]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        triggerHapticFeedback(HapticFeedbackType.IMPACT_MEDIUM);
      },
      [onChange],
    );

    return (
      <div
        className={clsx('flex flex-row w-full bg-gray-100 p-1 space-x-1', {
          'rounded-lg': size === 'md',
          'rounded-md': size === 'sm',
        })}
      >
        {options.map((option) => (
          <GSegmentButton
            id={id}
            key={option.value}
            option={option}
            value={value}
            size={size || 'md'}
            active={value === option.value}
            ref={ref}
            onChange={handleChange}
            {...rest}
          />
        ))}
      </div>
    );
  },
);

const GSegmentButton = forwardRef<
  HTMLInputElement,
  {
    id: string;
    option: {
      label: string;
      value: string;
    };
    value: string;
    size: 'sm' | 'md';
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    active: boolean;
  }
>(({ id, option, size, active, value, onChange, ...rest }, ref) => {
  const buttonId = `sg-${option.value}-${Math.random().toString(36).substr(2, 9)}`;
  return (
    <label className="relative flex flex-1" htmlFor={buttonId}>
      <input
        id={buttonId}
        type="radio"
        value={option.value}
        ref={ref}
        checked={option.value === value}
        className="-left-[9999px] absolute peer"
        onChange={onChange}
        {...rest}
      />
      <span
        className={clsx('inline-flex justify-center w-full transition-all cursor-pointer', {
          'p-2 rounded-lg': size === 'md',
          'py-1 px-2 rounded-md text-xs': size === 'sm',
        })}
      >
        <span className="z-[2]">{option.label}</span>
        {active && (
          <motion.div
            className="bg-white rounded-md left-0 top-0 right-0 bottom-0 absolute z-[1]"
            transition={{ type: 'spring', bounce: 0.25, duration: 0.5 }}
            layoutId={`${id}-underline`}
            layout
          />
        )}
      </span>
    </label>
  );
});

GSegmentedControl.displayName = 'GSegmentedControl';
