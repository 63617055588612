import { GPanel } from '@/design-system/v3/g-panel';
import { PanelNavItem } from '@/common/components/PanelNavItem';
import { useTranslation } from 'react-i18next';
import LinkIcon from '@/common/components/icon/icons/v2/link.svg?react';
import CardAdd from '@/common/components/icon/icons/v2/card-add.svg?react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { AddEditLinkCardPage } from '@/web/modules/home-card/panel/AddEditLinkCardPage';
import { HomeCardType, HomeCardVariant } from '@/web/modules/home-card/types';
import { AllCardsPage } from '@/web/modules/home-card/panel/AllCardsPage';
import { ManageCardsPanelContext } from '@/web/modules/home-card/panel/ManageCardsPanelContext';
import { ManageCardsPanelPage } from '@/web/modules/home-card/enums/ManageCardsPanelPage.enum';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ManageCardsPanel = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState<HomeCardVariant | null>(null);
  const [actions, setActions] = useState<ReactNode | null>(null);
  const [onBack, setOnBack] = useState<(() => void) | null>(null);
  const [page, setPage] = useState<ManageCardsPanelPage>(ManageCardsPanelPage.START);

  const title = useMemo(() => {
    switch (page) {
      case ManageCardsPanelPage.START:
        return t('homecard.manageCards.title');
      case ManageCardsPanelPage.ALL_CARDS:
        return t('homecard.allCards');
      case ManageCardsPanelPage.ADD_LINK_CARD:
        return selectedCard ? t('homecard.editLinkCard.label') : t('homecard.addLinkCard.label');
    }
  }, [page, t, selectedCard]);

  const onEdit = (card: HomeCardVariant) => {
    setSelectedCard(card);

    if (card.type === HomeCardType.LINK) {
      setPage(ManageCardsPanelPage.ADD_LINK_CARD);
    }
  };

  const contextValue = useMemo(() => ({ onBack, setOnBack, page, setPage, actions, setActions }), [onBack, page]);

  useEffect(() => {
    if (open) {
      setPage(ManageCardsPanelPage.START);
    }
  }, [open]);

  useEffect(() => {
    if (page === ManageCardsPanelPage.START) {
      setSelectedCard(null);
      setOnBack(null);
      setActions(null);
    }
  }, [page]);

  return (
    <ManageCardsPanelContext.Provider value={contextValue}>
      <GPanel
        open={open}
        onClose={onClose}
        title={title}
        onBack={onBack}
        childrenClassName="px-0"
        drag={page === ManageCardsPanelPage.START}
        autoHeight
        actions={actions}
      >
        {page === ManageCardsPanelPage.START && (
          <div className="px-4">
            <PanelNavItem
              label={t('homecard.addLinkCard.label')}
              icon={<LinkIcon />}
              onClick={() => setPage(ManageCardsPanelPage.ADD_LINK_CARD)}
            />
            <PanelNavItem
              label={t('homecard.allCards')}
              icon={<CardAdd />}
              onClick={() => setPage(ManageCardsPanelPage.ALL_CARDS)}
            />
          </div>
        )}

        {page === ManageCardsPanelPage.ADD_LINK_CARD && (!selectedCard || selectedCard.type === HomeCardType.LINK) && (
          <AddEditLinkCardPage onConfirm={onClose} card={selectedCard} />
        )}
        {page === ManageCardsPanelPage.ALL_CARDS && <AllCardsPage onSort={onClose} onEdit={onEdit} />}
      </GPanel>
    </ManageCardsPanelContext.Provider>
  );
};
