import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { login } from '@/auth/endpoints';
import { getEmailValidator, getRequiredValidator } from '@/common/validators';
import { getDecodedToken } from '@/auth/utils';
import { useSetRecoilState } from 'recoil';
import { authJWTState, authTokenSelector } from '@/auth/atoms';
import { FORGOT_PASSWORD_PATH } from '@/auth/routes';
import { GInput } from '@/design-system/v3/g-input';
import { useSnack } from '@/common/hooks/use-snack';
import GymlySymbol from '@/gymly-symbol.svg?react';
import { Title } from '@/design-system/v3/title';
import Button from '@/design-system/v3/button';
import { ErrorCode } from '../../../common/types';
import { isErrorResponse } from '../../../common/utils';
import { PageWrapper } from '@/common/components/page-wrapper';
import { useTranslation } from 'react-i18next';

export const LoginView = () => {
  const { t } = useTranslation();
  const { successMessage, errorMessage } = useSnack();
  const setAuthToken = useSetRecoilState(authTokenSelector);
  const setAuthJWT = useSetRecoilState(authJWTState);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPath = location?.state?.fromPath?.pathname || '/';
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data: { email: string; password: string }) => {
    try {
      const jwtResponse = await login(data.email, data.password);

      setAuthToken(jwtResponse.jwt);
      setAuthJWT(getDecodedToken());
      successMessage('authView.snack.success.title', { detailedMessage: 'authView.snack.success.message' });
      navigate(fromPath);
    } catch (e: unknown) {
      if (isErrorResponse(e) && e.response?.data.code === ErrorCode.BAD_CREDENTIALS) {
        errorMessage('authView.snack.error.title', {
          detailedMessage: 'authView.snack.error.message',
        });
      } else {
        errorMessage('authView.snack.error.title', {
          detailedMessage: 'authView.snack.error.general.message',
        });
      }
    }
  };

  return (
    <PageWrapper
      withLocaleSwitcher
      content={
        <div className="h-contentHeight w-full flex items-center justify-center">
          <div className="w-full">
            <div className="flex flex-col">
              <GymlySymbol className="h-10 w-10 text-black-100 dark:text-white" />
              <Title className="mt-2 dark:text-black-10">{t('authView.title')}</Title>
              <form className="w-full mt-8" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col space-y-2">
                  <GInput
                    {...register('email', {
                      ...getEmailValidator(),
                    })}
                    autoComplete="username"
                    error={errors.email?.message?.toString()}
                    type="email"
                    placeholder={t('authView.form.email.placeholder')}
                    title={t('authView.form.email.title')}
                  />
                  <GInput
                    {...register('password', {
                      ...getRequiredValidator(),
                    })}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    placeholder={t('authView.form.password.placeholder')}
                    error={errors.password?.message?.toString()}
                    title={t('authView.form.password.title')}
                  />
                  <div className="flex flex-col space-y-2">
                    <Link to={FORGOT_PASSWORD_PATH} style={{ alignSelf: 'flex-end' }}>
                      {t('authView.forgotPassword.link')}
                    </Link>
                  </div>
                </div>
                <button type="submit" className="hidden" />
              </form>
            </div>
          </div>
        </div>
      }
      footerClasses="px-4"
      footer={
        <Button
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid && isDirty}
          loading={isSubmitting}
        >
          {t('signIn')}
        </Button>
      }
    />
  );
};
