import React, { useMemo } from 'react';
import { GAvatar } from '@/design-system/g-avatar';
import Button from '@/design-system/v3/button';
import { useTranslation } from 'react-i18next';
import { InvitationStatus } from '../../enums/InvitationStatus.enum';
import { InvitedFriend } from '../../types/InvitedFriend';

import LoadingIcon from '@/icons/loader.svg?react';
import CheckCircleIcon from '@/icons/check-circle.svg?react';
import { twMerge } from 'tailwind-merge';

interface Props {
  friendInvitation: InvitedFriend;
  onCancel: () => void;
}

export const BringAFriendInvitedItem = ({ friendInvitation, onCancel }: Props) => {
  const { t } = useTranslation();

  const { status, statusIcon } = useMemo(() => {
    let status, statusIcon;

    const iconClass = 'h-4 w-4';
    const checIcon = <CheckCircleIcon className={twMerge(iconClass, 'text-typo-positive')} />;
    switch (friendInvitation.status) {
      case InvitationStatus.ACCEPTED:
        status = t('inviteAccepted');
        statusIcon = checIcon;
        break;
      case InvitationStatus.PENDING:
      default:
        status = t('invitePending');
        statusIcon = <LoadingIcon className={iconClass} />;
        break;
    }

    return { status, statusIcon };
  }, [friendInvitation.status]);

  return (
    <div className="flex justify-between items-center p-4 bg-white">
      <div className="flex items-center space-x-4">
        <GAvatar size="lg" letter={friendInvitation.friend.details.firstName.charAt(0)} />
        <div>
          <h3>
            {friendInvitation.friend.details.firstName} {friendInvitation.friend.details.lastName}
          </h3>
          <div className="flex items-center">
            {statusIcon}
            <span className="ms-1">{status}</span>
          </div>
        </div>
      </div>
      {/* <div>
        {friendInvitation.status === InvitationStatus.PENDING && (
          <Button gsize="large" onClick={onCancel} variant="secondary">
            {t('cancel')}
          </Button>
        )}
      </div> */}
    </div>
  );
};
