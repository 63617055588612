import styled from 'styled-components';

export const Label1 = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  display: block;
`;

export const Label2 = styled(Label1)`
  font-weight: 700;
`;
