import { Controller, useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { activateUser, login } from '../../endpoints';
import { getEmailValidator, getPasswordValidator, getRequiredValidator } from '../../../common/validators';
import logo from '../../../logo.svg';
import { getDecodedToken } from '@/auth/utils';
import { useQuery } from 'react-query';
import { getBusiness } from '@/business/endpoints';
import { useSetRecoilState } from 'recoil';
import { authJWTState, authTokenSelector } from '@/auth/atoms';
import { GGrid } from '@/design-system/v3/g-grid';
import Text from '@/design-system/v3/text';
import { GInput } from '@/design-system/v3/g-input';
import Button from '@/design-system/v3/button';
import { Title } from '@/design-system/v3/title';
import { GLink } from '@/design-system/v3/g-link';
import { useTranslation } from 'react-i18next';
import { handleValidationError, isErrorResponse } from '@/common/utils';
import { ErrorCode } from '@/common/types';
import { useSnack } from '@/common/hooks/use-snack';

export const InvitationView = () => {
  const { t } = useTranslation();
  const { successMessage, errorMessage } = useSnack();
  const setAuthToken = useSetRecoilState(authTokenSelector);
  const setAuthJWT = useSetRecoilState(authJWTState);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const businessUuid = searchParams.get('businessId');
  const shouldRedirect = !token || !email || !businessUuid;
  const fromPath = (location?.state as any)?.fromPath?.pathname || '/';
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: email || '',
      password: '',
      confirmPassword: '',
    },
  });
  const { data: business, isFetching } = useQuery('FETCH_BUSINESS', () => {
    if (businessUuid) {
      return getBusiness(businessUuid);
    }

    return null;
  });

  const passwordsValues = watch(['password', 'confirmPassword']);
  const onSubmit = async (data: any) => {
    try {
      await activateUser({ password: data.password, token });
      const jwtResponse = await login(data.email, data.password);

      setAuthToken(jwtResponse.jwt);
      setAuthJWT(getDecodedToken());
      successMessage('signUp.succesfull');
      navigate(fromPath);
    } catch (e: any) {
      if (isErrorResponse(e) && e.response?.data.code === ErrorCode.ACCOUNT_TOKEN_EXPIRED) {
        errorMessage('Invitation expired', {
          detailedMessage: 'The invitation has expired. Ask your admin to send you a new one.',
        });
      } else if (isErrorResponse(e) && e.response?.data.code === ErrorCode.ACCOUNT_TOKEN_NOT_FOUND) {
        errorMessage('Invalid invitation', {
          detailedMessage: 'The invitation link is invalid. Ask your admin to send you a new one.',
        });
      } else {
        handleValidationError(e, () => null, errorMessage);
      }
    }
  };

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  if (isFetching) {
    return null;
  }

  return (
    <GGrid container>
      <GGrid item xs></GGrid>
      <GGrid item xs={12}>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-full max-w-[320px] text-center">
            <img src={logo} alt="logo" className="py-10 max-w-[120px] mx-auto my-0" />
            <Title className="mb-10" as="h3">
              {t('invitationView.title', { businessName: business?.name })}
            </Title>

            <form>
              <div className="flex flex-col space-y-3">
                <Controller
                  name={'email'}
                  control={control}
                  rules={getEmailValidator()}
                  render={({ field: { onChange, value } }) => (
                    <GInput
                      autoComplete="off"
                      readOnly
                      error={errors.email?.message?.toString()}
                      type="email"
                      title={t('authView.form.email.title')}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                <Controller
                  name={'password'}
                  control={control}
                  rules={getPasswordValidator()}
                  render={({ field: { onChange, value } }) => (
                    <GInput
                      type="password"
                      autoComplete="new-password"
                      error={errors.password?.message?.toString()}
                      title={t('authView.form.password.title')}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                <Controller
                  name={'confirmPassword'}
                  control={control}
                  rules={getRequiredValidator()}
                  render={({ field: { onChange, value } }) => (
                    <GInput
                      type="password"
                      autoComplete="new-password"
                      error={
                        errors.confirmPassword?.message?.toString() ||
                        (passwordsValues[0] !== value ? t('authView.form.password.notMatch') : undefined)
                      }
                      title={t('authView.form.password.confirm.title')}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                <Button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || passwordsValues[0] !== passwordsValues[1]}
                  loading={isSubmitting}
                >
                  {t('join')}
                </Button>
                <Text className="text-center">
                  Have already an account? <GLink to="/login">Login</GLink>
                </Text>
              </div>
            </form>
          </div>
        </div>
      </GGrid>
      <GGrid item xs></GGrid>
    </GGrid>
  );
};
