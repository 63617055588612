import { HomeCardVariant, PatchCardRequest, SaveCardRequest } from '@/web/modules/home-card/types';
import { gaxios } from '@/api';

/**
 * Get the home cards for a business.
 *
 * @param businessUuid The ID of the business.
 *
 * @returns The home cards for the business.
 */
export const getBusinessHomeCards = async (businessUuid: string): Promise<HomeCardVariant[]> => {
  const resp = await gaxios<{ cards: HomeCardVariant[] }>({
    method: 'GET',
    url: `/v1/businesses/${businessUuid}/home-cards`,
  });

  return resp.data.cards;
};

/**
 * Create a new home card for a business.
 *
 * @param businessId The ID of the business to create the home card for.
 * @param data The data to create the home card with.
 */
export const createHomeCard = async (businessId: string, data: SaveCardRequest): Promise<HomeCardVariant> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/businesses/${businessId}/home-cards`,
    data,
  });

  return resp.data;
};

/**
 * Patch a home card.
 *
 * @param businessId The ID of the business.
 * @param homeCardId The ID of the home card.
 * @param data The data to patch the home card with.
 */
export const patchHomeCard = async (
  businessId: string,
  homeCardId: string,
  data: PatchCardRequest,
): Promise<HomeCardVariant> => {
  const resp = await gaxios({
    method: 'PATCH',
    url: `/v1/businesses/${businessId}/home-cards/${homeCardId}`,
    data,
  });

  return resp.data;
};

/**
 * Patch a set of home cards.
 *
 * @param businessId The ID of the business.
 * @param data The data to patch the home cards with.
 */
export const patchAllHomeCard = async (
  businessId: string,
  data: Record<string, PatchCardRequest>,
): Promise<unknown> => {
  const resp = await gaxios({
    method: 'PATCH',
    url: `/v1/businesses/${businessId}/home-cards`,
    data,
  });

  return resp.data;
};

/**
 * Remove a home card.
 *
 * @param businessId The ID of the business.
 * @param homeCardId The ID of the home card.
 */
export const removeHomeCard = async (businessId: string, homeCardId: string): Promise<unknown> => {
  const resp = await gaxios({
    method: 'DELETE',
    url: `/v1/businesses/${businessId}/home-cards/${homeCardId}`,
  });

  return resp.data;
};

/**
 * Upload the image of a home card.
 *
 * @param businessId The ID of the business.
 * @param homeCardId The ID of the home card.
 * @param file The image file to upload.
 */
export const uploadHomeCardImage = async (businessId: string, homeCardId: string, file: File): Promise<unknown> => {
  const formData = new FormData();
  formData.append('file', file);

  const resp = await gaxios({
    method: 'POST',
    url: `/v1/businesses/${businessId}/home-cards/${homeCardId}/image`,
    data: formData,
  });

  return resp.data;
};
