import Text from '@/design-system/v3/text';
import { IconWrapper } from '../icon-wrapper';
import NotFound from '@/common/components/icon/icons/v2/not-found.svg?react';

export const NoResultsFound = ({ label }: { label?: string }) => {
  return (
    <div className="text-center opacity-30">
      <IconWrapper icon={NotFound} className="w-auto h-[120px] mx-auto" />
      <Text className="text-typo-secondary mt-6">{label || 'No results found'}</Text>
    </div>
  );
};
