import { twMerge } from 'tailwind-merge';

export const IconWrapper = ({
  icon,
  className,
}: {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  className?: string;
}) => {
  const Icon = icon;

  return <Icon className={twMerge('w-6', className)} />;
};
