import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type Props = {
  label: string;
  control: ReactNode;
  required?: boolean;
  optionalLabel?: boolean;
  size?: 'md' | 'sm';
};

export const GFormControlLabel = ({ label, control, required, optionalLabel = false, size = 'md' }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-1">
      <span className="block text-typo-secondary">
        <span
          className={clsx({
            'text-sm': size === 'md',
            'text-xs': size === 'sm',
          })}
        >
          {label}
        </span>
        {required && <span className="inline-block ml-1 text-purple-600">*</span>}
        {!required && optionalLabel && <span className="inline-block ml-1">({t('optional').toLowerCase()})</span>}
      </span>
      {control}
    </div>
  );
};
