import { FC, PropsWithChildren } from 'react';

type Props = {
  /**
   * The condition to check.
   */
  condition: boolean;
  /**
   * A render function that returns the wrapped children.
   *
   * @param children The children to wrap.
   */
  render: (children: React.ReactNode) => React.ReactElement;
};

/**
 * Wraps children with a component if condition is true.
 *
 * @param condition The condition to check.
 * @param children The children to wrap.
 * @param render A render function that returns the wrapped children.
 * @constructor
 */
export const ConditionalWrapper: FC<PropsWithChildren<Props>> = ({ condition, children, render }) => {
  if (!condition) {
    return <>{children}</>;
  }

  return render(children);
};
