import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '@/auth/endpoints';
import { getEmailValidator } from '@/common/validators';
import { useSnack } from '@/common/hooks/use-snack';
import GymlySymbol from '@/gymly-symbol.svg?react';
import { Title } from '@/design-system/v3/title';
import Button from '@/design-system/v3/button';
import { GInput } from '@/design-system/v3/g-input';
import { PageWrapper } from '@/common/components/page-wrapper';
import { useTranslation } from 'react-i18next';

export const ForgotPasswordView = () => {
  const { successMessage, errorMessage } = useSnack();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });
  const onSubmit = async (data: { email: string }) => {
    try {
      await resetPassword(data.email);

      successMessage('authView.forgotPassword.snack.success.title', {
        detailedMessage: 'authView.forgotPassword.snack.success.message',
      });
      navigate('/');
    } catch {
      errorMessage('authView.snack.error.title', { detailedMessage: 'authView.snack.error.general.message' });
    }
  };

  return (
    <PageWrapper
      withLocaleSwitcher
      content={
        <div className="h-contentHeight w-full flex items-center justify-center">
          <div className="w-full">
            <div className="flex flex-col">
              <GymlySymbol className="h-10 w-10 text-black-100" />
              <Title className="mt-2">{t('authView.forgotPassword.title')}</Title>
              <form className="w-full mt-8">
                <div className="flex flex-col space-y-2">
                  <GInput
                    {...control.register('email', {
                      ...getEmailValidator(),
                    })}
                    error={errors.email?.message?.toString()}
                    type="email"
                    placeholder={t('authView.form.email.placeholder')}
                    title={t('authView.form.email.title')}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      footerClasses="px-4"
      footer={
        <Button
          type="submit"
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid && isDirty}
          loading={isSubmitting}
        >
          {t('authView.forgotPassword.resetBtn')}
        </Button>
      }
    />
  );
};
