import { useTranslation } from 'react-i18next';
import { InstructionStep, InstructionStepProps } from './InstructionStep';
import { GCard } from '@/design-system/v3/g-card';
import { CardWithTitle } from '../card-with-title/CardWithTitle';

interface Props {
  title: string;
  steps: InstructionStepProps[];
}

export const InstructionSteps = ({ title, steps }: Props) => {
  return (
    <CardWithTitle title={title}>
      <ul className="divide-y divide-gray-200 break-words bg-white">
        {steps.map((step) => (
          <InstructionStep key={step.step} {...step} />
        ))}
      </ul>
    </CardWithTitle>
  );
};
