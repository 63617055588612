import { OptionsObject, useSnackbar } from 'notistack';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface ExtendedOptionsObject extends OptionsObject {
  detailedMessage?: string;
  detailedMessageParams?: Record<string, string>;
  icon?: ReactNode;
}

export type SnackFuncProps = (message: string, options?: ExtendedOptionsObject) => void;

export const useSnack = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const successMessage = useCallback(
    (message: string, options?: ExtendedOptionsObject) =>
      enqueueSnackbar(t(message), {
        ...options,
        variant: 'success',
        detailedMessage:
          options && options.detailedMessage ? t(options.detailedMessage, options.detailedMessageParams) : undefined,
      }),
    [enqueueSnackbar, t],
  );
  const errorMessage = useCallback(
    (message: string, options?: ExtendedOptionsObject) => {
      enqueueSnackbar(t(message), {
        ...options,
        variant: 'error',
        detailedMessage:
          options && options.detailedMessage ? t(options.detailedMessage, options.detailedMessageParams) : undefined,
      });
    },
    [enqueueSnackbar, t],
  );

  return {
    successMessage,
    errorMessage,
    closeMessage: closeSnackbar,
  };
};
