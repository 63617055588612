import { isValid as isIbanValid } from 'iban';
import { isValidPhoneNumber } from 'libphonenumber-js';
import i18next from 'i18next';

export const getRequiredValidator = () => ({
  required: i18next.t('requiredField'),
});

export const getContentValidator = () => ({
  pattern: {
    value: /.*\S.*/,
    message: i18next.t('invalidFormat'),
  },
  ...getRequiredValidator(),
});

export const getEmailValidator = () => ({
  pattern: {
    value: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,9}$/,
    message: i18next.t('invalidEmail'),
  },
  ...getRequiredValidator(),
});

export const getLinkValidator = (required = true) => ({
  pattern: {
    value: /https?:\/\/[\w.-]+(?:\.[\w.-]+)+[\w\-\\._~:\\?#\\[\]@%!\\$&'()*+,;=]*/,
    message: i18next.t('invalidLink'),
  },
  ...(required ? getRequiredValidator() : {}),
});

export const getNameValidator = (required = true) => ({
  pattern: {
    value: /^[\S ]*[\S][\S]*$/i,
    message: i18next.t('invalidFormat'),
  },
  ...(required ? getRequiredValidator() : {}),
});

export const getSlugValidator = () => ({
  pattern: {
    value: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/i,
    message: i18next.t('invalidFormat'),
  },
  ...getRequiredValidator(),
});

export const getPhoneNumberValidator = () => ({
  validate: (value: string | undefined | null): string | boolean => {
    return value && isValidPhoneNumber(value) ? true : i18next.t('invalidFormat');
  },
  ...getRequiredValidator(),
});

export const getPasswordValidator = () => ({
  pattern: {
    value: /^\S{10,}$/,
    message: i18next.t('invalidPassword'),
  },
  ...getRequiredValidator(),
});

export const getColorValidator = () => ({
  pattern: {
    value: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
    message: i18next.t('invalidFormat'),
  },
  ...getRequiredValidator(),
});

export const getIbanValidator = (optional = true) => ({
  validate: (value: string | undefined | null): string | boolean => {
    if (optional && (!value || value === '')) {
      return true;
    }

    return value && isIbanValid(value) ? true : i18next.t('invalidFormat');
  },
});
