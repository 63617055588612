import { usePrivacyPolicy } from '@/web/hooks/use-privacy-policy';
import { RichTextContent } from '@/common/components/rich-text-content';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BackToButton } from '@/web/views/extra-menu';
import { getProfilePath, PRIVACY_PATH, PROFILE_PATH } from '@/web/routes';
import { useTermsAndConditions } from '@/web/hooks/use-terms-and-conditions';
import Button from '@/design-system/v3/button';
import { getTermsAndConditionsPdf } from '@/business/endpoints';
import { useBusiness } from '@/web/hooks/use-business';

export const TermsAndConditions = () => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const { termsAndConditions } = useTermsAndConditions();

  const handleDownload = useCallback(() => {
    if (businessUuid) {
      getTermsAndConditionsPdf(businessUuid);
    }
  }, [businessUuid]);

  if (!termsAndConditions) {
    return <></>;
  }

  return (
    <PageWrapper
      header={<PageHeader title={t('termsAndConditions')} leftAction={<BackToButton path={PROFILE_PATH} />} />}
      content={
        <div className="relative overflow-scroll py-4">
          <RichTextContent html={termsAndConditions.content} />
        </div>
      }
      footer={
        <div className="px-4 pt-4">
          <Button variant="primary" onClick={handleDownload}>
            {t('download')}
          </Button>
        </div>
      }
    />
  );
};
