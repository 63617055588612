import clsx from 'clsx';
import React from 'react';

interface Props {
  icon?: React.ReactNode;
  title: string;
  description?: React.ReactNode;
}

export const BringAFriendHeader = ({ icon, title, description }: Props) => {
  return (
    <header>
      {icon && <div className="flex justify-center items-center">{icon}</div>}
      <div
        className={clsx('text-center', {
          'mt-6': icon,
        })}
      >
        <h1 className="text-2xl font-median font-semibold">{title}</h1>
        <p className="text-typo-secondary mt-1">{description}</p>
      </div>
    </header>
  );
};
