import { CardWithTitle } from '@/common/components/card-with-title/CardWithTitle';
import ClockIcon from '@/icons/clock.svg?react';
import MarkerPinIcon from '@/icons/marker-pin.svg?react';
import { BringAFriendCourseDetail } from '../../types/BringAFriendCourseDetail';
import { format } from 'date-fns';
import { useBusiness } from '@/web/hooks/use-business';
import { useTranslation } from 'react-i18next';
import { formatWithT, languageToLocale } from '@/i18n/utils';

interface Props {
  course: BringAFriendCourseDetail;
}

const subTitleClass = 'text-typo-tertiary text-sm';
const titleClass = 'text-2xl font-semibold';
const textClass = 'text-typo-secondary text-base';

export const BringAFriendClassInformation = ({ course }: Props) => {
  const { t, i18n } = useTranslation();
  const { businessLocations } = useBusiness();
  const location = businessLocations?.find((loc) => loc.id === course.locationId);

  const startAt = new Date(course.startAt);
  const endsAt = new Date(course.endAt);

  return (
    <CardWithTitle title={t('bringAFriend.informationAboutYourBooking')}>
      <div className="divide-y">
        <div className="p-5 space-y-1">
          <p className={subTitleClass}>{t('bringAFriend.course')}</p>
          <h3 className={titleClass}>{course.name}</h3>
          {course.teachers.length > 0 && (
            <p className={textClass}>{course.teachers.map((t) => t.fullName).join(', ')}</p>
          )}
        </div>

        <div className="p-5">
          <p className={subTitleClass}>{t('courseDetails.details.title')}</p>
          <h3 className={titleClass}>{formatWithT(startAt, 'EEEE, d MMMM yyyy')}</h3>
          <div className={textClass}>
            <div className="flex items-center">
              <MarkerPinIcon className="w-5 h-5" /> <span className="ms-1">{location?.name}</span>
            </div>
            <div className="flex items-center">
              <ClockIcon className="w-5 h-5" />
              <span className="ms-1">
                {format(startAt, 'HH:mm')} - {format(endsAt, 'HH:mm')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </CardWithTitle>
  );
};
