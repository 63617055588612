import { useBusiness } from '@/web/hooks/use-business';
import { InboxItem } from '@/common/components/inbox-item';
import { getUserNotifications } from '@/web/endpoints';
import { Notification } from './types';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { Outlet, useNavigate } from 'react-router-dom';
import { getMessagePath } from '@/web/routes';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { InfiniteScroll } from '@/common/components/infinite-scroll';

export const NotificationsView = () => {
  const { businessUuid } = useBusiness();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchData = useCallback(
    async (page: number, size: number) => {
      return getUserNotifications(businessUuid as string, { page, size });
    },
    [businessUuid],
  );

  return (
    <div className="overflow-scroll" ref={setContainerRef}>
      {containerRef && (
        <InfiniteScroll
          container={containerRef}
          fetchData={fetchData}
          onEmpty={<div className="flex items-center justify-center p-5">{t('noResults.messages')}</div>}
          render={(notification: Notification) => (
            <InboxItem
              key={notification.notificationId}
              notification={notification}
              onClick={() => {
                navigate(getMessagePath(notification.notificationId));
              }}
            />
          )}
          getId={(notification) => notification.notificationId}
          pageSize={20}
        />
      )}
    </div>
  );
};

export const InboxView = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper
      header={<PageHeader title={t('profileMenuView.inbox.title')} />}
      content={<Outlet />}
      contentClasses="px-0"
    />
  );
};
