import { Black05 } from '@/design-system/colors';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CourseCardWrapper = motion(styled(NavLink)`
  display: flex;
  color: black;
  justify-content: space-between;
  text-decoration: none;
`);
