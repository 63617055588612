import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { PrivacyContent, PrivacyFooter } from '../media-privacy';
import { useTranslation } from 'react-i18next';
import { PROFILE_PATH } from '@/web/routes';
import { BackToButton } from '../../extra-menu';

export const PrivacyView = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper
      header={
        <PageHeader title={t('profileMenuView.privacy.title')} leftAction={<BackToButton path={PROFILE_PATH} />} />
      }
      content={
        <>
          <PrivacyContent explanation={t('privacyView.explanation')} />
          <PrivacyFooter btnContent={t('save')} />
        </>
      }
      footerClasses="px-4"
    />
  );
};
