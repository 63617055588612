import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { useBusiness } from '@/web/hooks/use-business';
import { HomeCard } from '@/web/modules/home-card/components/HomeCard';
import SlideWideAdd from '@/common/components/icon/icons/v2/slide-wide-add.svg?react';
import { ManageCardsPanel } from '@/web/modules/home-card/panel/ManageCardsPanel';
import React, { useCallback, useState } from 'react';
import { useHomeCards } from '@/web/modules/home-card/hooks/useHomeCards';
import { useAuth } from '@/auth/hooks/use-auth';
import { PullToRefresh } from '@/common/components/pull-to-refresh';
import { useQueryClient } from 'react-query';

export const FETCH_BUSINESS_HOME_CARDS = 'FETCH_BUSINESS_HOME_CARDS';

export const HomeDiscovery = () => {
  const cards = useHomeCards();
  const { isAdminMember } = useAuth();
  const { businessUuid, businessLogo } = useBusiness();
  const queryClient = useQueryClient();
  const [manageCardsPanelOpen, setManageCardsPanelOpen] = useState(false);

  const handleManageCardsClick = () => {
    setManageCardsPanelOpen(true);
  };

  const handleManageCardsClose = useCallback(() => {
    setManageCardsPanelOpen(false);
  }, [setManageCardsPanelOpen]);

  const handleRefresh = useCallback(async () => {
    return queryClient.invalidateQueries([FETCH_BUSINESS_HOME_CARDS, businessUuid]);
  }, [queryClient, businessUuid]);

  return (
    <PageWrapper
      header={
        <PageHeader
          title={<img src={businessLogo} className="max-h-[24px] h-[24px] w-auto self-start" alt="logo" />}
          rightAction={
            <>
              {isAdminMember && (
                <button className="text-secondary" onClick={handleManageCardsClick}>
                  <SlideWideAdd className="h-6 w-6" />
                </button>
              )}
            </>
          }
        />
      }
      headerBorder={false}
      content={
        <div className="flex flex-col">
          {isAdminMember && <ManageCardsPanel open={manageCardsPanelOpen} onClose={handleManageCardsClose} />}
          <PullToRefresh onRefresh={handleRefresh}>
            <div className="grid grid-cols-2 gap-4 mt-4 pb-2">
              {(cards || []).map((card) => (
                <HomeCard key={card.id} card={card} />
              ))}
            </div>
          </PullToRefresh>
        </div>
      }
    />
  );
};
