import { gaxios } from '@/api';
import { InvitedFriend } from '../types/InvitedFriend';
import { BringAFriendInviteDetailsResponse } from '../types/BringAFriendInviteDetailsResponse';

export const BRING_A_FRIEND_INVITATIONS = 'BRING_A_FRIEND_INVITATIONS';

/**
 * Get all invited friends between the given dates
 * @param startDate expected format: yyyy-MM-dd
 * @param endDate  expected format: yyyy-MM-dd
 * @returns List of all invited friends between the given dates
 */
export const getInvitations = async (startDate: string, endDate: string): Promise<InvitedFriend[]> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/user/friends/invitations?startDate=${startDate}&endDate=${endDate}`,
  });

  return resp.data;
};

export const createAFriend = async (body: CreateAFriendRequest): Promise<Friend> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/friends`,
    data: body,
  });

  return resp.data;
};

export const inviteFriendToCourse = async (body: InviteFriendToCourseRequest): Promise<void> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/friends/invitations`,
    data: body,
  });

  return resp.data;
};

export const getInvitationDetail = async (
  invitationId: string,
  token: string,
): Promise<BringAFriendInviteDetailsResponse> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/friends/invitations/${invitationId}?token=${token}`,
  });

  return resp.data;
};

export const acceptFriendRequest = async (invitationId: string, token: string): Promise<void> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/friends/invitations/${invitationId}/accept?token=${token}`,
  });

  return resp.data;
};
