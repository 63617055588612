import { getNameValidator, getEmailValidator } from '@/common/validators';
import Button from '@/design-system/v3/button';
import { GInput } from '@/design-system/v3/g-input';
import GiftIcon from '@/icons/gift.svg?react';
import { BringAFriendInstructions } from '../BringAFriendInstructions/BringAFriendInstructions';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BringAFriendHeader } from '../BringAFriendHeader/BringAFriendHeader';
import { GCheckbox } from '@/design-system/v3/g-checkbox';

interface Props {
  title: string;
  description: string;
  isCourse: boolean;
  isSubmitting: boolean;
  onSubmit: (email: BringAFriendForm) => void;
  isFetching?: boolean;
}

export const BringAFriendForm = ({
  title,
  description,
  isCourse,
  isSubmitting,
  onSubmit,
  isFetching = false,
}: Props) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setError,
    watch,
  } = useForm<BringAFriendForm>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      isAcknowleding: false,
    },
  });

  const isAcknowleding = watch('isAcknowleding');

  const onSubmitForm = (data: BringAFriendForm) => {
    if (!isValid || !isDirty) {
      return;
    }

    if (!data.isAcknowleding && !isCourse) {
      setError('isAcknowleding', {
        type: 'manual',
        message: t('acknowledgeFriendGymAccessDisclaimer'),
      });
      return;
    }

    onSubmit(data);
  };

  return (
    <div>
      <BringAFriendHeader
        icon={<GiftIcon className="w-16 h-16 text-typo-primary" />}
        title={title}
        description={description}
      />
      <form className="mt-6 space-y-5">
        <GInput
          gsize="xl"
          error={errors.firstName?.message?.toString()}
          placeholder={`${t('authView.form.firstName.title')}*`}
          {...control.register('firstName', {
            ...getNameValidator(),
          })}
          required
        />

        <GInput
          error={errors.lastName?.message?.toString()}
          gsize="xl"
          placeholder={`${t('authView.form.lastName.title')}*`}
          required
          {...control.register('lastName', {
            ...getNameValidator(),
          })}
        />

        <GInput
          error={errors.email?.message?.toString()}
          gsize="xl"
          placeholder={`${t('authView.form.email.title')}*`}
          required
          {...control.register('email', {
            ...getEmailValidator(),
          })}
        />

        {!isCourse && !isFetching && (
          <div className="flex items-baseline space-x-2.5">
            <Controller
              name="isAcknowleding"
              control={control}
              render={({ field }) => (
                <GCheckbox
                  required
                  variant="secondary"
                  id="isAcknowleding"
                  onChange={field.onChange}
                  checked={field.value}
                />
              )}
            />
            <label htmlFor="isAcknowleding" className="text-typo-primary">
              {t('acknowledgeFriendGymAccessDisclaimer')}
            </label>
          </div>
        )}
        <Button
          disabled={!isValid || !isDirty || (!isAcknowleding && !isCourse) || isSubmitting}
          onClick={handleSubmit(onSubmitForm)}
          gsize="large"
          className="bg-primary text-primary-contrast-text"
        >
          {t('bringAFriend.sendInvite')}
        </Button>
      </form>

      <div className="mt-8">
        <BringAFriendInstructions isCourse={isCourse} />
      </div>
    </div>
  );
};
