import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { BackToButton } from '../../../web/views/extra-menu';
import { useTranslation } from 'react-i18next';
import { useCourseDetail } from '@/web/views/course-detail';
import { BringAFriendForm } from '../components/BringAFriendForm/BringAFriendForm';
import { useBusiness } from '@/web/hooks/use-business';
import { useState } from 'react';
import { BringAFriendCompleted } from '../components/BringAFriendCompleted/BringAFriendCompleted';
import { BRING_A_FRIEND_PROFILE_PATH, getProfilePath } from '@/web/routes';
import useBringAFriend from '../hooks/useBringAFriend';

enum BringAFriendSteps {
  FILL_IN_FORM = 1,
  COMPLETED = 2,
}

export const BringAFriend = () => {
  const { course, isFetching } = useCourseDetail();
  const { businessName } = useBusiness();
  const { t } = useTranslation();
  const [bringAFriendStep, setBringAFriendStep] = useState(BringAFriendSteps.FILL_IN_FORM);
  const [email, setEmail] = useState('');

  const { createInvitationMutation } = useBringAFriend();
  const { mutate, isLoading } = createInvitationMutation;

  const onSubmit = (data: BringAFriendForm) => {
    mutate(
      { data, course },
      {
        onSuccess: () => {
          setEmail(data.email);
          setBringAFriendStep(BringAFriendSteps.COMPLETED);
        },
      },
    );
  };

  return (
    <PageWrapper
      headerType="transparent"
      header={
        <PageHeader
          title={course ? course.name : t('overview')}
          titlePosition="left"
          leftAction={
            <BackToButton
              path={course ? `/courses/${course.id}/${course.startAt}` : getProfilePath(BRING_A_FRIEND_PROFILE_PATH)}
            />
          }
        />
      }
      className="bg-gray-50"
      contentClasses="px-0"
      disableFooter
      footerClasses="px-4 py-4 mb-0 shadow-footerCourseDetail"
      content={
        <section id="bring-a-friend" className="px-4 pt-2">
          {bringAFriendStep === BringAFriendSteps.COMPLETED ? (
            <BringAFriendCompleted isCourse={!!course} email={email} />
          ) : (
            <BringAFriendForm
              title={course ? t('bringAFriend.title') : t('bringAFriend.title.location')}
              description={
                course
                  ? t('bringAFriend.description.course', {
                      course: course.name,
                    })
                  : t('bringAFriend.description.location', {
                      businessName: businessName,
                    })
              }
              isFetching={isFetching}
              isSubmitting={isLoading}
              isCourse={!!course}
              onSubmit={onSubmit}
            />
          )}
        </section>
      }
    />
  );
};
