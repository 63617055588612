import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends HTMLAttributes<HTMLDivElement> {
  container?: boolean;
  item?: boolean;
  xs?: boolean | number;
  md?: number;
  children?: ReactNode;
  className?: string;
}
const generateXsWidth = (size: number | boolean | undefined) => {
  if (!size) return '';

  if (typeof size === 'boolean') {
    if (size) return 'auto-cols-auto';
  }

  switch (size) {
    case 1:
      return 'col-span-1';
    case 2:
      return 'col-span-2';
    case 3:
      return 'col-span-3';
    case 4:
      return 'col-span-4';
    case 5:
      return 'col-span-5';
    case 6:
      return 'col-span-6';
    case 7:
      return 'col-span-7';
    case 8:
      return 'col-span-8';
    case 9:
      return 'col-span-9';
    case 10:
      return 'col-span-10';
    case 11:
      return 'col-span-11';
    case 12:
      return 'col-span-12';
    default:
      return '';
  }
};

const generateMdWidth = (size: number | boolean | undefined) => {
  if (!size) return '';

  if (typeof size === 'boolean') {
    if (size) return 'auto-cols-auto';
  }
  switch (size) {
    case 1:
      return 'md:col-span-1';
    case 2:
      return 'md:col-span-2';
    case 3:
      return 'md:col-span-3';
    case 4:
      return 'md:col-span-4';
    case 5:
      return 'md:col-span-5';
    case 6:
      return 'md:col-span-6';
    case 7:
      return 'md:col-span-7';
    case 8:
      return 'md:col-span-8';
    case 9:
      return 'md:col-span-9';
    case 10:
      return 'md:col-span-10';
    case 11:
      return 'md:col-span-11';
    case 12:
      return 'md:col-span-12';
    default:
      return '';
  }
};

export const GGrid = ({ container, item, xs, md, children, className, ...rest }: Props) => {
  const classes = clsx({
    'grid grid-cols-12 w-full': container,
    [generateXsWidth(xs)]: xs && item,
    [generateMdWidth(md)]: md && item,
  });
  return (
    <div className={twMerge(classes, className)} {...rest}>
      {children}
    </div>
  );
};
