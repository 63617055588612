import { TOKEN_STORE } from '@/common/constants';
import { atom, selector } from 'recoil';
import { downloadPicture } from './endpoints';
import { JWT, User } from './types';

export const authUserState = atom<null | User>({
  key: 'AuthUserState',
  default: null,
  effects: [
    ({ onSet, setSelf }) => {
      onSet(async (user) => {
        if (!user) {
          return;
        }

        const userCopy = { ...user };
        if (userCopy?.profileImagePath) {
          const imageBlob = await downloadPicture(userCopy.profileImagePath);

          userCopy.profileImageUrl = URL.createObjectURL(imageBlob);
        }

        setSelf(userCopy as User);
      });
    },
  ],
});

export const authTokenState = atom<null | string>({
  key: 'AuthTokenState',
  default: null,
});

export const authTokenSelector = selector<null | string>({
  key: 'AuthTokenSelector',
  get: ({ get }) => get(authTokenState),
  set: ({ set }, newVal) => {
    if (newVal) {
      window.localStorage.setItem(TOKEN_STORE, newVal as string);
      // @ts-expect-error This only exists within mobile app
      window['ReactNativeWebView']?.postMessage?.(
        JSON.stringify({ type: 'SET_AUTH_TOKEN', payload: newVal as string }),
      );
    } else {
      window.localStorage.removeItem(TOKEN_STORE);
    }

    set(authTokenState, newVal);
  },
});

export const authJWTState = atom<null | JWT>({
  key: 'authJWTState',
  default: null,
});
