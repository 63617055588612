import { getProfilePath, PERSONAL_INFO_PATH } from '@/web/routes';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertVariant } from '@/design-system/v3/alert';
import Button from '@/design-system/v3/button';
import { useRecoilValue } from 'recoil';
import { authUserState } from '@/auth/atoms';
import { useUserDataSettings } from '@/web/hooks/use-user-data-settings';
import { Role, UserDataSetting } from '@/web/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const MissingDataAlert = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(authUserState);
  const { t } = useTranslation();
  const { isPropertyRequired, isUserDataLoaded } = useUserDataSettings();

  const labels: { [key in UserDataSetting]: string } = {
    [UserDataSetting.LAST_NAME_REQUIRED]: t('authView.form.lastName.title'),
    [UserDataSetting.DATE_OF_BIRTH_REQUIRED]: t('authView.form.dob.title'),
    [UserDataSetting.ADDRESS_REQUIRED]: t('addressForm.address.title'),
    [UserDataSetting.GENDER_REQUIRED]: t('gender'),
    [UserDataSetting.PHONE_NUMBER_REQUIRED]: t('phoneNumber'),
  };

  const missingFields = useMemo(() => {
    const fields: string[] = [];

    if (!isUserDataLoaded) {
      return fields;
    }

    if (isPropertyRequired(UserDataSetting.LAST_NAME_REQUIRED, Role.MEMBER) && !user?.lastName) {
      fields.push(labels[UserDataSetting.LAST_NAME_REQUIRED]);
    }

    if (isPropertyRequired(UserDataSetting.DATE_OF_BIRTH_REQUIRED, Role.MEMBER) && !user?.dateOfBirth) {
      fields.push(labels[UserDataSetting.DATE_OF_BIRTH_REQUIRED]);
    }

    if (isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER) && !user?.address) {
      fields.push(labels[UserDataSetting.ADDRESS_REQUIRED]);
    }

    if (isPropertyRequired(UserDataSetting.GENDER_REQUIRED, Role.MEMBER) && !user?.gender) {
      fields.push(labels[UserDataSetting.GENDER_REQUIRED]);
    }

    if (isPropertyRequired(UserDataSetting.PHONE_NUMBER_REQUIRED, Role.MEMBER) && !user?.phoneNumber) {
      fields.push(labels[UserDataSetting.PHONE_NUMBER_REQUIRED]);
    }

    return fields;
  }, [user, isPropertyRequired, isUserDataLoaded]);

  if (!isUserDataLoaded || missingFields.length === 0) {
    return <></>;
  }

  return (
    <Alert
      variant={AlertVariant.WARNING}
      title={t('missingDataAlert.title')}
      content={
        <div className="flex flex-col space-y-3">
          <span>
            {t('missingDataAlert.description')} ({missingFields.join(', ')}).
          </span>
          <div>
            <Button variant="secondary" fullWidth={false} onClick={() => navigate(getProfilePath(PERSONAL_INFO_PATH))}>
              {t('missingDataAlert.button.label')}
            </Button>
          </div>
        </div>
      }
      embedded
    />
  );
};
