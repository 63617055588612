import { useBusiness } from '@/web/hooks/use-business';
import {
  CourseAccessRestriction,
  CourseDetailMember,
  CourseDetailWithHelpers,
  CourseWithHelpers,
  MembershipEntriesControl,
  RoomLocation,
} from '@/web/types';
import { SnackFuncProps, useSnack } from '@/common/hooks/use-snack';
import { useMutation, useQueryClient } from 'react-query';
import {
  FETCH_COURSE_DETAIL_QUERY,
  FETCH_COURSE_MEMBERS_QUERY,
  FETCH_USER,
  joinWaitlist,
  subscribeToCourse,
  unsubscribeFromCourse,
} from '@/web/endpoints';
import { FETCH_WAITLIST } from '@/business/endpoints';
import Button from '@/design-system/v3/button';
import { format } from 'date-fns';
import { isApp, parseCourseISO, sendEvent } from '@/common/utils';
import { useAuth } from '../../../../auth/hooks/use-auth';
import { Alert, AlertVariant } from '@/design-system/v3/alert';
import { FunctionComponent, ReactNode, useMemo } from 'react';
import { sendPirschEvent } from '@/analytics/utils';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CourseJoinSpots } from '../course-spots';
import { CheckCircle, Hourglass } from 'phosphor-react';
import { useCourseDetail, useCourseStatusInfo } from '@/web/views/course-detail';
import { SkeletonLoading } from '../course-card';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import CalendarPlus2 from './calendar-plus-02.svg?react';
import { useRooms } from '@/web/hooks/use-rooms';
import { useNavigate } from 'react-router-dom';
import { getProfilePath, INVOICES_PATH, QRCODE_PATH } from '@/web/routes';
import QrCodeIcon from '@/common/components/icon/icons/v2/qrcode-alt.svg?react';
import { useSnackbar } from 'notistack';
import XCircleIcon from '@/icons/x-circle.svg?react';
import LockSolidIcon from '@/icons/lock-solid.svg?react';

export const AddToCalendarButton = ({ btnContent }: { btnContent?: ReactNode }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const btnText = btnContent || t('courseActions.button.addToCalendar');

  const queries = queryClient.getQueriesData<CourseDetailWithHelpers>([FETCH_COURSE_DETAIL_QUERY]);

  const course = queries[queries.length - 1]?.[1];
  if (!course) return null;

  return (
    <Button
      variant="secondary"
      className="flex items-center text-secondary"
      startIcon={<CalendarPlus2 className="w-4 h-4" />}
      gsize="large"
      onClick={() => {
        const start = format(course.originalStartAtDate, "yyyyMMdd'T'HHmmss");
        const end = format(course.originalEndAtDate, "yyyyMMdd'T'HHmmss");

        const locationName = course.businessLocation.name;

        sendEvent('addToCalendar');
        sendPirschEvent('Add to Calendar', {
          courseName: course.name,
        });

        window.open(
          encodeURI(
            `https://www.google.com/calendar/event?action=TEMPLATE&dates=${start}/${end}&text=${course.name}&location=${locationName}&sf=true`,
          ),
        );
      }}
    >
      {btnText}
    </Button>
  );
};

export const CourseActions = ({
  course,
  members,
}: {
  course: CourseDetailWithHelpers;
  members: CourseDetailMember[];
}) => {
  const { businessUser } = useBusiness();
  const { canUnsubscribe, canCancel, canSubscribe } = useCourseStatusInfo(course);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { memberships } = useAuth();
  const rooms = useRooms();
  const membership = memberships && memberships.length > 0 ? memberships[0] : null;
  const isCourseTeacher = course.teachers.some((teacher) => teacher.id === businessUser?.id);
  const isMember = members.find((member) => member.id === businessUser?.id);

  const showBookButton = !(
    isCourseTeacher ||
    course.isPast ||
    course.accessRestrictions.includes(CourseAccessRestriction.INVOICE_DUE) ||
    course.isCancelled
  );

  const room = useMemo(() => {
    return rooms.find((r) => r.id === course.roomId);
  }, [rooms, course]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-start space-y-2">
        {!isMember && course.accessRestrictions.includes(CourseAccessRestriction.INVOICE_DUE) && (
          <div className="flex flex-col space-y-2 w-full">
            <button
              className="py-3 px-5 bg-error text-white rounded-lg text-sm font-medium"
              onClick={() => navigate(getProfilePath(INVOICES_PATH))}
            >
              {t('payInvoiceNow')}
            </button>
            <div className="text-center font-median">
              <LockSolidIcon className="inline-flex mr-2" />
              {t('course.accessRestriction.invoiceDue')}
            </div>
          </div>
        )}
        {!isMember &&
          !course.isWaiting &&
          course.accessRestrictions.length > 0 &&
          !course.accessRestrictions.includes(CourseAccessRestriction.INVOICE_DUE) && (
            <Alert
              className="w-full"
              variant={AlertVariant.WARNING}
              content={
                <>
                  {t('courseActions.reasons.title')}
                  <ul className="list-disc pl-4 mt-2">
                    {course.accessRestrictions.map((restriction) => (
                      <li key={restriction}>
                        {restriction === CourseAccessRestriction.NO_ACCESS && t('courseActions.reasons.noAccess')}
                        {restriction === CourseAccessRestriction.NO_LOCATION_ACCESS &&
                          t('courseActions.reasons.noLocationAccess')}
                        {restriction === CourseAccessRestriction.TOO_EARLY && t('courseActions.reasons.tooEarly')}
                        {restriction === CourseAccessRestriction.NO_MEMBER && t('courseActions.reasons.noMember')}
                        {restriction === CourseAccessRestriction.NO_ACTIVE_MEMBERSHIP &&
                          t('courseActions.reasons.noActiveMembership')}
                        {restriction === CourseAccessRestriction.COURSE_TYPE_RESTRICTION &&
                          t('courseActions.reasons.courseTypeRestriction')}
                        {restriction === CourseAccessRestriction.COURSE_ENTRIES_LIMIT_RESTRICTION && (
                          <>
                            {membership?.entriesControl === MembershipEntriesControl.DAILY &&
                              t('courseActions.reasons.dailyLimit', {
                                count: membership?.entries || 0,
                              })}
                            {membership?.entriesControl === MembershipEntriesControl.WEEKLY &&
                              t('courseActions.reasons.weeklyLimit', {
                                count: membership?.entries || 0,
                              })}
                            {membership?.entriesControl === MembershipEntriesControl.MONTHLY &&
                              t('courseActions.reasons.monthlyLimit', {
                                count: membership?.entries || 0,
                              })}
                            {membership?.entriesControl === MembershipEntriesControl.FIXED_AMOUNT &&
                              t('courseActions.reasons.absoluteLimit', {
                                count: membership?.entries || 0,
                              })}
                          </>
                        )}
                        {restriction === CourseAccessRestriction.TIME_SLOT_NOT_ALLOWED &&
                          t('courseActions.reasons.timeSlotNotAllowed')}
                      </li>
                    ))}
                  </ul>
                </>
              }
            />
          )}
        <div className="flex flex-col space-y-2 w-full">
          {showBookButton && (
            <>
              {canUnsubscribe || canCancel ? (
                <>
                  {room && room.location === RoomLocation.ONLINE && room.onlineUrl && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        if (isApp()) {
                          window.location.href = room.onlineUrl;
                        } else {
                          window.open(room.onlineUrl, '_blank');
                        }
                      }}
                    >
                      Join online
                    </Button>
                  )}
                  <CourseUnsubscribeCancelButton />
                </>
              ) : canSubscribe ? (
                <CourseSubscribeButton />
              ) : (
                <CourseJoinButton />
              )}
            </>
          )}
          {(isMember || !course.accessRestrictions.includes(CourseAccessRestriction.INVOICE_DUE)) && (
            <CourseDetailsJoinStatus className="flex justify-center" />
          )}
        </div>
      </div>
    </div>
  );
};

const CourseJoinGoing = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center space-x-1 text-secondary">
      <CheckCircle size={16} weight="fill" />
      <div>{t('courseCard.isGoing.text')}</div>
    </div>
  );
};

const CourseJoinWaiting = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center space-x-1 text-typo-warning">
      <Hourglass size={16} weight="fill" />
      <div>{t('courseCard.isWaiting.text')}</div>
    </div>
  );
};

const CourseJoinButton = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { course, isFetching, shouldDisableJoinButton } = useCourseDetail();
  const { timeframeBeforeCancel } = useBusiness();
  const { errorMessage, successMessage } = useSnack();
  const { closeSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation(
    ({ courseId, date }: { courseId: string; date: string }) => subscribeToCourse(courseId, date),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY]);
        queryClient.invalidateQueries([FETCH_COURSE_MEMBERS_QUERY]);
        queryClient.invalidateQueries([FETCH_WAITLIST]);
        queryClient.invalidateQueries([FETCH_USER]);

        let button: { icon: FunctionComponent; onClick: () => void } | null = null;

        // If the course starts within 15 minutes, we add the option to go directly to the QR code.
        if (course && parseCourseISO(course.startAt, course).getTime() - Date.now() < 15 * 60 * 1000) {
          button = {
            onClick: () => {
              navigate(QRCODE_PATH);
              closeSnackbar();
            },
            icon: QrCodeIcon,
          };
        }

        successMessage('courseActions.snack.success.title', {
          detailedMessage: 'courseActions.snack.success.message',
          button,
        });
      },
      onError: (e: AxiosError<{ code: string }>) => {
        onError({ e, time: timeframeBeforeCancel, showErrorToast: errorMessage, t });
      },
    },
  );

  return (
    <Button
      type="submit"
      loading={isFetching || isLoading}
      gsize="large"
      disabled={shouldDisableJoinButton}
      onClick={() =>
        mutate({
          courseId: `${course?.id}`,
          date: `${course?.originalStartAt}`,
        })
      }
    >
      {t('courseActions.button.join')}
    </Button>
  );
};

const CourseUnsubscribeCancelButton = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { course, isFetching: isCourseFetching, canCancel, canUnsubscribe } = useCourseDetail();
  const { timeframeBeforeCancel, businessSettings } = useBusiness();
  const { errorMessage, successMessage } = useSnack();
  const { mutate, isLoading } = useMutation(
    ({ courseId, date }: { courseId: string; date: string }) => unsubscribeFromCourse(courseId, date),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY]);
        queryClient.invalidateQueries([FETCH_COURSE_MEMBERS_QUERY]);
        queryClient.invalidateQueries([FETCH_WAITLIST]);
        queryClient.invalidateQueries([FETCH_USER]);

        let message: string;
        let detailedMessage: string;

        if (canCancel) {
          message = 'courseActions.snack.cancellation.title';
          detailedMessage = 'courseActions.snack.cancellation.message';
        } else {
          message = 'courseActions.snack.unsubscribe.title';
          detailedMessage = 'courseActions.snack.unsubscribe.message';
        }

        successMessage(message, { detailedMessage });
      },
      onError: (e: AxiosError<{ code: string }>) => {
        onError({ e, time: timeframeBeforeCancel, showErrorToast: errorMessage, t });
      },
    },
  );

  const isLateCancellation = useMemo(() => {
    return (
      course &&
      course.startAt &&
      new Date(course.startAt).getTime() - Date.now() < (businessSettings?.cancelCourseMinutes ?? 0) * 60 * 1000
    );
  }, [course, businessSettings]);

  return (
    <Button
      loading={isLoading || isCourseFetching}
      withConfirmation={{
        title: t('courseActions.cancel.title'),
        content: (
          <div className="flex flex-col space-y-3">
            <span>
              {isLateCancellation && t('courseActions.snack.error.timeframe', { duration: timeframeBeforeCancel })}
            </span>
            <span>{t('courseActions.cancel.message')}</span>
          </div>
        ),
      }}
      gsize="large"
      variant="secondary"
      className="text-secondary"
      onClick={() =>
        mutate({
          courseId: `${course?.id}`,
          date: `${course?.originalStartAt}`,
        })
      }
    >
      {canUnsubscribe ? t('courseActions.button.unsubscribe') : t('courseActions.button.cancel')}
    </Button>
  );
};

const CourseSubscribeButton = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { course, isFetching: isCourseFetching } = useCourseDetail();
  const { timeframeBeforeCancel } = useBusiness();
  const { errorMessage, successMessage } = useSnack();
  const { mutate, isLoading: isSubmitting } = useMutation(
    ({ courseId, date }: { courseId: string; date: string }) => joinWaitlist(courseId, date),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY]);
        queryClient.invalidateQueries([FETCH_WAITLIST]);
        queryClient.invalidateQueries([FETCH_USER]);

        successMessage('courseActions.snack.waitlist.title', {
          detailedMessage: 'courseActions.snack.waitlist.message',
        });
      },
      onError: (e: AxiosError<{ code: string }>) => {
        onError({ e, time: timeframeBeforeCancel, showErrorToast: errorMessage, t });
      },
    },
  );

  return (
    <Button
      loading={isSubmitting || isCourseFetching}
      gsize="large"
      variant="secondary"
      className={clsx('flex text-primary')}
      onClick={() =>
        mutate({
          courseId: `${course?.id}`,
          date: `${course?.originalStartAt}`,
        })
      }
    >
      {t('courseActions.button.subscribe')}
    </Button>
  );
};

const onError = ({
  e,
  time,
  t,
  showErrorToast,
}: {
  e: AxiosError<{ code: string }>;
  time: string;
  t: TFunction;
  showErrorToast: SnackFuncProps;
}) => {
  const code = e?.response?.data?.code;
  let detailedMessage = e.message;

  if (code === 'too_late_to_cancel') {
    detailedMessage = t('courseActions.snack.error.timeframe', {
      count: +time,
    });
  } else if (code === 'course_already_started') {
    detailedMessage = t('courseActions.snack.error.alreadyStarted');
  } else if (code === 'course_insufficient_capacity') {
    detailedMessage = t('courseActions.snack.error.capacity');
  } else {
    detailedMessage = t('courseActions.snack.error.general');
  }

  showErrorToast('Error', { detailedMessage });
};

export const CourseDetailsJoinStatus = ({
  className,
  withSpots = true,
}: {
  className?: string;
  withSpots?: boolean;
}) => {
  const { t } = useTranslation();
  const { course, isInWaitlist, isFetching, isMember, spots } = useCourseDetail();

  let content;

  if (isFetching) {
    content = <SkeletonLoading className="w-[120px] h-4" />;
  } else if (course?.isCancelled === true) {
    content = (
      <div className="flex items-center space-x-1 text-typo-negative">
        <XCircleIcon className="w-4 h-4" />
        <span>{t('cancelled')}</span>
      </div>
    );
  } else if (isMember) {
    content = <CourseJoinGoing />;
  } else if (isInWaitlist) {
    content = <CourseJoinWaiting />;
  } else {
    content = withSpots ? <CourseJoinSpots spots={spots} capacity={course?.capacity ?? 0} /> : null;
  }

  return content ? <div className={className}>{content}</div> : null;
};

export const CourseJoinStatus = ({ className, course }: { className?: string; course: CourseWithHelpers }) => {
  const { isMember, isInWaitlist } = useCourseStatusInfo(course);

  let content = null;

  if (isMember) {
    content = <CourseJoinGoing />;
  } else if (isInWaitlist) {
    content = <CourseJoinWaiting />;
  }

  return <div className={className}>{content}</div>;
};
