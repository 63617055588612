import { skeletonAnimationClasses } from '@/web/utils';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface Props {
  showIcon?: boolean;
}

export const BringAFriendHeaderSkeleton = ({ showIcon }: Props) => {
  return (
    <header>
      {showIcon && (
        <div className="flex justify-center items-center">
          <div className={twMerge('w-12 h-12 bg-gray-200 rounded-full', skeletonAnimationClasses)}></div>
        </div>
      )}
      <div className={clsx('text-center', 'mt-6')}>
        <div className={twMerge('h-6 bg-gray-200 rounded w-1/2 mx-auto', skeletonAnimationClasses)}></div>
        <div className={twMerge('h-4 bg-gray-200 rounded w-3/4 mx-auto mt-2', skeletonAnimationClasses)}></div>
      </div>
    </header>
  );
};
