import { ReactNode, useMemo } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { Title } from '../title';
import { useTranslation } from 'react-i18next';
import XIcon from '@/common/components/icon/icons/v2/x.svg?react';
import { IconWrapper } from '@/common/components/icon-wrapper';
import ArrowLeft from '@/common/components/icon/icons/v2/arrow-left.svg?react';

type Props = {
  title?: ReactNode;
  actions?: ReactNode;
  showCloseButton?: boolean;
  open: boolean;
  closeButtonVariant?: 'icon' | 'text';
  autoHeight?: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  childrenClassName?: string;
  onBack?: (() => void) | null;
  drag?: boolean;
};

const drawerVariants = {
  hidden: {
    y: '100%',
  },
  visible: {
    y: '0%',
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 200,
    },
  },
  exit: {
    y: '100%',
    transition: {
      duration: 0.2,
    },
  },
};

export const GPanel = ({
  title,
  open,
  actions,
  autoHeight = false,
  closeButtonVariant = 'text',
  showCloseButton = true,
  onClose,
  children,
  className,
  childrenClassName,
  onBack,
  drag = true,
}: Props) => {
  const { t } = useTranslation();

  const containerStyles = useMemo(() => {
    if (!window.safeAreaInsets) {
      return {};
    }

    return {
      maxHeight: `calc(100vh - ${window.safeAreaInsets.top + window.safeAreaInsets.bottom}px)`,
      paddingBottom: window.safeAreaInsets.bottom,
    };
  }, []);

  return (
    <AnimatePresence>
      {open && (
        <FloatingPortal>
          <motion.div
            className="fixed z-[49] bottom-0 left-0 top-0 right-0 w-full bg-black-100/50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={drawerVariants}
            dragElastic={0.2}
            onDragEnd={(_, info) => {
              if (info.offset.y > 20) {
                onClose();
                return;
              }
            }}
            dragConstraints={{ left: 0, top: 0, right: 0, bottom: 25 }}
            drag={drag ? 'y' : undefined}
            className={twMerge(
              clsx(
                'fixed flex flex-col z-50 left-0 bottom-0 rounded-t-lg right-0 w-full max-h-[95vh] bg-white overflow-hidden dark:bg-black-100 dark:text-gray',
                {
                  'h-[calc(var(--screen-height)-40px)]': !autoHeight,
                },
              ),
              className,
            )}
            style={containerStyles}
          >
            <header
              className={clsx('flex flex-col items-center py-3', {
                'border-b border-b-borders-secondary': title !== undefined,
              })}
            >
              {drag && <motion.div className="rounded-full w-[46px] h-[6px] bg-gray-100 shadow-inner mb-3" />}
              {title && (
                <div className="flex relative items-center px-4 flex-1 max-h-[64px] w-full">
                  {!onBack && <div className="w-8 h-8" />}
                  {onBack && (
                    <button className="w-8 h-8 flex" onClick={onBack}>
                      <IconWrapper icon={ArrowLeft} />
                    </button>
                  )}
                  <div className="flex items-center justify-center flex-1 grow">
                    <Title as="h2" variant="title4" className="leading-none">
                      {title}
                    </Title>
                  </div>
                  <div>
                    {showCloseButton && (
                      <button onClick={onClose} className="text-primary">
                        {closeButtonVariant === 'text' && t('done')}
                        {closeButtonVariant === 'icon' && <XIcon className="text-typo-tertiary" />}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </header>

            <div className={clsx('overflow-y-auto flex-1 p-4', childrenClassName)}>{children}</div>

            {actions && (
              <div className="flex flex-1 max-h-[64px] px-4 items-center justify-end relative">{actions}</div>
            )}
          </motion.div>
        </FloatingPortal>
      )}
    </AnimatePresence>
  );
};
