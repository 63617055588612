import { format } from 'date-fns';
import {
  acceptFriendRequest,
  BRING_A_FRIEND_INVITATIONS,
  createAFriend,
  getInvitations,
  inviteFriendToCourse,
} from '../api/BringAFriendApi';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { CourseDetailWithHelpers } from '@/web/types';
import { useSnack } from '@/common/hooks/use-snack';
import { handleValidationError } from '@/common/utils';
import { InvitedFriend } from '../types/InvitedFriend';
import { useTranslation } from 'react-i18next';
import { ErrorCode } from '@/common/types';
import { useBusiness } from '@/web/hooks/use-business';
import { useAuth } from '@/auth/hooks/use-auth';
import { useMemo } from 'react';

type Options =
  | Omit<UseQueryOptions<InvitedFriend[], unknown, InvitedFriend[], string[]>, 'queryKey' | 'queryFn'>
  | undefined;

const FETCH_BRING_A_FRIEND_LIMIT = 'FETCH_BRING_A_FRIEND_LIMIT';

const useBringAFriend = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { errorMessage } = useSnack();
  const { businessUuid, businessMemberships, businessName } = useBusiness();
  const { t } = useTranslation();

  // Fetch invitations
  const useInvitationsQuery = (options?: Options) => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const firstDayString = format(firstDay, 'yyyy-MM-dd');
    const lastDayString = format(lastDay, 'yyyy-MM-dd');

    return useQuery(
      [BRING_A_FRIEND_INVITATIONS],
      async () => await getInvitations(firstDayString, lastDayString),
      options,
    );
  };

  // Create invitation
  const createInvitationMutation = useMutation(
    async ({ data, course }: { data: CreateAFriendRequest; course?: CourseDetailWithHelpers | null }) => {
      try {
        const friendResponse = await createAFriend(data);
        if (course) {
          await inviteFriendToCourse({
            friendId: friendResponse.id,
            course: {
              id: course.id,
              date: course.startAt,
            },
          });
        }

        queryClient.invalidateQueries(BRING_A_FRIEND_INVITATIONS);
        return friendResponse;
      } catch (e: any) {
        if (e?.response?.data?.code === ErrorCode.ACCOUNT_EMAIL_ALREADY_IN_USE) {
          errorMessage('emailAlreadyRegistered');
        } else if (e?.response?.data?.code === 'friend_member_of_business') {
          errorMessage('bringAFriend.snack.error.friendMemberOfBusiness.title', {
            detailedMessage: 'bringAFriend.snack.error.friendMemberOfBusiness.description',
            detailedMessageParams: { businessName: businessName ?? '' },
          });
        } else {
          handleValidationError(e, undefined, errorMessage);
        }

        throw e;
      }
    },
  );

  // Accept invitation mutation
  const acceptFriendRequestMutation = useMutation(
    async ({ invitationId, token }: { invitationId: string; token: string }) => {
      try {
        await acceptFriendRequest(invitationId, token);
      } catch (e: any) {
        if (e?.response?.data?.code === ErrorCode.ACCOUNT_EMAIL_ALREADY_IN_USE) {
          errorMessage(t('emailAlreadyRegistered'));
        } else {
          handleValidationError(e, undefined, errorMessage);
        }

        throw e;
      }
    },
  );

  const { data: limit } = useQuery([FETCH_BRING_A_FRIEND_LIMIT, businessUuid], async () => {
    if (!businessUuid || !businessMemberships) {
      return 0;
    }

    const userMemberships =
      user?.businesses
        .find((business) => business.businessId === businessUuid)
        ?.memberships.map((membership) => membership.membershipId) ?? [];

    return businessMemberships
      .filter((membership) => {
        return userMemberships.includes(membership.id);
      })
      .reduce((acc, membership) => {
        const currentValue = membership.userBringAFriendGrant?.value ?? 0;
        return currentValue > acc ? currentValue : acc;
      }, 0);
  });

  return useMemo(
    () => ({
      invitationsQuery: useInvitationsQuery,
      createInvitationMutation,
      acceptFriendRequestMutation,
      limit: limit,
    }),
    [limit, acceptFriendRequestMutation, createInvitationMutation, useInvitationsQuery],
  );
};

export default useBringAFriend;
