import { popoverMenuState } from '@/common/atoms';
import { useRecoilState } from 'recoil';
import {
  FloatingOverlay,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react';
import clsx from 'clsx';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';

export const PopoverMenus = () => {
  const [popoverMenu, setPopoverMenu] = useRecoilState(popoverMenuState);
  const { refs, floatingStyles } = useFloating({
    placement: popoverMenu.placement || 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({ fallbackAxisSideDirection: 'end' }),
      shift(),
      offset(10),
      size({
        apply({ elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${elements.reference.getBoundingClientRect().width}px`,
          });
        },
      }),
    ],
    elements: {
      reference: popoverMenu.anchor,
    },
  });
  const onClose = () => {
    setPopoverMenu({
      ...popoverMenu,
      anchor: null,
      key: undefined,
      component: null,
    });

    if (popoverMenu.handleClose) {
      popoverMenu.handleClose();
    }
  };
  const items = popoverMenu.items;

  return (
    <>
      <FloatingOverlay onClick={onClose} className={clsx('bg-gray/20 z-10', { hidden: !popoverMenu.anchor })} />
      <FloatingPortal>
        <Transition
          as={Fragment}
          show={!!popoverMenu.anchor}
          enter="transition-opacity ease-in duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="bg-white rounded-md shadow-md overflow-hidden z-20"
          >
            {items ? (
              items.map((item, index) => (
                <button
                  key={`popover-item-${index}`}
                  className="flex flex-col active:bg-gray-25 border-t border-gray-50 first:border-none"
                  style={{
                    padding: 16,
                    width: '100%',
                    fontSize: 14,
                  }}
                  onClick={() => {
                    item.onClick();
                    onClose();
                  }}
                >
                  {item.render}
                </button>
              ))
            ) : (
              <>{popoverMenu?.component || null}</>
            )}
          </div>
        </Transition>
      </FloatingPortal>
    </>
  );
};
