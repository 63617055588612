import { useQuery } from 'react-query';
import { getBusinessHomeCards } from '@/web/modules/home-card/api';
import { useBusiness } from '@/web/hooks/use-business';

export const FETCH_BUSINESS_HOME_CARDS = 'FETCH_BUSINESS_HOME_CARDS';

export const useHomeCards = () => {
  const { businessUuid } = useBusiness();
  const { data } = useQuery([FETCH_BUSINESS_HOME_CARDS, businessUuid], async () => {
    if (businessUuid) {
      return getBusinessHomeCards(businessUuid);
    }
  });

  return data || [];
};
