import Button from '@/design-system/v3/button';
import { Title } from '@/design-system/v3/title';
import { Prohibit } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

const NoActiveMembershipView = () => {
  const { t } = useTranslation();

  const onRetry = () => {
    window.location.href = '/';
  };
  return (
    <div className="h-screenHeight justify-center flex items-center p-4">
      <div className="text-center flex flex-col items-center justify-center">
        <Prohibit size={68} className="text-typo-secondary/30 my-10" />
        <Title>{t('noActiveMembership')}</Title>
        <p className="text-typo-secondary my-2">{t('noActiveMembership.description')}</p>
        <Button variant="secondary" className="w-auto mt-2" onClick={onRetry}>
          {t('retry')}
        </Button>
      </div>
    </div>
  );
};

export default NoActiveMembershipView;
