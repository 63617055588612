import { t } from 'i18next';
import { BringAFriendInstructions } from '../BringAFriendInstructions/BringAFriendInstructions';
import CheckCircleIcon from '@/icons/check-circle.svg?react';
import { BringAFriendHeader } from '../BringAFriendHeader/BringAFriendHeader';

interface Props {
  isCourse: boolean;
  email: string;
}

export const BringAFriendCompleted = ({ isCourse, email }: Props) => {
  return (
    <div>
      <BringAFriendHeader
        icon={<CheckCircleIcon className="w-16 h-16 text-typo-positive" />}
        title={t('inviteSent')}
        description={t('email.sentTo', {
          email: email,
        })}
      />
      <div className="mt-8">
        <BringAFriendInstructions isCourse={isCourse} />
      </div>
    </div>
  );
};
