import { businessLocationsState } from '@/business/atoms';
import { GSelect, SelectProps } from '@/design-system/v3/g-select';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';
import { useCallback } from 'react';

export const LocationSelect = ({ onChange, ...props }: Omit<SelectProps, 'options'>) => {
  const locations = useRecoilValue(businessLocationsState);
  const { t } = useTranslation();

  const handleOnChange = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      onChange?.(ev);
      triggerHapticFeedback(HapticFeedbackType.IMPACT_MEDIUM);
    },
    [onChange],
  );

  if (!locations) {
    return <>...</>;
  }

  return (
    <GSelect
      onChange={handleOnChange}
      options={locations.map((location) => ({
        label: location.name,
        value: location.id,
      }))}
      placeholder={t('bookingFlow.locationSelect.placeholder')}
      {...props}
    />
  );
};
