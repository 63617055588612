import { businessMembershipsState } from '@/business/atoms';
import { GSelect, SelectProps } from '@/design-system/v3/g-select';
import { useRecoilValue } from 'recoil';

export const MembershipSelect = (props: Omit<SelectProps, 'options'>) => {
  const memberships = useRecoilValue(businessMembershipsState);

  if (!memberships?.length) {
    return <>Loading...</>;
  }

  return (
    <GSelect
      options={memberships.map((membership) => ({
        label: membership.name,
        value: membership.id,
      }))}
      placeholder="Pick a Membership"
      {...props}
    />
  );
};
