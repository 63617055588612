import * as xss from 'xss';

export const SafeHtml = ({ html }: { html: string | null | undefined }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: xss.filterXSS(html || '', {
          whiteList: {
            p: [],
            br: [],
            div: [],
            h1: [],
            h2: [],
            h3: [],
            a: ['href', 'target', 'rel'],
            ul: [],
            ol: [],
            li: [],
            span: [],
            strong: [],
          },
        }),
      }}
    />
  );
};
