import { MediaPrivacyView } from '@/web/views/profile/media-privacy';
import { RequireAuth } from './components/require-auth';
import { RequireNoAuth } from './components/require-no-auth';
import { ForgotPasswordView } from './views/forgot-password';
import { GuestActivationView } from './views/guest-activation';
import { InvitationView } from './views/invitation';
import { LoginView } from './views/login';
import { LogoutView } from './views/logout';
import { OnboardingFormProvider, OnboardingView } from './views/onboarding';
import { ResetPasswordView } from './views/reset-password';
import { SignupView } from './views/signup';
import BringAFriendInvitation from '@/features/bring-a-friend/views/BringAFriendInvitation/BringAFriendInvitation';
import { Outlet } from 'react-router-dom';
import NoActiveMembershipView from './views/no-active-membership/NoActiveMembershipView';

export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/password-reset';
export const LOGOUT_PATH = '/logout';
export const ONBOARDING_PATH = '/onboarding';
export const MEDIA_PRIVACY_PATH = '/media-privacy';
export const ONBOARDING_SPECIFIC_PATH = '/onboarding/:id';
export const SIGNUP_PATH = '/signup/:id';
export const GUEST_ACTIVATION_PATH = '/guest-activation';
export const NO_ACTIVE_MEMBERSHIP = '/no-active-membership';

export const INVITATION_PATH = '/invitation';
export const BRING_A_FRIEND_INVITATION_PATH = 'friend/:invitationId';

export const AUTH_ROUTES = [
  {
    path: RESET_PASSWORD_PATH,
    element: (
      <>
        <ResetPasswordView />
      </>
    ),
  },
  {
    path: FORGOT_PASSWORD_PATH,
    element: (
      <RequireNoAuth>
        <ForgotPasswordView />
      </RequireNoAuth>
    ),
  },
  {
    path: LOGIN_PATH,
    element: (
      <RequireNoAuth>
        <LoginView />
      </RequireNoAuth>
    ),
  },
  {
    path: LOGOUT_PATH,
    element: (
      <RequireAuth>
        <LogoutView />
      </RequireAuth>
    ),
  },

  {
    path: ONBOARDING_PATH,
    element: (
      <RequireAuth isStaying>
        <OnboardingFormProvider>
          <OnboardingView />
        </OnboardingFormProvider>
      </RequireAuth>
    ),
  },

  {
    path: MEDIA_PRIVACY_PATH,
    element: (
      <RequireAuth isMediaPrivacy>
        <MediaPrivacyView />
      </RequireAuth>
    ),
  },

  {
    path: ONBOARDING_SPECIFIC_PATH,
    element: (
      <OnboardingFormProvider>
        <OnboardingView />
      </OnboardingFormProvider>
    ),
  },
  {
    path: SIGNUP_PATH,
    element: (
      <RequireNoAuth>
        <SignupView />
      </RequireNoAuth>
    ),
  },
  {
    path: INVITATION_PATH,
    element: <Outlet />,
    nestedRoutes: [
      {
        path: '',
        element: (
          <RequireNoAuth>
            <InvitationView />
          </RequireNoAuth>
        ),
      },
      {
        path: BRING_A_FRIEND_INVITATION_PATH,
        element: <BringAFriendInvitation />,
      },
    ],
  },
  {
    path: GUEST_ACTIVATION_PATH,
    element: (
      <RequireNoAuth>
        <GuestActivationView />
      </RequireNoAuth>
    ),
  },
  {
    path: NO_ACTIVE_MEMBERSHIP,
    element: (
      <RequireAuth isStaying>
        <NoActiveMembershipView />
      </RequireAuth>
    ),
  },
];
