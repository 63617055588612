import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/browser';
import App from './App';
import { AuthInit } from '@/auth/components/auth-init';
import { queryClient } from '@/query-client';
import { BusinessInit } from '@/business/components/business-init';
import { RecoilRoot } from 'recoil';
import { Modals } from '@/common/components/modals';
import { PopoverMenus } from '@/common/components/popover-menus';
import { GNotification } from './design-system/v3/g-notification';
import { Panels } from './common/components/panels';
import './index.css';
import { Theme } from '@/Theme';
import { Router } from '@/common/Router';
import { history } from '@/common/history';
import '@/i18n';
import '@/common/native-bridge';
import { isApp } from '@/common/utils';
import { MaintenanceScreen } from '@/common/maintenance-screen';

if (window.isProd || window.isStaging || window.isDemo) {
  Sentry.init({
    dsn: 'https://53042374c8774b934811332c2114fecb@o1225392.ingest.us.sentry.io/4508019217596416',
    environment: window.isProd ? 'production' : window.isDemo ? 'demo' : 'staging',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
      event.tags = {
        ...event.tags,
        app: isApp(),
        appVersion: window.AppVersion,
      };

      return event;
    },
  });
}

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <SnackbarProvider
            dense
            autoHideDuration={2500}
            classes={{
              containerRoot: 'pb-12 left-4',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            maxSnack={3}
            Components={{
              default: GNotification,
              success: GNotification,
              error: GNotification,
              warning: GNotification,
              info: GNotification,
            }}
          >
            <Theme>
              <MaintenanceScreen>
                <AuthInit>
                  <BusinessInit>
                    <App />
                    <Modals />
                    <Panels />
                    <PopoverMenus />
                  </BusinessInit>
                </AuthInit>
              </MaintenanceScreen>
            </Theme>
          </SnackbarProvider>
        </Router>
      </QueryClientProvider>
    </RecoilRoot>,
  );
}
