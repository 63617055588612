import Button from '@/design-system/v3/button';
import { GCard } from '@/design-system/v3/g-card';
import { useBusiness } from '@/web/hooks/use-business';
import logo from '@/logo.svg';
import clsx from 'clsx';
import { Title } from '@/design-system/v3/title';
import { OnboardingTransition } from '../onboarding-transition';
import { useTranslation } from 'react-i18next';

interface Props {
  onNextStep: (id: string) => void;
  onPreviousStep?: () => void;
}

export const OnboadingLocation = ({ onNextStep }: Props) => {
  const { t } = useTranslation();
  const { businessLocations } = useBusiness();

  if (businessLocations && businessLocations.length === 1) {
    onNextStep(businessLocations[0].id);
    return <></>;
  }

  return (
    <OnboardingTransition>
      <div className="mb-10 px-4 pt-4">
        <Title className="mb-8">{t('onboarding.location.title')}</Title>
        <div className="grid gap-5 grid-cols-1 md:grid-cols-2 ">
          {businessLocations?.map((location) => {
            const hasImage = !!location.publicImageUrl;

            return (
              <GCard key={location.id}>
                <GCard.Content>
                  <div
                    className={clsx('bg-center bg-no-repeat rounded-md w-full h-[50vw] md:h-40 mb-2', {
                      'bg-[length:200px] border': !hasImage,
                      'bg-cover': hasImage,
                    })}
                    style={{ backgroundImage: `url(${location.publicImageUrl || logo})` }}
                  />
                  <Title variant="title5" className="mb-6">
                    {location.name}
                  </Title>
                </GCard.Content>
                <GCard.Actions>
                  <Button className="w-full" onClick={() => onNextStep(location.id)}>
                    {t('selectLocation')}
                  </Button>
                </GCard.Actions>
              </GCard>
            );
          })}
        </div>
      </div>
    </OnboardingTransition>
  );
};
