import React, { useEffect } from 'react';
import { OnboardingProfileForm } from './views/onboarding-profile-form';
import { OnboardingSignIn } from './views/onboarding-sign-in';
import { useAuth } from '@/auth/hooks/use-auth';

interface Props {
  onNextStep: (isNewUser: boolean) => void;
  onPreviousStep?: () => void;
}

enum UserInformationViews {
  ProfileForm = 'ProfileForm',
  SignIn = 'SignIn',
}

export const OnboardingUserInformation = ({ onNextStep, onPreviousStep }: Props) => {
  const [view, setView] = React.useState(UserInformationViews.ProfileForm);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      onNextStep(false);
    }
  }, [user]);

  return (
    <>
      {view === UserInformationViews.ProfileForm && (
        <OnboardingProfileForm
          onSignIn={() => setView(UserInformationViews.SignIn)}
          onNextStep={() => onNextStep(true)}
          onPreviousStep={onPreviousStep}
        />
      )}
      {view === UserInformationViews.SignIn && (
        <OnboardingSignIn
          onNextStep={() => onNextStep(false)}
          onPreviousStep={() => setView(UserInformationViews.ProfileForm)}
        />
      )}
    </>
  );
};
