import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  variant?: 'default' | 'danger' | 'warning';
  size?: 'small' | 'medium';
}

export const GBoxedInfo = ({ className, variant = 'default', size = 'small', children, ...rest }: Props) => {
  const classes = clsx('rounded-md leading-none text-xs inline-block border self-start', {
    'py-1 px-[6px]': size === 'small',
    'px-3 py-2': size === 'medium',
    'border-gray-50 bg-gray-25 text-typo-primary': variant === 'default',
    'border-warning-100 bg-[#FFF3CE] text-[#6E4A02]': variant === 'warning',
    'border-error-100 bg-error-50 text-error-900': variant === 'danger',
  });
  return (
    <div className={twMerge(classes, className)} {...rest}>
      {children}
    </div>
  );
};
