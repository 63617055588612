import { useParams } from 'react-router-dom';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { useQueryClient } from 'react-query';
import { FETCH_COURSES } from '@/web/endpoints';
import { PullToRefresh } from '@/common/components/pull-to-refresh';
import { BookingFlow, BookingFlowNavigation } from '@/common/components/booking-flow';
import { useBusiness } from '@/web/hooks/use-business';
import { useMemo } from 'react';
import { BackToButton } from '@/web/views/extra-menu';
import { FiltersPanel } from '@/common/components/booking-flow/filters-panel';

export const CourseBookingFlow = () => {
  const { id } = useParams<{ id: string }>();
  const { businessCTypes } = useBusiness();
  const queryClient = useQueryClient();

  const courseType = useMemo(() => {
    return (businessCTypes || []).find((type) => type.id === id);
  }, [id, businessCTypes]);

  const handleRefresh = async () => {
    return queryClient.invalidateQueries(FETCH_COURSES);
  };

  return (
    <PageWrapper
      aboveFooter={<BookingFlowNavigation />}
      contentClasses="px-0"
      header={<PageHeader title={courseType?.name} leftAction={<BackToButton path="/" />} />}
      content={
        <PullToRefresh onRefresh={handleRefresh}>
          <FiltersPanel courseTypeId={id} />
          <BookingFlow courseTypeId={id} />
        </PullToRefresh>
      }
    />
  );
};
