import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { appState } from '@/common/atoms';
import { useRecoilState } from 'recoil';

export const useEmbed = () => {
  const location = useLocation();
  const [appStateValue, setAppState] = useRecoilState(appState);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    if (urlParams.has('embed')) {
      setAppState((prev) => ({ ...prev, embed: true }));
    }
  }, [location, setAppState]);

  return appStateValue.embed;
};
