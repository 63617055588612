import { useBusiness } from '@/web/hooks/use-business';
import { useRecoilState, useRecoilValue } from 'recoil';
import { bookingFlowLocation, bookingFlowRoom, bookingFlowSubType, bookingFlowType } from '../atoms';
import { LocationSelect } from '@/business/components/location-select';
import { businessLocationsState } from '@/business/atoms';
import clsx from 'clsx';
import { sendPirschEvent } from '@/analytics/utils';
import { useRooms } from '@/web/hooks/use-rooms';
import { GSelect } from '@/design-system/v3/g-select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';

type Props = {
  courseTypeId?: string | undefined;
};

export const FiltersPanel = ({ courseTypeId }: Props) => {
  const { t } = useTranslation();
  const { businessCTypes } = useBusiness();
  const locations = useRecoilValue(businessLocationsState);
  const rooms = useRooms();
  const [selectedType, setSelectedType] = useRecoilState(bookingFlowType);
  const [selectedSubType, setSelectedSubType] = useRecoilState(bookingFlowSubType);
  const [selectedLocation, setLocation] = useRecoilState(bookingFlowLocation);
  const [selectedRoom, setSelectedRoom] = useRecoilState(bookingFlowRoom);

  const primaryCourseTypeOptions = useMemo(() => {
    if (!businessCTypes) {
      return [];
    }

    return businessCTypes.filter((type) => type.parentCourseTypeId === null);
  }, [businessCTypes]);

  const subCourseTypeOptions = useMemo(() => {
    if (!businessCTypes || !(courseTypeId || selectedType)) {
      return [];
    }

    return businessCTypes.filter(
      (type) => type.parentCourseTypeId === courseTypeId || type.parentCourseTypeId === selectedType,
    );
  }, [businessCTypes, courseTypeId, selectedType]);

  const roomOptions = useMemo(
    () => [
      { value: '', label: t('all') },
      ...rooms
        .filter((room) => {
          if (!selectedLocation) {
            return true;
          }

          return !room.businessLocationId || room.businessLocationId === selectedLocation;
        })
        .map((room) => ({
          value: room.id,
          label: room.name,
        })),
    ],
    [rooms, selectedLocation],
  );

  if ((!locations || locations.length <= 1) && (!businessCTypes || businessCTypes.length <= 1)) {
    return <></>;
  }

  return (
    <div className="flex justify-between bg-white sticky top-0 z-50 h-[60px] border-y scroll-smooth border-y-borders-secondary">
      <div className="flex grow overflow-x-auto items-center px-4 space-x-2">
        {locations && locations.length > 1 && (
          <LocationSelect
            className="rounded-[100px] text-sm bg-surface-secondary border-borders-tertiary max-h-[36px]"
            value={selectedLocation}
            onChange={(ev) => {
              setLocation(ev.target.value);
            }}
          />
        )}
        {roomOptions.length > 1 && (
          <GSelect
            className="rounded-[100px] text-sm bg-surface-secondary border-borders-tertiary max-h-[36px]"
            options={roomOptions}
            value={selectedRoom}
            placeholder={t('room')}
            onChange={(e) => {
              setSelectedRoom(e.target.value);
            }}
          />
        )}
        {!courseTypeId && businessCTypes && primaryCourseTypeOptions.length > 1 && (
          <GSelect
            className="rounded-[100px] text-sm bg-surface-secondary border-borders-tertiary max-h-[36px]"
            options={primaryCourseTypeOptions}
            value={selectedType}
            placeholder={t('activity')}
            onChange={(e) => {
              setSelectedType(e.target.value);

              const type = businessCTypes.find((t) => t.id === e.target.value);

              if (type) {
                sendPirschEvent('Booking Flow Filter Item Click', {
                  page: 'booking-flow',
                  typeName: type.name,
                  typeId: type.id,
                });
              }
            }}
          />
        )}
        {subCourseTypeOptions.length > 0 && (
          <GSelect
            className="rounded-[100px] text-sm bg-surface-secondary border-borders-tertiary max-h-[36px]"
            options={subCourseTypeOptions}
            value={selectedSubType}
            placeholder={t('activity')}
            onChange={(e) => {
              setSelectedSubType(e.target.value);
            }}
          />
        )}
      </div>
    </div>
  );
};
