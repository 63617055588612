import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { changePassword } from '@/auth/endpoints';
import { getPasswordValidator } from '@/common/validators';
import { useSnack } from '@/common/hooks/use-snack';
import GymlySymbol from '@/gymly-symbol.svg?react';
import { Title } from '@/design-system/v3/title';
import Button from '@/design-system/v3/button';
import { GInput } from '@/design-system/v3/g-input';
import { PageWrapper } from '@/common/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { onLogout } from '@/auth/utils';
import { useAuth } from '@/auth/hooks/use-auth';

export const ResetPasswordView = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { successMessage, errorMessage } = useSnack();
  const [params] = useSearchParams();
  const token = params.get('token');
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });
  const passwordsValues = watch(['newPassword', 'confirmPassword']);
  const onSubmit = async (data: { newPassword: string; confirmPassword: string }) => {
    try {
      await changePassword(null, data.newPassword, token);
      successMessage('resetPassword.snack.title', {
        detailedMessage: 'resetPassword.snack.message',
      });
      navigate('/login');
    } catch (e: unknown) {
      errorMessage('resetPassword.snack.error.title', {
        detailedMessage: 'resetPassword.snack.error.message',
      });
    }
  };

  useEffect(() => {
    if (token && isLoggedIn) {
      onLogout();
    }
  }, [token, isLoggedIn]);

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <PageWrapper
      content={
        <div className="h-contentHeight w-full flex items-center justify-center">
          <div className="w-full">
            <div className="flex flex-col">
              <GymlySymbol className="h-10 w-10 text-black-100" />
              <Title className="mt-2">{t('authView.forgotPassword.title')}</Title>
              <form className="w-full mt-8">
                <div className="flex flex-col space-y-2">
                  <GInput
                    {...control.register('newPassword', {
                      ...getPasswordValidator(),
                    })}
                    error={errors.newPassword?.message?.toString()}
                    type="password"
                    autoComplete="new-password"
                    placeholder={t('authView.form.newPassword.title')}
                    title={t('authView.form.newPassword.title')}
                  />

                  <GInput
                    {...control.register('confirmPassword', {
                      validate: (value) => {
                        return passwordsValues[0] !== value ? t('authView.form.password.notMatch') : undefined;
                      },
                    })}
                    error={errors.confirmPassword?.message?.toString()}
                    type="password"
                    autoComplete="new-password"
                    placeholder={t('authView.form.password.confirm.title')}
                    title={t('authView.form.password.confirm.title')}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      footerClasses="px-4"
      footer={
        <Button
          type="submit"
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || passwordsValues[0] !== passwordsValues[1]}
          loading={isSubmitting}
        >
          {t('authView.form.resetPassword.btn')}
        </Button>
      }
    />
  );
};
