import { gaxios } from '@/api';
import { BASE } from '../common/constants';
import { LoginResponse, ProrateResult } from './types';
import { PaymentSchedule } from '@/web/types';
import { format } from 'date-fns';

export const signup = async (email: string, password: string): Promise<LoginResponse> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/signup`,
    data: {
      email,
      password,
    },
  });

  return resp.data;
};

export const changePassword = async (
  currentPassword: string | null,
  newPassword: string,
  token?: string | null,
): Promise<LoginResponse> => {
  const data = {
    newPassword,
  } as any;

  if (currentPassword) {
    data.currentPassword = currentPassword;
  }

  if (token) {
    data.token = token;
  }
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/password/change`,
    data,
  });

  return resp.data;
};

export const resetPassword = async (email: string): Promise<LoginResponse> => {
  const data = {
    email,
  } as any;
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/password/reset`,
    data,
  });

  return resp.data;
};

export const login = async (email: string, password: string): Promise<LoginResponse> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/auth/login`,
    data: {
      email,
      password,
    },
  });

  return resp.data;
};

export const refreshToken = async (): Promise<LoginResponse> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/auth/refreshToken`,
  });

  return resp.data;
};

export const activateUser = async (data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/activate`,
    data,
  });

  return resp.data;
};

export const activateGuest = async (businessId: string, data: any): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/businesses/${businessId}/guest/activation`,
    data,
  });

  return resp.data;
};

export const downloadPicture = async (path: string): Promise<Blob> => {
  const resp = await gaxios({
    method: 'GET',
    url: `${BASE}${path}`,

    responseType: 'blob',
  });

  return resp.data;
};

/**
 * Get the prorated amount for a membership type at a location.
 *
 * @param businessId The business id to get the prorated amount for.
 * @param locationId The location id to get the prorated amount for.
 * @param membershipId The UUID of the membership type to get the prorated amount for.
 * @param activeAt The date to get the prorated amount for.
 * @param discountCode The discount code to apply to the prorated amount.
 *
 * @return {Promise<ProrateResult>} The prorated amount.
 */
export const getProrateAmount = async (
  businessId: string,
  locationId: string,
  membershipId: string,
  activeAt: Date | null = null,
  discountCode: string | null = null,
): Promise<ProrateResult> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/businesses/${businessId}/locations/${locationId}/prorate`,
    data: {
      membershipId: membershipId,
      activeAt: activeAt ? format(activeAt, 'yyyy-MM-dd') : null,
      discountCode,
    },
  });

  return resp.data;
};

/**
 * Get the payment schedule of the given location.
 *
 * @param businessId The business id to get the payment schedule for.
 * @param locationId The location id to get the payment schedule for.
 *
 * @return {Promise<{ schedule: PaymentSchedule }>} The prorated amount.
 */
export const getLocationPaymentSchedule = async (
  businessId: string,
  locationId: string,
): Promise<{ schedule: PaymentSchedule | null }> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/businesses/${businessId}/locations/${locationId}/payment-schedule`,
  });

  return resp.data;
};

export const exchangeSsoToken = async (token: string): Promise<string> => {
  const resp = await gaxios<{ token: string }>({
    method: 'POST',
    url: `/v1/user/sso/exchange`,
    data: { token },
  });

  return resp.data.token;
};
