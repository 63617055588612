import { rgbToHex } from '../common/color-utils';

export const Purple100 = '#6F04F4';
export const Purple80 = '#822AF6';
export const Purple70 = '#9B4DFF';
export const Purple60 = '#964FF8';
export const Purple20 = '#BC9AFB';
export const Purple05 = '#E3E5FF';

export const Dark100 = '#110026';
export const Dark70 = '#584D67';
export const Dark50 = '#8E809F';

export const Gray900 = '#101828';
export const Gray800 = '#1D2939';
export const Gray700 = '#344054';
export const Gray600 = '#475467';
export const Gray500 = '#667085';
export const Gray400 = '#98A2B3';
export const Gray300 = '#D0D5DD';
export const Gray200 = '#EAECF0';
export const Gray100 = '#F4F2F5';
export const Gray70 = '#F7F6F8';
export const Gray50 = '#F9FAFB';
export const Gray30 = '#fcfbfc';

export const Steel100 = '#282B40';

export const BlueGrey05 = '#F3F6F9';

export const Blue100 = '#2176FF';

export const DarkBlue100 = '#1D1B52';
export const DarkBlue60 = '#5C5A85';
export const DarkBlue40 = '#7b799f';
export const DarkBlue20 = '#9A98B8';
export const DarkBlue10 = '#B9B7D1';

export const Black100 = '#0F192B';
export const Black80 = '#353D4C';
export const Black60 = '#5B616D';
export const Black40 = '#81868F';
export const Black20 = '#CCD1D9';
export const Black10 = '#F1F1F1';
export const Black05 = '#F7F7F7';

export const Red600 = '#D92D20';
export const Red500 = '#F04438';
export const Red100 = '#FEE4E2';
export const Red60 = '#FF758F';

export const Orange600 = '#DC6803';
export const Orange500 = '#F79009';
export const Orange100 = '#FEF0C7';

export const Green600 = '#039855';
export const Green100 = '#47C579';
export const Green50 = '#ECFDF3';

export const White100 = '#ffffff';

export const GraphColorWheel = ['#6F04F4', '#0F192B', '#10A9E8', '#f4c004', '#47C579', '#FF758F', '#1a67db'];

const generateRandomColor = () => {
  const minRange = 52;
  const maxRange = 235;

  return rgbToHex({
    r: Math.floor(Math.random() * (maxRange - minRange + 1) + 52),
    g: Math.floor(Math.random() * (maxRange - minRange + 1) + 52),
    b: Math.floor(Math.random() * (maxRange - minRange + 1) + 52),
    a: 1,
  });
};

export const getColorFromWheel = (index: number) => {
  if (index > GraphColorWheel.length - 1) {
    return generateRandomColor();
  }

  return GraphColorWheel[index];
};
