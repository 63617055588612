import clsx from 'clsx';
import { CircleNotch } from 'phosphor-react';
import { twMerge } from 'tailwind-merge';

type Variant = 'primary' | 'secondary' | 'tertiary';

export const GLoader = ({ variant = 'primary', className }: { variant?: Variant; className?: string }) => {
  const classes = clsx('w-6 h-6', {
    'text-white': variant === 'primary',
    'text-primary': variant === 'secondary',
  });
  return (
    <div className="animate-spin duration-300">
      <CircleNotch className={twMerge(classes, className)} />
    </div>
  );
};
