import { getLocationPaymentSchedule } from '@/auth/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { useQuery } from 'react-query';

export const FETCH_BUSINESS_PAYMENT_SCHEDULE = 'FETCH_BUSINESS_PAYMENT_SCHEDULE';

export const useBusinessPaymentSchedule = () => {
  const { businessUuid, primaryLocationId } = useBusiness();
  const { data: paymentSchedule } = useQuery(
    [FETCH_BUSINESS_PAYMENT_SCHEDULE, businessUuid, primaryLocationId],
    async () => {
      if (businessUuid && primaryLocationId) {
        return (await getLocationPaymentSchedule(businessUuid, primaryLocationId)).schedule;
      }
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!businessUuid && !!primaryLocationId,
    },
  );

  return paymentSchedule;
};
