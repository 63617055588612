import { modalState } from '@/common/atoms';
import { useRecoilState } from 'recoil';
import { GPanel } from '@/design-system/v3/g-panel';

const emptyObject = {};

export const Modals = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const Content = modal?.component;
  const Title = modal?.title;
  const Actions = modal?.actions;
  const isModalOpen = !!modal?.isOpen;

  const handleClose = () => {
    setModal(null);
  };

  const modalProps = {
    handleClose,
    ...(modal?.props ? modal.props : emptyObject),
  };

  return (
    <GPanel title={Title} open={isModalOpen} onClose={handleClose} actions={Actions}>
      <div className="flex flex-col p-5 overflow-auto">{Content && <Content {...modalProps} />}</div>
    </GPanel>
  );
};
