import { Spinner } from 'phosphor-react';
import { ReactNode, TouchEvent, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';

const THRESHOLD = 60;
const RESISTANCE = 3;

const checkAllScrollTopZero = (element: HTMLElement | Element) => {
  if (element.scrollHeight > element.clientHeight) {
    if (element.scrollTop !== 0) {
      return false;
    }
  }

  for (const child of Array.from(element.children)) {
    if (!checkAllScrollTopZero(child)) {
      return false;
    }
  }

  return true;
};

export const PullToRefresh = ({
  onRefresh,
  children,
  className,
}: {
  children: ReactNode;
  onRefresh: () => Promise<void>;
  className?: string;
}) => {
  const [pullDistance, setPullDistance] = useState(0);
  const touchStartY = useRef(0);
  const touchCurrentY = useRef(0);
  const canScroll = useRef(false);
  const hasReachedThreshold = pullDistance >= THRESHOLD;

  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    const html = document.querySelector('html');
    canScroll.current = html ? checkAllScrollTopZero(html) : false;
    if (canScroll.current) {
      touchStartY.current = e.touches[0].clientY;
    }
  };

  const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (canScroll.current) {
      touchCurrentY.current = e.touches[0].clientY;
      if (touchCurrentY.current > touchStartY.current) {
        const newPullDistance = (touchCurrentY.current - touchStartY.current) / RESISTANCE;

        if (newPullDistance > THRESHOLD + 10) {
          return;
        }

        if (hasReachedThreshold && newPullDistance < THRESHOLD) {
          triggerHapticFeedback(HapticFeedbackType.IMPACT_LIGHT);
        }

        if (!hasReachedThreshold && newPullDistance >= THRESHOLD) {
          triggerHapticFeedback(HapticFeedbackType.IMPACT_MEDIUM);
        }

        setPullDistance(newPullDistance);
      }
    }
  };

  const onTouchEnd = async () => {
    if (hasReachedThreshold) {
      setPullDistance(THRESHOLD);
      await onRefresh();
    }
    setPullDistance(0);
    canScroll.current = false;
  };

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      className={twMerge('relative', className)}
    >
      <div
        className="absolute top-0 flex justify-center items-center w-full"
        style={{ height: THRESHOLD, opacity: pullDistance / THRESHOLD }}
      >
        <div
          className={hasReachedThreshold ? 'animate-spin' : undefined}
          style={{ transform: hasReachedThreshold ? '' : `rotate(${pullDistance * 2}deg)` }}
        >
          <Spinner className={hasReachedThreshold ? 'animate-pulse' : ''} size={hasReachedThreshold ? 26 : 24} />
        </div>
      </div>
      <div style={{ transform: `translateY(${pullDistance}px)` }}>{children}</div>
    </div>
  );
};
