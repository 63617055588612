import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import InformationIcon from '@/common/components/icon/icons/v2/information.svg?react';

export enum AlertVariant {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
}

export enum AlertDesign {
  DEFAULT = 'default',
  BORDERED = 'bordered',
}

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title' | 'content'> {
  variant?: AlertVariant;
  design?: AlertDesign;
  title?: ReactNode;
  content: ReactNode;
  embedded?: boolean;
}

export const Alert = ({
  design = AlertDesign.DEFAULT,
  variant = AlertVariant.DEFAULT,
  title,
  content,
  className,
  embedded = false,
}: Props) => {
  return design === AlertDesign.BORDERED ? (
    <BorderedAlert variant={variant} title={title} content={content} embedded={embedded} className={className} />
  ) : (
    <DefaultAlert variant={variant} title={title} content={content} embedded={embedded} className={className} />
  );
};

const DefaultAlert = ({ variant, embedded, title, content, className }: Props) => (
  <div
    className={twMerge(
      clsx('flex flex-row p-4 border border-gray-200', {
        'bg-gray-25': variant === AlertVariant.DEFAULT,
        'bg-success-25': variant === AlertVariant.SUCCESS,
        'bg-warning-25': variant === AlertVariant.WARNING,
        'bg-error-25': variant === AlertVariant.ERROR,
        'rounded-lg': !embedded,
        'border-l-none border-r-none': embedded,
      }),
      className,
    )}
  >
    <div className="flex flex-col">
      {title && <span className="text-lg mb-1 font-neutral text-typo-primary">{title}</span>}
      <span className="text-typo-primary text-sm">{content}</span>
    </div>
  </div>
);

const BorderedAlert = ({ variant, embedded, title, content, className }: Props) => (
  <div
    className={twMerge(
      clsx('relative flex flex-row p-4 border border-gray-200 overflow-hidden', {
        'rounded-lg': !embedded,
        'border-l-none border-r-none': embedded,
      }),
      className,
    )}
  >
    <div
      className={clsx('absolute h-full w-1 left-0 top-0', {
        'bg-gray-500': variant === AlertVariant.DEFAULT,
        'bg-success-500': variant === AlertVariant.SUCCESS,
        'bg-warning-500': variant === AlertVariant.WARNING,
        'bg-error-500': variant === AlertVariant.ERROR,
        'bg-typo-info': variant === AlertVariant.INFO,
      })}
    />
    <div
      className={clsx('me-2', {
        'text-gray-500': variant === AlertVariant.DEFAULT,
        'text-success-500': variant === AlertVariant.SUCCESS,
        'text-warning-500': variant === AlertVariant.WARNING,
        'text-error-500': variant === AlertVariant.ERROR,
        'text-typo-info': variant === AlertVariant.INFO,
      })}
    >
      {AlertVariant.INFO === variant && <InformationIcon height={24} />}
    </div>
    <div className="flex flex-col">
      {title && <span className="text-sm mb-0.5 font-semibold text-typo-primary">{title}</span>}
      <span className="text-typo-tertiary text-sm font-medium">{content}</span>
    </div>
  </div>
);
