import { useAuth } from '@/auth/hooks/use-auth';
import { PageWrapper } from '@/common/components/page-wrapper';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { HomeUser } from '../home-user';
import { Tabs } from '@/design-system/v3/g-tabs';
import { BookingFlowNavigation } from '@/common/components/booking-flow';
import { DraggableArea } from '@/common/components/draggable-area';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { PullToRefresh } from '@/common/components/pull-to-refresh';
import { FETCH_COURSES, FETCH_UPCOMING_COURSES } from '@/web/endpoints';
import { FiltersPanel } from '@/common/components/booking-flow/filters-panel';
import { RESERVATIONS_PATH, WAITLIST_PATH } from '@/web/routes';
import { queryInvalidator } from '@/query-client';
import { ReservationView } from '@/web/views/reservations';
import { WaitlistView } from '@/web/views/waitlist';
import { useCallback } from 'react';
import { FETCH_WAITLIST } from '@/business/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { HomeScreenMode } from '@/web/types';
import { HomeDiscovery } from '@/common/views/home-discovery';

const HEADER_AND_TABS_HEIGHT = 108;

type Tab = {
  content: string;
  isSelected: boolean;
  onClick: () => void;
  view: React.ReactNode;
  filters?: React.ReactNode;
};

export const Home = () => {
  const { isUserOnboarded } = useAuth();
  const { businessSettings } = useBusiness();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isHome = location.pathname === '/';

  const handleRefresh = useCallback(() => {
    const queries = [];

    if (location.pathname === '/') {
      queries.push(FETCH_COURSES);
    }

    if (location.pathname === RESERVATIONS_PATH) {
      queries.push(FETCH_UPCOMING_COURSES);
    }

    if (location.pathname === WAITLIST_PATH) {
      queries.push(FETCH_WAITLIST);
    }

    return queryInvalidator(queries);
  }, [location]);

  if (!isUserOnboarded) {
    return <Navigate to="/onboarding" />;
  }

  if (businessSettings?.homeScreenMode == HomeScreenMode.DISCOVERY) {
    return <HomeDiscovery />;
  }

  const tabs: Tab[] = [
    {
      content: t('homeView.classes.title'),
      isSelected: isHome,
      onClick: () => navigate('/'),
      view: <HomeUser />,
      filters: <FiltersPanel />,
    },
    {
      content: t('homeView.reservations.title'),
      isSelected: location.pathname === RESERVATIONS_PATH,
      onClick: () => navigate(RESERVATIONS_PATH),
      view: <ReservationView />,
    },
    {
      content: t('homeView.waitlist.title'),
      isSelected: location.pathname === WAITLIST_PATH,
      onClick: () => navigate(WAITLIST_PATH),
      view: <WaitlistView />,
    },
  ];

  const selectedTabIndex = tabs.findIndex((tab) => tab.isSelected);

  return (
    <PageWrapper
      aboveFooter={isHome ? <BookingFlowNavigation /> : null}
      contentClasses="px-0"
      contentHeightOffset={HEADER_AND_TABS_HEIGHT}
      content={
        <>
          <PullToRefresh onRefresh={handleRefresh}>
            <Tabs layoutId="staff-teacher-menu" className="px-4 z-50 overflow-hidden" tabs={tabs} />
            {tabs[selectedTabIndex].filters ?? null}
            <DraggableArea
              onDragRight={() => {
                if (tabs[selectedTabIndex + 1]) {
                  tabs[selectedTabIndex + 1].onClick();
                }
              }}
              onDragLeft={() => {
                if (tabs[selectedTabIndex - 1]) {
                  tabs[selectedTabIndex - 1].onClick();
                }
              }}
            >
              <AnimatePresence>{tabs[selectedTabIndex].view}</AnimatePresence>
            </DraggableArea>
          </PullToRefresh>
        </>
      }
    />
  );
};
