import { BRING_A_FRIEND_INVITATIONS } from '@/features/bring-a-friend/api/BringAFriendApi';
import { BringAFriendClassInformation } from '@/features/bring-a-friend/components/BringAFriendClassInformation/BringAFriendClassInformation';
import useBringAFriend from '@/features/bring-a-friend/hooks/useBringAFriend';
import { BringAFriendInviteDetailsResponse } from '@/features/bring-a-friend/types/BringAFriendInviteDetailsResponse';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import CheckCircleIcon from '@/icons/check-circle.svg?react';
import { useEffect } from 'react';
import { InvitationStatus } from '@/features/bring-a-friend/enums/InvitationStatus.enum';

type Props = {
  invitation: BringAFriendInviteDetailsResponse;
  token: string;
};

const BringAFriendClassInvitation = ({ invitation, token }: Props) => {
  const { acceptFriendRequestMutation } = useBringAFriend();
  const { mutate } = acceptFriendRequestMutation;
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    if (invitation.status === InvitationStatus.ACCEPTED) {
      return;
    }

    mutate(
      { invitationId: invitation.id, token },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([BRING_A_FRIEND_INVITATIONS, invitation.id]);
        },
      },
    );
  }, [queryClient, mutate, invitation, token]);

  return (
    <div>
      <div className="flex flex-col items-center justify-center text-center ">
        <div className="mb-6">
          <CheckCircleIcon className="w-16 h-16 text-typo-positive" />
        </div>
        <h2 className="font-median text-2xl font-semibold">{t('bringAFriend.youreInvited')}</h2>
        <div className="flex flex-col text-base">
          <div>
            {t('bringAFriend.invitedBy', {
              name: invitation.account.firstName,
            })}
          </div>
          <div>{t('bringAFriend.inviteConfirmation.description')}</div>
        </div>
      </div>

      <div className="mt-10">
        <BringAFriendClassInformation course={invitation.course} />
      </div>
    </div>
  );
};

export default BringAFriendClassInvitation;
