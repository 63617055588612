import { useRecoilState } from 'recoil';
import clsx from 'clsx';
import { useWebshopCategories } from '../hooks/use-webshop-categories';
import { categoriesFilterState } from '../atoms';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';

export const WebshopCategoriesFilter = () => {
  const { categories } = useWebshopCategories({ page: 0, size: 50 });
  const [selectedCategories, setSelectedCategories] = useRecoilState(categoriesFilterState);

  return (
    <div className="flex justify-between bg-white sticky scroll-smooth">
      <div className="flex grow overflow-x-auto items-center">
        {categories?.map((cat) => (
          <button
            key={cat.id}
            className={clsx(
              'mr-2 rounded-[100px] flex text-sm items-center justify-center whitespace-nowrap py-2 px-3',
              selectedCategories[cat.id] ? 'bg-primary text-primary-contrast-text font-medium' : 'bg-surface-secondary',
            )}
            onClick={() => {
              setSelectedCategories((prev) => {
                const newCategories = { ...prev };
                if (newCategories[cat.id]) {
                  delete newCategories[cat.id];
                } else {
                  newCategories[cat.id] = true;
                }
                return newCategories;
              });
              triggerHapticFeedback(HapticFeedbackType.IMPACT_MEDIUM);
            }}
          >
            {cat.name}
          </button>
        ))}
      </div>
    </div>
  );
};
