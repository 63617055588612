export const userToForm = (user: any) => {
  if (!user) {
    return null;
  }

  return {
    ...user,
    dateOfBirth: user.dateOfBirth || '',
  };
};
