import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type AvatarSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
interface SkeletonProps {
  size?: AvatarSize;
  className?: string;
}

const GAvatarSkeleton = ({ size = 'md', className }: SkeletonProps) => {
  const sizes = clsx({
    'w-[92px] h-[92px] min-w-[92px]': size === '3xl',
    'w-20 h-20 min-w-[80px]': size === '2xl',
    'w-16 h-16 min-w-[60px]': size === 'xl',
    'w-12 h-12 min-w-[48px]': size === 'lg',
    'w-10 h-10 min-w-[40px]': size === 'md',
    'w-8 h-8 min-w-[32px]': size === 'sm',
    'w-7 h-7 min-w-[28px]': size === 'xs',
    'w-6 h-6 min-w-[24px]': size === '2xs',
  });

  const classes = twMerge(
    clsx('relative rounded-full max-w-none overflow-hidden bg-gray-200 animate-pulse', sizes),
    className,
  );

  return <div className={classes} />;
};

export default GAvatarSkeleton;
