import { useCallback, useState } from 'react';
import { useBusiness } from '../../../hooks/use-business';
import { useQuery, useQueryClient } from 'react-query';
import { getPaginatedUserInvoices } from '../../../endpoints';
import { InvoiceStatus } from '../../../types';
import { PaginationState } from '@tanstack/react-table';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import Button from '@/design-system/v3/button';
import Text from '@/design-system/v3/text';
import { GLoader } from '@/design-system/g-loader';
import { NoResultsFound } from '@/common/components/no-results-found';
import { useTranslation } from 'react-i18next';
import { getInvoicePdfLink } from '@/business/endpoints';
import { PROFILE_PATH } from '@/web/routes';
import { BackToButton } from '../../extra-menu';
import { useSetRecoilState } from 'recoil';
import { panelState } from '@/common/atoms';
import { InvoicePayment } from '@/common/components/invoice-payment';
import DownloadCloudIcon from '@/common/components/icon/icons/v2/download-cloud.svg?react';
import { Paginator } from '@/common/components/paginator';
import { PullToRefresh } from '@/common/components/pull-to-refresh';

const invoiceAttrsFromStatus = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.PAID:
      return {
        className: 'text-success',
        label: 'paid',
      };
    case InvoiceStatus.FAILED:
      return {
        className: 'text-error',
        label: 'failed',
      };

    case InvoiceStatus.RETRY:
      return {
        className: 'text-warning',
        label: 'retrying',
      };

    case InvoiceStatus.CANCELLED:
      return {
        className: 'text-gray',
        label: 'cancelled',
      };

    case InvoiceStatus.CHARGEBACK:
      return {
        className: 'text-error',
        label: 'chargeback',
      };

    case InvoiceStatus.PENDING:
    default:
      return {
        className: 'text-warning',
        label: 'pending',
      };
  }
};

export const FETCH_USER_INVOICES = 'FETCH_USER_INVOICES';

const PAYABLE_INVOICE_STATUSES = [InvoiceStatus.CHARGEBACK, InvoiceStatus.FAILED, InvoiceStatus.PENDING];

export const InvoicesView = () => {
  const { businessUuid } = useBusiness();
  const { t } = useTranslation();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const queryClient = useQueryClient();
  const [loadingInvoices, setLoadingInvoices] = useState<{ [key: string]: boolean }>({});
  const setPanel = useSetRecoilState(panelState);

  const { data, isFetching } = useQuery([FETCH_USER_INVOICES, businessUuid, pageIndex, pageSize], async function () {
    if (businessUuid) {
      return getPaginatedUserInvoices({
        businessUuid,
        page: pageIndex,
        size: pageSize,
      });
    }

    return null;
  });

  const handleNextPage = () =>
    setPagination((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex + 1,
    }));
  const handlePrevPage = () =>
    setPagination((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex - 1,
    }));

  const canNextPage = Boolean(data && !data.last);
  const canPrevPage = Boolean(data && !data.first);

  const handleRefresh = () => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    queryClient.invalidateQueries([FETCH_USER_INVOICES, businessUuid]);
  };

  const createPayClickHandler = useCallback(
    (invoiceId: string) => () => {
      setPanel({
        component: InvoicePayment,
        // title: 'Pay invoice',
        isOpen: true,
        autoHeight: true,
        props: { invoiceId },
      });
    },
    [setPanel],
  );

  const content = isFetching ? (
    <div className="flex items-center justify-center h-contentHeight">
      <GLoader variant="secondary" />
    </div>
  ) : !data?.content?.length ? (
    <div className="flex items-center justify-center h-contentHeight">
      <NoResultsFound label={t('noResults.invoices')} />
    </div>
  ) : (
    <PullToRefresh onRefresh={handleRefresh}>
      <Text className="p-4 text-typo-secondary" variant="small">
        {t('invoicesView.list.title')}
      </Text>

      {data.content.map((invoice) => {
        const attrs = invoiceAttrsFromStatus(invoice.status);
        return (
          <div
            key={invoice.id}
            className="border-t only:border-b last:border-b dark:last:dark:border-b-gray-700 dark:only:dark:border-b-gray-700 dark:border-t-gray-700 w-full flex items-center justify-between p-4"
          >
            <div className="space-y-1 text-sm">
              <div>
                {t('invoicesView.invoice.title', {
                  invoiceNumber: invoice.numberFormatted,
                })}
              </div>
              <div className={attrs.className}>{t(attrs.label)}</div>
            </div>
            <div className="flex flex-row items-center space-x-1">
              <Button
                variant="secondary"
                className="w-auto"
                loading={loadingInvoices[invoice.id]}
                onClick={async () => {
                  if (businessUuid) {
                    setLoadingInvoices((prev) => ({ ...prev, [invoice.id]: true }));

                    const { url } = await getInvoicePdfLink(businessUuid, invoice.id);

                    const link = document.createElement('a');
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();

                    setLoadingInvoices((prev) => ({ ...prev, [invoice.id]: false }));
                  }
                }}
              >
                <DownloadCloudIcon />
              </Button>
              {PAYABLE_INVOICE_STATUSES.includes(invoice.status) && (
                <Button variant="primary" className="w-auto" onClick={createPayClickHandler(invoice.id)}>
                  {t('pay')}
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </PullToRefresh>
  );

  return (
    <PageWrapper
      header={
        <PageHeader title={t('profileMenuView.yourInvoices.title')} leftAction={<BackToButton path={PROFILE_PATH} />} />
      }
      contentClasses="pb-4 px-0"
      content={content}
      footer={
        data && (
          <div className="px-4">
            <Paginator
              onPreviousPage={handlePrevPage}
              onNextPage={handleNextPage}
              page={pageIndex + 1}
              totalPages={data.totalPages}
              canPreviousPage={canPrevPage}
              canNextPage={canNextPage}
              isFetching={isFetching}
            />
          </div>
        )
      }
    />
  );
};
