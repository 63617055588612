import { Placement } from '@floating-ui/react';
import React, { ReactNode } from 'react';
import { atom } from 'recoil';

export type ModalKeys = string;

export interface ModalProps {
  handleClose: () => void;
}

export interface ModalState {
  title?: string;
  component: React.FC<any>;
  actions?: ReactNode;
  props?: Record<string, unknown>;
  isOpen?: boolean;
}

export const modalState = atom<ModalState | null>({
  key: 'modalState',
  default: null,
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newVal, prevVal) => {
        if (!newVal && prevVal) {
          setSelf({
            ...(prevVal as ModalState),
            isOpen: false,
          });
        }

        if (newVal) {
          setSelf({
            ...newVal,
            isOpen: true,
          });
        }
      });
    },
  ],
});

export interface PanelState {
  title?: string;
  component: React.FC<any>;
  actions?: ReactNode;
  props?: Record<string, unknown>;
  isOpen: boolean;
  closeButtonVariant?: 'icon' | 'text';
  autoHeight?: boolean;
  childrenClassName?: string | undefined;
  draggable?: boolean;
}

export const panelState = atom<PanelState | null>({
  key: 'panelState',
  default: null,
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newVal, prevVal) => {
        if (!newVal && prevVal) {
          setSelf({
            ...(prevVal as PanelState),
            isOpen: false,
          });
        }

        if (newVal) {
          setSelf({
            ...newVal,
            isOpen: true,
          });
        }
      });
    },
  ],
});

export type PopoverItems = {
  onClick: () => void;
  render: JSX.Element;
}[];

interface PopoverMenuState {
  key?: string;
  placement?: Placement;
  anchor: HTMLElement | null;
  component?: JSX.Element | null;
  items?: PopoverItems;
  handleClose?: () => void;
}

export const popoverMenuState = atom<PopoverMenuState>({
  key: 'popoverMenuState',
  default: {
    anchor: null,
    items: [],
    component: null,
  },
});

export const uiPropsState = atom<{ noSidenav: boolean; noHeader: boolean }>({
  key: 'uiPropsState',
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newOptions, prevOptions) => {
        setSelf({
          ...prevOptions,
          ...newOptions,
        });
      });
    },
  ],
  default: {
    noHeader: false,
    noSidenav: false,
  },
});

export const appState = atom<{
  foreground: boolean | null;
  extraMenuOpen: boolean;
  embed: boolean;
  maintenance: string | null;
}>({
  key: 'appState',
  default: {
    foreground: null,
    extraMenuOpen: false,
    embed: false,
    maintenance: null,
  },
});
