import { GCard } from '@/design-system/v3/g-card';
import React from 'react';

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
}

export const CardWithTitle = ({ title, children }: Props) => {
  return (
    <div>
      <span className="font-medium text-typo-secondary">{title}</span>
      <div className="mt-1">
        <GCard variant="secondary">{children}</GCard>
      </div>
    </div>
  );
};
