import { format } from 'date-fns';
import { enUS, nl, de } from 'date-fns/locale';
import callingCodes from './calling-codes.json';
import { Language } from '@/auth/types';

interface LocaleMap {
  [key: string]: Locale;
}

interface FlagMap {
  [key: string]: string;
}

export const LOCALE_STORAGE = 'i18nextLng';

const localeMap: LocaleMap = {
  en: enUS,
  nl: nl,
  de: de,
};

const countryMap: { [key: string]: string } = {
  en: 'US',
  nl: 'NL',
  de: 'DE',
};

export const flagsMap: FlagMap = {
  en: 'us',
  nl: 'nl',
  de: 'de',
};

export const supportedLocales = [
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'nl',
    name: 'Nederlands',
  },
  {
    locale: 'de',
    name: 'Deutsch',
  },
];

export const getDefaultLocale = () => {
  const detectedLocale = localStorage.getItem(LOCALE_STORAGE) || navigator.language;

  if (detectedLocale) {
    const supportedLocale = supportedLocales.find((locale) => locale.locale.startsWith(detectedLocale.substring(0, 2)));
    return supportedLocale?.locale || 'en';
  }

  return 'en';
};

export const getLocaleObject = () => {
  const locale = localStorage.getItem(LOCALE_STORAGE) || 'en';

  return localeMap[locale as keyof LocaleMap] || enUS;
}

export const formatWithT = (date: string | Date, formatStr: string) => {
  const localeObject = getLocaleObject();
  const dateObject = typeof date === 'string' ? new Date(date) : date;

  return format(dateObject, formatStr, { locale: localeObject });
};

export const getDefaultCallingCode = (): string | undefined => {
  const locale = getDefaultLocale();
  const country = countryMap[locale] ?? 'US';
  const code = callingCodes.find((countryCode) => countryCode.iso === country)?.code;

  return code ? `+${code}` : undefined;
};

export const languageToLocale = (language: Language) => {
  switch (language) {
    case Language.NL:
      return 'nl';
    case Language.DE:
      return 'de';
    case Language.EN:
    default:
      return 'en';
  }
};

export const localeToLanguage = (locale: string) => {
  switch (locale) {
    case 'nl':
      return Language.NL;
    case 'de':
      return Language.DE;
    case 'en':
    default:
      return Language.EN;
  }
};