import { useTranslation } from 'react-i18next';
import { useBusiness } from '@/web/hooks/use-business';
import { getTermsAndConditionsLegacyPdf, getTermsAndConditionsPdf } from '@/business/endpoints';
import { RichTextContent } from '@/common/components/rich-text-content';
import React, { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { panelState } from '@/common/atoms';
import Button from '@/design-system/v3/button';
import { usePrivacyPolicy } from '@/web/hooks/use-privacy-policy';
import { GLink } from '@/design-system/v3/g-link';
import { useTermsAndConditions } from '@/web/hooks/use-terms-and-conditions';

type Props = {
  acknowledge: boolean;
};

export const BusinessTerms = ({ acknowledge }: Props) => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const setPanel = useSetRecoilState(panelState);
  const privacyPolicy = usePrivacyPolicy();
  const { termsAndConditions } = useTermsAndConditions();

  const handleTermsAndConditionsClick = async () => {
    if (!termsAndConditions) {
      return getTermsAndConditionsLegacyPdf(businessUuid as string);
    }

    setPanel({
      component: ContentModal,
      title: t('termsAndConditions'),
      isOpen: true,
      props: {
        content: termsAndConditions.content,
        showCloseButton: true,
        handleDownload: () => {
          getTermsAndConditionsPdf(businessUuid as string);
        },
        onClose: () => setPanel(null),
      },
    });
  };

  const handlePrivacyPolicyClick = useCallback(async () => {
    setPanel({
      component: ContentModal,
      title: t('privacyPolicy'),
      isOpen: true,
      props: {
        content: privacyPolicy.privacyPolicy?.content,
        showCloseButton: true,
        onClose: () => setPanel(null),
      },
    });
  }, [privacyPolicy, t, setPanel]);

  const termsAndConditionsButton = (
    <button className="inline-flex text-secondary underline outline-none" onClick={handleTermsAndConditionsClick}>
      {t('termsAndConditions')}
    </button>
  );

  const privacyPolicyButton = useMemo(() => {
    if (privacyPolicy.privacyPolicy) {
      return (
        <button className="inline-flex text-secondary underline outline-none" onClick={handlePrivacyPolicyClick}>
          {t('privacyPolicy')}
        </button>
      );
    }

    return (
      <GLink to={privacyPolicy.url as string} rel="noopener noreferrer" target="_blank">
        {t('privacyPolicy')}
      </GLink>
    );
  }, [t, privacyPolicy, handlePrivacyPolicyClick]);

  return (
    <>
      {acknowledge && t('acknowledgeTermsConditionsPrivacy')} {termsAndConditionsButton}
      {` ${t('and')} `}
      {privacyPolicyButton}
      {acknowledge && '.'}
    </>
  );
};

const ContentModal = ({
  content,
  onClose,
  handleDownload,
}: {
  content: string;
  onClose: () => void;
  handleDownload?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-wrap justify-center w-full">
      <div className="w-full max-w-[600px]">
        <RichTextContent html={content} />
        <div className="relative flex flex-wrap space-y-2 w-full px-4 mt-4 md:flex-nowrap md:space-y-0 md:space-x-2 md:px-0">
          <Button variant="primary" className="w-full md:w-auto" onClick={onClose}>
            {t('done')}
          </Button>
          {handleDownload && (
            <Button variant="secondary" className="w-full md:w-auto" onClick={handleDownload}>
              {t('download')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
