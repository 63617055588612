import React from 'react';
import { twMerge } from 'tailwind-merge';
import { GCard } from '@/design-system/v3/g-card';
import { skeletonAnimationClasses } from '@/web/utils';

export const BringAFriendOverviewCardSkeleton = () => {
  return (
    <GCard variant="secondary" className="bg-white">
      <div className="divide-y">
        <div className="px-4 pt-5 pb-2">
          <div className={twMerge('h-4 w-full', skeletonAnimationClasses)}></div>
          <div className="flex justify-between mt-1 font-medium text-xs">
            <span className={twMerge('h-3 w-1/5', skeletonAnimationClasses)}></span>
            <span className={twMerge('h-3 w-1/5', skeletonAnimationClasses)}></span>
          </div>
        </div>
        <div className="p-4">
          <div className={twMerge('h-10 w-full', skeletonAnimationClasses)}></div>
        </div>
      </div>
    </GCard>
  );
};
