import { PropsWithChildren, useEffect } from 'react';
import { darken, desaturate, getScale, lighten, readableColorIsBlack } from 'color2k';
import { useBusiness } from '@/web/hooks/use-business';

const GYMLY_SHADES = {
  25: '#FAFAFF',
  50: '#F4F3FF',
  100: '#EBE9FE',
  200: '#D9D6FE',
  300: '#BDB4FE',
  400: '#9B8AFB',
  500: '#6F04F4',
  600: '#6938EF',
  700: '#5925DC',
  800: '#4A1FB8',
  900: '#3E1C96',
};

const CONTRAST_TEXT_LIGHT = '#FFFFFF';
const CONTRAST_TEXT_DARK = '#020005';

export const Theme = ({ children }: PropsWithChildren) => {
  const { businessSettings } = useBusiness();

  useEffect(() => {
    let shades: string[];

    const primaryColor = businessSettings?.branding?.primaryColor;

    if (primaryColor) {
      const lightest = desaturate(lighten(primaryColor, 0.45), 0.3);
      const darkest = desaturate(darken(primaryColor, 0.2), 0.3);
      const scale = getScale(lightest, primaryColor, darkest);
      const steps = [0, ...Array.from(Array(11).keys()).map((i) => i / 10)];

      shades = steps.map((step) => scale(step));
    } else {
      shades = Object.values(GYMLY_SHADES);
    }

    let currentShade = 25;

    shades.forEach((shade) => {
      if (currentShade > 900) {
        return;
      }

      const property = `--color-primary-${currentShade}`;
      document.documentElement.style.setProperty(property, shade);

      if (currentShade === 50) {
        currentShade += 50;
      } else if (currentShade >= 100) {
        currentShade += 100;
      } else {
        currentShade += 25;
      }
    });

    document.documentElement.style.setProperty(
      '--color-primary-contrast-text',
      primaryColor && readableColorIsBlack(primaryColor) ? CONTRAST_TEXT_DARK : CONTRAST_TEXT_LIGHT,
    );

    const secondaryColor = businessSettings?.branding?.secondaryColor || CONTRAST_TEXT_DARK;

    document.documentElement.style.setProperty('--color-secondary', secondaryColor);
  }, [businessSettings]);

  return <>{children}</>;
};
