import { Membership, MembershipPaymentType, PaymentSchedule } from '@/web/types';
import Text from '@/design-system/v3/text';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getLocationPaymentSchedule } from '@/auth/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { useAuth } from '@/auth/hooks/use-auth';
import { useRecoilState } from 'recoil';
import { businessPaymentScheduleState } from '@/business/atoms';
import { useBusinessPaymentSchedule } from '@/web/hooks/use-business-payment-schedule';

type Props = {
  membership: Membership;
};

export const MembershipDescription = ({ membership }: Props) => {
  const { t } = useTranslation();
  const paymentSchedule = useBusinessPaymentSchedule();

  return (
    <>
      {membership.paymentType === MembershipPaymentType.PERIODIC && !!paymentSchedule && (
        <>
          {paymentSchedule.type === 'WEEKLY' && paymentSchedule.weekInterval && (
            <Text variant="small">
              {t('everyWeek', {
                count: paymentSchedule.weekInterval,
              })}
            </Text>
          )}

          {paymentSchedule.type === 'MONTHLY' && paymentSchedule.dayOfMonth && (
            <Text variant="small">
              {t('everyMonthOn', {
                count: paymentSchedule.dayOfMonth,
              })}
            </Text>
          )}
        </>
      )}
      {membership.paymentType === MembershipPaymentType.YEARLY && <Text variant="small">{t('everyYear')}</Text>}
      {membership.paymentType === MembershipPaymentType.ONCE && (
        <div>
          <Text variant="small">{t('onceOff')}</Text>
          {membership.entries !== undefined && (
            <Text variant="small" className="ml-1">
              -{' '}
              {t('nrOfEntry', {
                count: membership.entries,
              })}
            </Text>
          )}
          {membership.expireDays !== null && membership.expireDays > 0 && (
            <Text variant="small" className="ml-1">
              -{' '}
              {t('nrOfDay', {
                count: membership.expireDays,
              })}
            </Text>
          )}
        </div>
      )}
    </>
  );
};
