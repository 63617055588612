import { SafeHtml } from '@/common/components/safe-html';
import clsx from 'clsx';

export const RichTextContent = ({ html, className }: { html: string; className?: string }) => {
  return (
    <div className={clsx('rich-text-content', className)}>
      <SafeHtml html={html} />
    </div>
  );
};
