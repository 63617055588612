import { useCallback, useMemo, useState } from 'react';
import { Role, UserDataSetting, UserDataSettings } from '@/web/types';
import { useQuery } from 'react-query';
import { getUserDataSettings } from '@/web/endpoints';
import { useBusiness } from '@/web/hooks/use-business';

export const FETCH_USER_DATA_SETTINGS = 'FETCH_USER_DATA_SETTINGS';

export const useUserDataSettings = () => {
  const { businessUuid } = useBusiness();
  const [isLoaded, setIsLoaded] = useState(false);

  const { data } = useQuery([FETCH_USER_DATA_SETTINGS, businessUuid], async () => {
    if (!businessUuid) {
      return {};
    }

    return getUserDataSettings(businessUuid);
  });

  const settings = useMemo<UserDataSettings>(() => {
    if (data) {
      setIsLoaded(true);
    }

    return Object.keys(Role).reduce((acc, role) => {
      const roleKey = Role[role as keyof typeof Role];

      return {
        ...acc,
        [roleKey]: {
          lastNameRequired: data && data[roleKey] ? data[roleKey].lastNameRequired : true,
          dateOfBirthRequired: data && data[roleKey] ? data[roleKey].dateOfBirthRequired : true,
          addressRequired: data && data[roleKey] ? data[roleKey].addressRequired : true,
          genderRequired: data && data[roleKey] ? data[roleKey].genderRequired : true,
          phoneNumberRequired: data && data[roleKey] ? data[roleKey].phoneNumberRequired : true,
        },
      };
    }, {} as UserDataSettings);
  }, [data]);

  const isPropertyRequired = useCallback(
    (property: UserDataSetting, role: Role) => {
      return settings[role][property];
    },
    [settings],
  );

  return { isPropertyRequired, isUserDataLoaded: isLoaded };
};
