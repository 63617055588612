import Button, { ButtonProps } from '@/design-system/v3/button';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  onPreviousStep?: () => void;
  onNextStep: () => void;
  nextTitle?: ReactNode;
  previousTitle?: ReactNode;
}

export const OnboardingFooter = ({ onPreviousStep, onNextStep, nextTitle, previousTitle, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="grid gap-5 grid-cols-1 md:grid-cols-2 my-10">
      <Button onClick={onPreviousStep} className="order-2 md:order-1" variant="secondary">
        {previousTitle || t('previousStep')}
      </Button>
      <Button className="order-1 md:order-2" {...rest} onClick={onNextStep}>
        {nextTitle || t('nextStep')}
      </Button>
    </div>
  );
};
