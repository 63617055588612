import { RequireAuth } from '@/auth/components/require-auth';
import { ChangePasswordView } from './views/profile/change-password';
import { PersonalInfoView } from './views/profile/personal-info';
import { Subscriptions } from './views/profile/subscriptions';
import { CourseDetailView } from './views/course-detail';
import { InboxView, NotificationsView } from './views/inbox';
import { BookingFlow } from '@/common/components/booking-flow';
import { InvoicesView } from './views/profile/invoices';
import { QRCodeView } from './views/qr-code-view';
import { BusinessSwitchView } from './views/business-switch';
import { OnboardingFormProvider, OnboardingView } from '@/auth/views/onboarding';
import { Home } from '@/common/views/home';
import { Navigate, Outlet } from 'react-router-dom';
import { AttendeesListView } from '@/common/components/booking-flow/course-attendees-list';
import { PrivacyView } from './views/profile/privacy';
import { StreaksView } from './views/streaks';
import { MessageView } from './views/inbox/message';
import { ManageCoursesView } from '@/web/views/management/manage-courses-view';
import { ManageCourseView } from '@/web/views/management/manage-course-view';
import { LanguagesView } from '@/web/views/profile/languages';
import { ProfileHome } from './views/profile/profile-home';
import { TeacherCoursesView } from './views/teacher-courses';
import { ShopView } from '@/web/views/shop';
import { PaymentCompleteView } from '@/web/views/payment-complete';
import { OnboardingComplete } from '@/web/views/onboarding-complete';
import { WebShopTab } from '@/web/views/shop/webshop-tab';
import { MembershipsTab } from '@/web/views/shop/memberships-tab';
import { MembershipCheckout } from '@/web/views/shop/membership-checkout';
import { PrivacyPolicyDetail } from '@/web/views/profile/media-privacy/privacy-policy-detail';
import { TermsAndConditions } from '@/web/views/profile/media-privacy/terms-and-conditions';
import { BringAFriend } from '@/features/bring-a-friend/views/BringAFriend';

import { CourseBookingFlow } from '@/web/views/course-booking-flow';
import { ReservationView } from '@/web/views/reservations';
import { WaitlistView } from '@/web/views/waitlist';
import { BringAFriendOverview } from '@/features/bring-a-friend/views/BringAFriendOverview/BringAFriendOverview';
import { CourseAttendeesCheckin } from '@/common/components/booking-flow/course-attendees-checkin';

export const COURSES_PATH = '/courses';
export const COURSE_BOOKING_PATH = '/course-type/:id';
export const QRCODE_PATH = '/qr-code';
export const COURSE_DETAIL_PATH = COURSES_PATH + '/:uuid/:startAt';
export const COURSE_DETAIL_ATTENDEES_PATH = COURSE_DETAIL_PATH + '/attendees';
export const COURSE_DETAIL_CHECKIN_PATH = COURSE_DETAIL_PATH + '/checkin';
export const TEACHER_COURSES_PATH = '/teacher-courses';

export const INBOX_PATH = '/inbox';
export const INBOX_MESSAGES_PATH = 'messages';

export const INBOX_MESSAGE_PATH = `${INBOX_PATH}/${INBOX_MESSAGES_PATH}/:id`;

export const STREAKS_PATH = '/streaks';

export const SHOP_PATH = '/shop';
export const SHOP_WEBSHOP_PATH = 'webshop';

export const CHECKOUT_PATH = '/checkout';

export const CHECKOUT_MEMBERSHIP_PATH = 'membership/:id';

export const PROFILE_PATH = '/profile';
export const PERSONAL_INFO_PATH = 'personal-info';
export const PRIVACY_PATH = 'privacy';
export const PRIVACY_POLICY_PATH = 'privacy/privacy-policy';
export const TERMS_AND_CONDITIONS_PATH = 'terms-and-conditions';
export const CHANGE_PASSWORD_PATH = 'change-password';
export const SUBSCRIPTIONS_PATH = 'subscriptions';
export const INVOICES_PATH = 'invoices';
export const BUSINESS_SWITCH_PATH = 'business-switch';
export const LANGUAGES_PATH = 'languages';
export const PROFILE_RESERVATIONS_PATH = 'reservations';
export const PROFILE_WAITLIST_PATH = 'waitlist';
export const RESERVATIONS_PATH = '/reservations';
export const WAITLIST_PATH = '/waitlist';

export const MANAGE_PATH = '/manage';
export const MANAGE_COURSES_PATH = 'courses';

export const PAYMENT_COMPLETE_PATH = '/payment/complete';
export const ONBOARDING_COMPLETE_PATH = '/onboarding/complete';

export const BRING_A_FRIEND_PATH = '/bring-a-friend';
export const BRING_A_FRIEND_PROFILE_PATH = 'bring-a-friend-overview';
export const BRING_A_FRIEND_TO_COURSE_PATH = `courses/:uuid/:startAt`;

export const getInboxPath = (path: string) => INBOX_PATH + '/' + path;
export const getMessagePath = (messageId: string) => `${INBOX_PATH}/${INBOX_MESSAGES_PATH}/${messageId}`;
export const getCourseDetailPath = (uuid: string, startAt: string, path: string) =>
  `${COURSES_PATH}/${uuid}/${startAt}/${path}`;
export const getManagePath = (path: string) => MANAGE_PATH + '/' + path;
export const getProfilePath = (path: string) => PROFILE_PATH + '/' + path;

export const USER_ROUTES = [
  {
    path: '/',
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: SHOP_PATH,
    element: (
      <RequireAuth>
        <ShopView />
      </RequireAuth>
    ),
    nestedRoutes: [
      {
        path: '',
        element: <MembershipsTab />,
      },
      {
        path: SHOP_WEBSHOP_PATH,
        element: <WebShopTab />,
      },
    ],
  },
  {
    path: CHECKOUT_PATH,
    element: (
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    ),
    nestedRoutes: [
      {
        path: CHECKOUT_MEMBERSHIP_PATH,
        element: (
          <RequireAuth>
            <MembershipCheckout />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: TEACHER_COURSES_PATH,
    element: (
      <RequireAuth>
        <TeacherCoursesView />
      </RequireAuth>
    ),
    allowOnly: ['TEACHER', 'STAFF', 'ADMIN'],
  },
  {
    path: '/onboarding',
    element: (
      <RequireAuth>
        <OnboardingFormProvider>
          <OnboardingView />
        </OnboardingFormProvider>
      </RequireAuth>
    ),
  },
  {
    path: ONBOARDING_COMPLETE_PATH,
    element: <OnboardingComplete />,
  },
  {
    path: PAYMENT_COMPLETE_PATH,
    element: <PaymentCompleteView />,
  },
  {
    path: INBOX_PATH,
    element: (
      <RequireAuth>
        <InboxView />
      </RequireAuth>
    ),
    nestedRoutes: [
      {
        path: '',
        element: (
          <RequireAuth>
            <Navigate to={INBOX_MESSAGES_PATH} replace />
          </RequireAuth>
        ),
      },
      {
        path: INBOX_MESSAGES_PATH,
        element: (
          <RequireAuth>
            <NotificationsView />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: INBOX_MESSAGE_PATH,
    element: (
      <RequireAuth>
        <MessageView />
      </RequireAuth>
    ),
  },
  {
    path: COURSES_PATH,
    element: (
      <RequireAuth>
        <BookingFlow />
      </RequireAuth>
    ),
  },
  {
    path: COURSE_BOOKING_PATH,
    element: (
      <RequireAuth>
        <CourseBookingFlow />
      </RequireAuth>
    ),
  },
  {
    path: STREAKS_PATH,
    element: (
      <RequireAuth>
        <StreaksView />
      </RequireAuth>
    ),
  },
  {
    path: QRCODE_PATH,
    element: (
      <RequireAuth>
        <QRCodeView />
      </RequireAuth>
    ),
  },
  {
    path: RESERVATIONS_PATH,
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: WAITLIST_PATH,
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: COURSE_DETAIL_PATH,
    element: (
      <RequireAuth>
        <CourseDetailView />
      </RequireAuth>
    ),
  },
  {
    path: COURSE_DETAIL_ATTENDEES_PATH,
    element: (
      <RequireAuth>
        <AttendeesListView />
      </RequireAuth>
    ),
  },
  {
    path: COURSE_DETAIL_CHECKIN_PATH,
    element: (
      <RequireAuth>
        <CourseAttendeesCheckin />
      </RequireAuth>
    ),
  },
  {
    path: MANAGE_PATH,
    element: null,
    nestedRoutes: [
      {
        path: MANAGE_COURSES_PATH,
        element: (
          <RequireAuth>
            <ManageCoursesView />
          </RequireAuth>
        ),
      },
      {
        path: `${MANAGE_COURSES_PATH}/:id`,
        element: (
          <RequireAuth>
            <ManageCourseView />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: PROFILE_PATH,
    element: <Outlet />,
    nestedRoutes: [
      {
        path: '',
        element: (
          <RequireAuth>
            <ProfileHome />
          </RequireAuth>
        ),
      },
      {
        path: PERSONAL_INFO_PATH,
        element: (
          <RequireAuth>
            <PersonalInfoView />
          </RequireAuth>
        ),
      },
      {
        path: PRIVACY_PATH,
        element: (
          <RequireAuth>
            <PrivacyView />
          </RequireAuth>
        ),
      },
      {
        path: PRIVACY_POLICY_PATH,
        element: (
          <RequireAuth>
            <PrivacyPolicyDetail />
          </RequireAuth>
        ),
      },

      {
        path: BRING_A_FRIEND_PROFILE_PATH,
        element: (
          <RequireAuth>
            <BringAFriendOverview />
          </RequireAuth>
        ),
      },
      {
        path: TERMS_AND_CONDITIONS_PATH,
        element: (
          <RequireAuth>
            <TermsAndConditions />
          </RequireAuth>
        ),
      },
      {
        path: CHANGE_PASSWORD_PATH,
        element: (
          <RequireAuth>
            <ChangePasswordView />
          </RequireAuth>
        ),
      },
      {
        path: SUBSCRIPTIONS_PATH,
        element: (
          <RequireAuth>
            <Subscriptions />
          </RequireAuth>
        ),
      },
      {
        path: INVOICES_PATH,
        element: (
          <RequireAuth>
            <InvoicesView />
          </RequireAuth>
        ),
      },
      {
        path: BUSINESS_SWITCH_PATH,
        element: (
          <RequireAuth>
            <BusinessSwitchView />
          </RequireAuth>
        ),
      },
      {
        path: LANGUAGES_PATH,
        element: <LanguagesView />,
      },
      {
        path: PROFILE_RESERVATIONS_PATH,
        element: (
          <RequireAuth>
            <ReservationView wrapped />
          </RequireAuth>
        ),
      },
      {
        path: PROFILE_WAITLIST_PATH,
        element: (
          <RequireAuth>
            <WaitlistView wrapped />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: BRING_A_FRIEND_PATH,
    element: (
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    ),
    nestedRoutes: [
      {
        path: '',
        element: <BringAFriend />,
      },
      {
        path: BRING_A_FRIEND_TO_COURSE_PATH,
        element: <BringAFriend />,
      },
    ],
  },
];
