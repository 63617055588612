import { Gender, GenderToLabel, User } from '@/auth/types';
import { countries } from '@/auth/views/onboarding/utils';
import { getEmailValidator, getNameValidator, getRequiredValidator } from '@/common/validators';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { changeEmail, FETCH_USER, updateUser, uploadUserProfileImage } from '@/web/endpoints';
import { userToForm } from './utils';
import { useQueryClient } from 'react-query';
import { useEffect, useMemo } from 'react';
import { ImageUpload } from './image-upload';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authUserState } from '@/auth/atoms';
import { GInput } from '@/design-system/v3/g-input';
import { Alert, AlertVariant } from '../../../../design-system/v3/alert';
import Button from '../../../../design-system/v3/button';
import { GSelect } from '../../../../design-system/v3/g-select';
import { handleValidationError, resizeImage } from '../../../../common/utils';
import { useSnack } from '../../../../common/hooks/use-snack';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { BackToButton } from '../../extra-menu';
import { PROFILE_PATH } from '@/web/routes';
import { useUserDataSettings } from '@/web/hooks/use-user-data-settings';
import { Role, UserDataSetting } from '@/web/types';
import { GPhone } from '@/design-system/v3/g-phone';

interface FormProps {
  membershipId: string;
  firstName: string;
  lastName: string;
  insertion: string;
  email: string;
  profileImagePath: string;
  dateOfBirth: string;
  phoneNumber: string;
  gender: Gender | null;
  address: {
    country: string;
    street: string;
    postalCode: string;
    city: string;
  };
}

export const PersonalInfoView = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(authUserState);
  const { successMessage, errorMessage } = useSnack();
  const { isPropertyRequired, isUserDataLoaded } = useUserDataSettings();
  const setAuthUser = useSetRecoilState(authUserState);
  const {
    handleSubmit,
    control,
    trigger,
    register,
    formState: { errors, isSubmitting, isDirty, dirtyFields },
    setError,
  } = useForm<FormProps>({
    mode: 'onChange',
    defaultValues: user ? userToForm(user) : null,
  });
  const queryClient = useQueryClient();

  const email = useWatch({ control, name: 'email' });

  const onSubmit = async ({ profileImagePath, ...data }: any) => {
    const promises: Promise<any>[] = [];

    if (dirtyFields.profileImagePath) {
      const resized = await resizeImage(profileImagePath);
      promises.push(uploadUserProfileImage(resized));
    }

    if (dirtyFields.email) {
      promises.push(changeEmail(data.email));
    }

    const userPromise = updateUser({
      firstName: data.firstName,
      insertion: data.insertion,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      phoneNumber: data.phoneNumber,
      gender: data.gender,
      address: {
        street: data.address.street,
        city: data.address.city,
        postalCode: data.address.postalCode,
        country: data.address.country,
      },
    });

    promises.push(userPromise);

    try {
      await Promise.all(promises);
      const user = (await userPromise) as User;
      setAuthUser(user);
      await queryClient.invalidateQueries(FETCH_USER);
      successMessage('saved', { detailedMessage: 'profileView.snack.success.message' });
    } catch (e: any) {
      handleValidationError(e, setError, errorMessage);
    }
  };

  useEffect(() => {
    if (user) {
      trigger();
    }
  }, [user, trigger]);

  const content = !isUserDataLoaded ? (
    <></>
  ) : (
    <div className="space-y-5 sm:max-w-[520px]">
      <Controller
        name={'profileImagePath'}
        control={control}
        render={({ field: { onChange } }) => <ImageUpload onChange={onChange} value={user?.profileImageUrl} />}
      />

      <GInput
        {...register('email', { ...getEmailValidator() })}
        error={errors.email?.message?.toString()}
        title={t('authView.form.email.title')}
        required
      />

      {dirtyFields.email && !errors.email && (
        <Alert
          variant={AlertVariant.DEFAULT}
          title="Confirmation is required"
          content={
            <span>
              An email will be sent to <strong>{email}</strong> in order to confirm the change.
            </span>
          }
        />
      )}

      <GInput
        {...register('firstName', { ...getNameValidator() })}
        error={errors.firstName?.message?.toString()}
        title={t('authView.form.firstName.title')}
        required
      />

      <GInput
        {...register('insertion', { ...getNameValidator(false) })}
        error={errors.insertion?.message?.toString()}
        title={t('authView.form.insertion.title')}
        optionalLabel
      />

      <GInput
        {...control.register('lastName', {
          ...getNameValidator(isPropertyRequired(UserDataSetting.LAST_NAME_REQUIRED, Role.MEMBER)),
        })}
        error={errors.lastName?.message?.toString()}
        title={t('authView.form.lastName.title')}
        required={isPropertyRequired(UserDataSetting.LAST_NAME_REQUIRED, Role.MEMBER)}
        optionalLabel
      />

      <GInput
        {...register('dateOfBirth', {
          ...(isPropertyRequired(UserDataSetting.DATE_OF_BIRTH_REQUIRED, Role.MEMBER) ? getRequiredValidator() : {}),
        })}
        title={t('authView.form.dob.title')}
        type="date"
        error={errors.dateOfBirth?.message?.toString()}
        required={isPropertyRequired(UserDataSetting.DATE_OF_BIRTH_REQUIRED, Role.MEMBER)}
      />

      <Controller
        name={'address.country'}
        control={control}
        rules={{
          ...(isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER) ? getRequiredValidator() : {}),
        }}
        render={({ field: { onChange, value } }) => (
          <GSelect
            onChange={onChange}
            options={countries}
            error={errors.address?.country?.message?.toString()}
            value={value}
            title={t('addressForm.country.title')}
            required={isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)}
          />
        )}
      />

      <GInput
        {...register('address.street', {
          ...getNameValidator(isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)),
        })}
        required={isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)}
        error={errors.address?.street?.message?.toString()}
        title={t('addressForm.address.title')}
      />

      <GInput
        {...register('address.postalCode', {
          ...getNameValidator(isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)),
        })}
        required={isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)}
        error={errors.address?.postalCode?.message?.toString()}
        title={t('addressForm.postalCode.title')}
      />

      <GInput
        {...register('address.city', {
          ...getNameValidator(isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)),
        })}
        required={isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)}
        error={errors.address?.city?.message?.toString()}
        title={t('addressForm.city.title')}
      />

      <Controller
        name={'phoneNumber'}
        control={control}
        rules={{
          ...(isPropertyRequired(UserDataSetting.PHONE_NUMBER_REQUIRED, Role.MEMBER) ? getRequiredValidator() : {}),
        }}
        render={({ field: { onChange, value } }) => (
          <GPhone
            onChange={onChange}
            error={errors.phoneNumber?.message?.toString()}
            value={value}
            title="Phone number"
            placeholder="Phone number"
            name="phoneNumber"
            required={isPropertyRequired(UserDataSetting.PHONE_NUMBER_REQUIRED, Role.MEMBER)}
          />
        )}
      />

      <Controller
        name={'gender'}
        control={control}
        rules={{
          ...(isPropertyRequired(UserDataSetting.GENDER_REQUIRED, Role.MEMBER) ? getRequiredValidator() : {}),
        }}
        render={({ field: { onChange, value } }) => (
          <GSelect
            onChange={onChange}
            error={errors.gender?.message?.toString()}
            options={[
              {
                label: GenderToLabel[Gender.MALE],
                value: Gender.MALE,
              },
              {
                label: GenderToLabel[Gender.FEMALE],
                value: Gender.FEMALE,
              },
              {
                label: GenderToLabel[Gender.OTHER],
                value: Gender.OTHER,
              },
              {
                label: GenderToLabel[Gender.NOT_SPECIFIED],
                value: Gender.NOT_SPECIFIED,
              },
            ]}
            value={value}
            title="Gender"
            placeholder="Gender"
            required={isPropertyRequired(UserDataSetting.GENDER_REQUIRED, Role.MEMBER)}
          />
        )}
      />

      <Button onClick={handleSubmit(onSubmit)} loading={isSubmitting} disabled={!isDirty} className="self-start">
        Save
      </Button>
    </div>
  );

  return (
    <PageWrapper
      header={
        <PageHeader title={t('profileMenuView.personalInfo.title')} leftAction={<BackToButton path={PROFILE_PATH} />} />
      }
      contentClasses="py-4"
      content={content}
    />
  );
};
