import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { InstructionSteps } from '@/common/components/instruction-steps/InstructionSteps';

interface Props {
  isCourse?: boolean;
}

export const BringAFriendInstructions = ({ isCourse }: Props) => {
  const { t } = useTranslation();

  const steps = useMemo(() => {
    return [
      {
        step: 1,
        title: t('bringAFriend.step1.title'),
        description: t('bringAFriend.step1.description'),
      },
      {
        step: 2,
        title: t('bringAFriend.step2.title'),
        description: t('bringAFriend.step2.description'),
      },
      {
        step: 3,
        title: isCourse ? t('bringAFriend.step3.title.class') : t('bringAFriend.step3.title'),
        description: t('bringAFriend.step3.description'),
      },
    ];
  }, [t, isCourse]);

  return <InstructionSteps steps={steps} title={t('bringAFriend.howItWorks')} />;
};
