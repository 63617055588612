import { CourseType } from '@/web/types';
import ChevronRight from '@/common/components/icon/icons/v2/chevron-right.svg?react';
import { useNavigate } from 'react-router-dom';
import { getManagePath, MANAGE_COURSES_PATH } from '@/web/routes';
import { filterHtmlContent } from '@/common/utils';

type Props = {
  courseType: CourseType;
};

export const CourseTypeItem = ({ courseType }: Props) => {
  const navigate = useNavigate();

  const handleClick = () => navigate(`${getManagePath(MANAGE_COURSES_PATH)}/${courseType.id}`);

  return (
    <div className="flex justify-between py-3" onClick={handleClick}>
      <div className="flex flex-row space-x-3">
        <div className="w-[92px] h-[64px] min-w-[92px] bg-gray-200 rounded-md overflow-hidden">
          {courseType.imagePath && (
            <img src={courseType.imagePath} alt={courseType.name} className="w-full h-full object-cover" />
          )}
        </div>
        <div className="flex flex-col py-1.5">
          <span className="text-sm font-medium">{courseType.name}</span>
          <span
            className="text-gray line-clamp-2"
            dangerouslySetInnerHTML={{
              __html: filterHtmlContent(courseType.description || ''),
            }}
          />
        </div>
      </div>
      <div className="flex items-center">
        <ChevronRight className="text-typo-secondary" />
      </div>
    </div>
  );
};
