import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { login, signup } from '../../endpoints';
import { getEmailValidator, getPasswordValidator } from '@/common/validators';
import { getDecodedToken } from '@/auth/utils';
import Button from '@/design-system/v3/button';
import { useSetRecoilState } from 'recoil';
import { authJWTState, authTokenSelector } from '@/auth/atoms';
import { useQuery } from 'react-query';
import { getBusiness } from '@/business/endpoints';
import { handleValidationError, setInStorage } from '@/common/utils';
import { ONBOARDING_BUSINESS_ID } from '@/common/constants';
import { GLink } from '@/design-system/v3/g-link';
import { GInput } from '@/design-system/v3/g-input';
import GymlySymbol from '@/gymly-symbol.svg?react';
import { Title } from '@/design-system/v3/title';
import Text from '@/design-system/v3/text';
import { GPageLoader } from '@/design-system/v3/g-page-loader';
import { NotFound } from '@/common/views/not-found';
import { useSnack } from '@/common/hooks/use-snack';
import { ErrorCode } from '@/common/types';
import { useTranslation } from 'react-i18next';

interface FormData {
  email: string;
  password: string;
}

export const SignupView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const businessUuid = params.id;
  const setAuthToken = useSetRecoilState(authTokenSelector);
  const setAuthJWT = useSetRecoilState(authJWTState);
  const { data: business, isFetching } = useQuery('FETCH_BUSINESS', async () => {
    if (businessUuid) {
      const business = await getBusiness(businessUuid);

      if (business?.id) {
        setInStorage(ONBOARDING_BUSINESS_ID, business.id);
      }

      return business;
    }

    return null;
  });
  const { successMessage, errorMessage } = useSnack();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await signup(data.email, data.password);
      const jwtResponse = await login(data.email, data.password);

      setAuthToken(jwtResponse.jwt);
      setAuthJWT(getDecodedToken());
      successMessage('signUp.succesfull');
    } catch (e) {
      const response = e.response?.data;

      if (response && response.code === ErrorCode.ACCOUNT_EMAIL_ALREADY_IN_USE) {
        setError('email', { message: 'Email is already in use.' });
      } else {
        handleValidationError(e, undefined, errorMessage);
      }
    }
  };

  if (isFetching) {
    return <GPageLoader />;
  }

  if (!business) {
    return <NotFound />;
  }

  return (
    <div className="h-full w-full flex items-center justify-center bg-gray-50 px-4">
      <div className="max-w-[440px] w-full">
        <div className="flex flex-col text-center justify-center items-center">
          <GymlySymbol className="h-10 w-10" />

          <div className="mt-6">
            <Title>Create a free account</Title>
            <Text className="mt-3">
              Create a free account to join{' '}
              <Text as="span" bold className="text-primary">
                {business.name}
              </Text>
              .
            </Text>
          </div>

          <form className="py-8 px-10 shadow-md rounded-xl bg-white w-full mt-8">
            <div className="flex flex-col space-y-6">
              <GInput
                {...control.register('email', {
                  ...getEmailValidator(),
                })}
                error={errors.email?.message?.toString()}
                type="email"
                placeholder="Your email"
                title="Email address"
              />
              <GInput
                {...control.register('password', {
                  ...getPasswordValidator(),
                })}
                name="password"
                type="password"
                autoComplete="new-password"
                placeholder="Enter your password"
                error={errors.password?.message?.toString()}
                title="Password"
              />

              <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid} loading={isSubmitting}>
                Create free account
              </Button>

              <Text className="text-gray my-6">
                By clicking “Create free account”, I agree to Gymly’s{' '}
                <GLink target="_blank" to="https://gymly.io/terms-of-service">
                  Terms
                </GLink>{' '}
                and{' '}
                <GLink target="_blank" to="https://gymly.io/privacy">
                  Privacy Policy
                </GLink>
              </Text>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
