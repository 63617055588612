import { useAuth } from '@/auth/hooks/use-auth';
import { getEmailValidator, getPasswordValidator } from '@/common/validators';
import { GInput } from '@/design-system/v3/g-input';
import { OnboardingStepsLayout } from '../../../onboarding-steps-layout';
import { OnboardingFooter } from '../../../onboarding-footer';
import { OnboardingTransition } from '../../../onboarding-transition';
import { OnboardingFormContext, OnboardingFormData } from '../../..';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserDataSettings } from '@/web/hooks/use-user-data-settings';

import { useSetRecoilState } from 'recoil';
import { authJWTState, authTokenSelector } from '@/auth/atoms';
import { getDecodedToken } from '@/auth/utils';
import { useSnack } from '@/common/hooks/use-snack';
import { login } from '@/auth/endpoints';
import { isErrorResponse } from '@/common/utils';
import { ErrorCode } from '@/common/types';

interface Props {
  initialEmail?: string;
  onNextStep: () => void;
  onPreviousStep?: () => void;
}

export const OnboardingSignIn = ({ onNextStep, onPreviousStep }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = useContext(OnboardingFormContext);
  const { isPropertyRequired, isUserDataLoaded } = useUserDataSettings();
  const { user } = useAuth();
  const isPartOfBusiness = !!user?.businesses?.length;

  const { successMessage, errorMessage } = useSnack();
  const setAuthToken = useSetRecoilState(authTokenSelector);
  const setAuthJWT = useSetRecoilState(authJWTState);

  const signIn = async (data: { email: string; password: string }) => {
    try {
      const jwtResponse = await login(data.email, data.password);

      setAuthToken(jwtResponse.jwt);
      setAuthJWT(getDecodedToken());
      successMessage('authView.snack.success.title', { detailedMessage: 'authView.snack.success.message' });
    } catch (e: unknown) {
      if (isErrorResponse(e) && e.response?.data.code === ErrorCode.BAD_CREDENTIALS) {
        errorMessage('authView.snack.error.title', {
          detailedMessage: 'authView.snack.error.message',
        });
      } else {
        errorMessage('authView.snack.error.title', {
          detailedMessage: 'authView.snack.error.general.message',
        });
      }
    }
  };

  const email = watch('email');
  const password = watch('password');

  return (
    <OnboardingStepsLayout title={t('signIn')}>
      <OnboardingTransition>
        {isUserDataLoaded && (
          <div className="flex flex-col space-y-4">
            <GInput
              {...control.register('email', {
                ...getEmailValidator(),
              })}
              readOnly={!!user?.email}
              error={errors.email?.message?.toString()}
              type="email"
              autoComplete="username"
              placeholder={t('authView.form.email.placeholder')}
              title={t('authView.form.email.title')}
              required
            />
            <div className={user?.email ? 'hidden' : undefined}>
              <GInput
                {...control.register('password', {
                  ...(user?.email ? {} : getPasswordValidator()),
                })}
                readOnly={!!user?.email}
                autoComplete="current-password"
                name="password"
                type="password"
                placeholder={t('authView.form.password.placeholder')}
                error={errors.password?.message?.toString()}
                title={t('authView.form.password.title')}
                required
              />
            </div>
          </div>
        )}
        <OnboardingFooter
          onPreviousStep={onPreviousStep}
          previousTitle={t('previous')}
          loading={isSubmitting}
          onNextStep={handleSubmit(signIn)}
          nextTitle={isPartOfBusiness ? t('complete') : t('signIn')}
          disabled={
            isSubmitting || (email === '' && password === '') || !!errors.email?.message || !!errors.password?.message
          }
        />
      </OnboardingTransition>
    </OnboardingStepsLayout>
  );
};
