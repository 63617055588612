import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { useBusiness } from '@/web/hooks/use-business';
import { useAuth } from '@/auth/hooks/use-auth';
import Button from '@/design-system/v3/button';
import Text from '@/design-system/v3/text';
import { GAvatar } from '@/design-system/g-avatar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BackToButton } from '../extra-menu';
import { PROFILE_PATH } from '@/web/routes';

export const BusinessSwitchView = () => {
  const { t } = useTranslation();
  const { businessUuid, setBusinessUuid, businessLocation } = useBusiness();
  const { user } = useAuth();
  const navigate = useNavigate();

  const content = (
    <div>
      <Text className="p-4 text-typo-secondary" variant="small">
        {t('businessSwitch.subtitle')}
      </Text>
      {user?.businesses?.map((business) => (
        <div
          key={business.businessId}
          className="border-t only:border-b last:border-b dark:last:dark:border-b-gray-700 dark:only:dark:border-b-gray-700 dark:border-t-gray-700 w-full flex items-center justify-between p-4"
        >
          <div className="flex items-center space-x-4">
            <GAvatar
              src={business.businessIcon || business.businessLogo || undefined}
              size="xl"
              className="rounded-xl"
            />
            <div className="space-y-1 text-sm">
              <div>{business.businessName}</div>
              <div className="text-gray-500">
                <div>{business.userType}</div>
                <div>{businessLocation?.name}</div>
              </div>
            </div>
          </div>
          <Button
            variant="secondary"
            className="w-auto"
            disabled={business.businessId === businessUuid}
            onClick={() => {
              setBusinessUuid(business.businessId);
              navigate('/');
            }}
          >
            {business.businessId === businessUuid ? t('businessSwitch.btn.current') : t('businessSwitch.btn.select')}
          </Button>
        </div>
      ))}
    </div>
  );

  return (
    <PageWrapper
      header={<PageHeader title={t('businessSwitch.title')} leftAction={<BackToButton path={PROFILE_PATH} />} />}
      contentClasses="p-0"
      content={content}
    />
  );
};
