import { BringAFriendInvitedListSkeleton } from '@/features/bring-a-friend/components/BringAFriendInvitedList/BringAFriendInvitedListSkeleton';
import { BringAFriendHeaderSkeleton } from '@/features/bring-a-friend/components/BringAFriendHeader/BringAFriendHeaderSkeleton';
import { BringAFriendOverviewCardSkeleton } from '@/features/bring-a-friend/components/BringAFriendOverviewCards/BringAFriendOverviewCardSkeleton';

const BringAFriendOverviewBasicSkeleton = () => {
  return (
    <div>
      <div className="mt-6 mb-8">
        <BringAFriendHeaderSkeleton />
      </div>
      <BringAFriendOverviewCardSkeleton />
      <div className="mt-8">
        <BringAFriendInvitedListSkeleton />
      </div>
    </div>
  );
};

export default BringAFriendOverviewBasicSkeleton;
