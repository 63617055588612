import { useBusiness } from '@/web/hooks/use-business';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useEmbed } from '@/common/hooks/use-embed';
import { GPageLoader } from '@/design-system/v3/g-page-loader';

const variants = {
  hidden: {
    y: '100%',
    opacity: 0,
  },
  visible: {
    y: '0%',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 200,
    },
  },
  exit: {
    opacity: 0,
    y: '100%',
    transition: {
      duration: 0.2,
    },
  },
};

export const SplashScreen = ({ children }: { children: ReactNode }) => {
  const { businessLogo } = useBusiness();
  const embed = useEmbed();
  const [showSplash, setShowSplash] = useState(screen.width < 900);

  useEffect(() => {
    setTimeout(() => setShowSplash(false), 1000);
  }, []);

  const splash = useMemo(() => {
    if (embed) {
      return <GPageLoader />;
    }

    if (businessLogo) {
      return (
        <motion.div
          className="fixed flex flex-col justify-center items-center h-screenHeight min-h-screenHeight"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={variants}
        >
          <img src={businessLogo} className="w-[50%]" alt="logo" />
        </motion.div>
      );
    }
  }, [businessLogo, embed]);

  return <AnimatePresence mode="wait">{showSplash && splash ? splash : children}</AnimatePresence>;
};
