import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import { ReactNode } from 'react';

export const RequireNoAuth = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
