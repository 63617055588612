import { stripHtml } from 'string-strip-html';
import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser';
import parse, { domToReact, Element } from 'html-react-parser';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ignoreTags = ['p', 'strong', 'div', 'a', 'br'];
const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      const children = domToReact(domNode.children as DOMNode[], options);

      if (domNode.name === 'a') {
        return (
          <a
            href={domNode.attribs.href}
            target={domNode.attribs.target || '_self'}
            className="text-primary"
            rel={domNode.attribs.target === '_blank' ? 'noopener noreferrer' : undefined}
          >
            {children}
          </a>
        );
      }
    }

    return domNode;
  },
};

const convertToHyperlink = (text: string) => {
  const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#/%=~_|$?!:,.]*[-A-Z0-9+&@#/%=~_|$])/gi;
  return text.replace(urlRegex, (url) => {
    return `<a target="_blank" href="${url}">${url}</a>`;
  });
};

export const Markup = ({ content, trimTo, className }: { content: string; trimTo?: number; className?: string }) => {
  const cleaned = convertToHyperlink(content).replace(/\\n/g, '<br />').replace(/\n/g, '<br />').replace(/\\/g, '');
  const [isStrimmed, setTrimmed] = useState(!!(trimTo && cleaned.length > trimTo));
  const { t } = useTranslation();
  const cls = twMerge('font-neutral text-lg', className);

  if (isStrimmed) {
    return (
      <div className={cls}>
        <div>{stripHtml(cleaned).result.substring(0, trimTo)} ...</div>
        <button className="text-md text-primary" onClick={() => setTrimmed(false)}>
          {t('readMore')}
        </button>
      </div>
    );
  }

  return <div className={cls}>{parse(stripHtml(cleaned, { ignoreTags }).result, options)}</div>;
};
