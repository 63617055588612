import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { GFormControlLabel } from '../g-form-control-label';
import { Eye, EyeClosed } from 'phosphor-react';

type Size = 'regular' | 'small' | 'large' | 'xl';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  gtitle?: string;
  title?: string;
  labelSize?: 'sm' | 'md';
  error?: boolean | ReactNode;
  icon?: ReactNode;
  gsize?: Size;
  iconContainerClasses?: string;
  helperText?: ReactNode;
  optionalLabel?: boolean;
  autoComplete?: string;
}

export const GInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      gtitle,
      helperText,
      error,
      className,
      iconContainerClasses,
      required,
      icon,
      type,
      value,
      labelSize,
      gsize = 'regular',
      optionalLabel = false,
      autoComplete = 'off',
      ...rest
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassoword] = useState(false);
    const hasIcon = icon || type === 'password';
    const title = gtitle ?? rest.title;

    const iconContainerClassesMerged = twMerge(
      clsx('flex items-center bg-white dark:bg-black-100 border border-gray-300 rounded-md px-3 transition-all', {
        'border-error-300': error,
        'shadow-inputFocusError': error && isFocused,
        'shadow-inputFocus border-primary-300': !error && isFocused,
      }),
      iconContainerClasses,
    );
    const mergedClasses = twMerge(
      getInputClasses({
        error: !!error,
        gsize,
        icon: !!icon,
        password: type === 'password',
        disabled: !!rest.disabled,
      }),
      className,
    );

    const getType = () => {
      if (type === 'password') {
        return showPassword ? 'text' : 'password';
      }

      return type || 'text';
    };

    const control = (
      <div className={hasIcon ? iconContainerClassesMerged : undefined}>
        {icon}
        <input
          type={getType()}
          ref={ref}
          placeholder={rest.placeholder || title}
          className={mergedClasses}
          value={value}
          {...rest}
          autoComplete={autoComplete}
          onFocus={(ev) => {
            if (rest.onFocus) rest.onFocus(ev);
            setIsFocused(true);
          }}
          onBlur={(ev) => {
            if (rest.onBlur) rest.onBlur(ev);
            setIsFocused(false);
          }}
        />
        {type === 'password' && (
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault();
              setShowPassoword(!showPassword);
            }}
          >
            {showPassword ? <Eye /> : <EyeClosed />}
          </button>
        )}
      </div>
    );

    return (
      <label className="block text-left">
        {title && (
          <GFormControlLabel
            control={control}
            label={title}
            required={required}
            optionalLabel={optionalLabel}
            size={labelSize}
          />
        )}
        {!title && control}
        {((error && typeof error !== 'boolean') || helperText) && (
          <div className="space-y-1 mt-1">
            {error && typeof error !== 'boolean' && <div className="text-error">{error}</div>}
            {helperText && <div className="text-gray-600 text-sm">{helperText}</div>}
          </div>
        )}
      </label>
    );
  },
);

GInput.displayName = 'GInput';

const getBorderStyle = (error: boolean) =>
  clsx(
    error
      ? 'border-error-300 focus:border-error-300 focus:shadow-inputFocusError'
      : 'border-gray-300 focus:shadow-inputFocus focus:border-primary-300',
  );
export const getInputClasses = ({
  error,
  gsize,
  icon,
  password,
  disabled,
}: {
  error: boolean;
  icon: boolean;
  disabled: boolean;
  password: boolean;
  gsize: Size;
}) =>
  clsx(
    'w-full rounded-md text-typo-primary shadow-input text-base placeholder-typo-secondary',
    'focus:outline-none focus:ring-0 focus:ring-offset-0 focus:text-typo-primary',
    'disabled:bg-gray-50 disabled:border-gray-300 disabled:text-typo-secondary',
    {
      'h-10': gsize === 'regular',
      'h-8': gsize === 'small',
      'h-12': gsize === 'large',
      'h-14': gsize === 'xl',
    },
    {
      'border-none': icon || password,
      'transition-all': !(icon || password),
      'pl-0': !!password,
      [getBorderStyle(error)]: !(icon || password),
      'opacity-50': disabled,
    },
  );
