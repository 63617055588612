import axios, { AxiosError } from 'axios';
import { getLocalToken } from '@/auth/utils';
import { API } from '@/common/constants';
import { captureException } from '@sentry/react';

interface JavaError {
  error: string;
  path: string;
  status: number;
  timestamp: string;
  trace: string;
}

export const handleRequestError = (e: AxiosError<JavaError>) => {
  console.log(e);

  captureException(e, {
    contexts: {
      metadata: {
        trace: e.response?.data?.trace || '',
      },
    },
  });

  throw e;
};

const gaxios = axios.create({
  baseURL: API,
  timeout: 30000,
});

gaxios.interceptors.request.use((request) => {
  const token = getLocalToken();

  if (token && request.headers) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }

  return request;
});

gaxios.interceptors.response.use(
  (response) => response,
  (error) => {
    handleRequestError(error);
  },
);

export { gaxios };
