import { useAuth } from '@/auth/hooks/use-auth';
import { AUTH_ROUTES } from '@/auth/routes';
import { NotFound } from '@/common/views/not-found';
import { Navigate, Route, Routes } from 'react-router-dom';
import { USER_ROUTES } from '@/web/routes';
import { useMemo } from 'react';
import { GPanel } from '@/design-system/v3/g-panel';
import { ExtraMenu } from '@/web/views/extra-menu';
import { useRecoilState } from 'recoil';
import { appState } from '@/common/atoms';

const getRoutesList = (routes: any, userType: any) => {
  return routes.map((route: any) => {
    const { nestedRoutes, allowOnly, ...rest } = route;
    const isAllowed = !allowOnly || allowOnly.includes(userType);

    if (!isAllowed && userType) {
      return <Route key={`route-path-${rest.path}`} path={rest.path} element={<Navigate to="/" />} />;
    }

    if (nestedRoutes) {
      return (
        <Route key={`route-path-${rest.path || 'home'}`} {...rest}>
          {getRoutesList(route.nestedRoutes, userType)}
        </Route>
      );
    }

    return <Route key={`route-path-${rest.path || 'home'}`} {...rest} />;
  });
};

export const Router = () => {
  const { userType } = useAuth();
  const [appStateValue, setAppState] = useRecoilState(appState);

  const routes = useMemo(() => {
    return [...AUTH_ROUTES, ...USER_ROUTES];
  }, []);

  return (
    <>
      <Routes>
        {getRoutesList(routes, userType)}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <GPanel
        open={appStateValue.extraMenuOpen}
        childrenClassName="px-0"
        onClose={() => setAppState((prev) => ({ ...prev, extraMenuOpen: false }))}
      >
        <ExtraMenu />
      </GPanel>
    </>
  );
};
