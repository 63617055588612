import { useTranslation } from 'react-i18next';
import { BringAFriendInvitedItem } from './BringAFriendInvitedItem';
import { InvitationStatus } from '../../enums/InvitationStatus.enum';
import { GCard } from '@/design-system/v3/g-card';
import { InvitedFriend } from '../../types/InvitedFriend';
import { useQuery } from 'react-query';
import { BRING_A_FRIEND_INVITATIONS, getInvitations } from '../../api/BringAFriendApi';
import { format } from 'date-fns';
import { BringAFriendInvitedListSkeleton } from './BringAFriendInvitedListSkeleton';

interface Props {
  invitations: InvitedFriend[];
}
export const BringAFriendInvitedList = ({ invitations }: Props) => {
  const { t } = useTranslation();

  const onCancelFriendInvitation = (friend: InvitedFriend) => {};

  return (
    <div>
      <h4 className="font-medium text-base mb-3">
        {t('recentInvites', {
          count: invitations?.length,
        })}
      </h4>
      <GCard variant="secondary">
        <div className="divide-y">
          {invitations?.map((invitation) => (
            <BringAFriendInvitedItem
              key={invitation.id}
              friendInvitation={invitation}
              onCancel={() => onCancelFriendInvitation(invitation)}
            />
          ))}
        </div>
      </GCard>
    </div>
  );
};
