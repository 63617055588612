import { useTranslation } from 'react-i18next';

export const CourseJoinSpots = ({ spots = 0, capacity = 0 }: { spots: number; capacity: number }) => {
  const { t } = useTranslation();

  return (
    <div className={spots > 3 ? 'text-typo-primary' : 'text-interactive-red'}>
      {t('spotsAmount', {
        count: `${capacity - spots}/${capacity}`,
      })}
    </div>
  );
};
