import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  as?: 'label';
  variant?: 'default' | 'secondary';
}

export const GCard = ({ className, as, variant = 'default', ...rest }: Props) => {
  const Wrapper = as || 'div';

  return (
    <Wrapper
      className={twMerge(
        clsx({
          'p-3 rounded-md bg-white border border-gray-200 block': variant === 'default',
          'border border-gray-200 rounded-lg overflow-hidden shadow-sm': variant === 'secondary',
        }),
        className,
      )}
      {...rest}
    />
  );
};

const Content = ({ className, as, ...rest }: Props) => {
  const Wrapper = as || 'div';
  return <Wrapper className={twMerge('', className)} {...rest} />;
};

const Actions = ({ className, as, ...rest }: Props) => {
  const Wrapper = as || 'div';
  return <Wrapper className={twMerge('', className)} {...rest} />;
};

GCard.Content = Content;
GCard.Actions = Actions;
