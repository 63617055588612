import { authJWTState, authTokenSelector, authUserState } from '@/auth/atoms';
import { exchangeSsoToken, refreshToken } from '@/auth/endpoints';
import { useAuth } from '@/auth/hooks/use-auth';
import { setUser } from '@sentry/react';
import { User } from '@/auth/types';
import { getDecodedToken, getLocalToken, hasToken, isTokenValid, onLogout, shouldTokenRefresh } from '@/auth/utils';
import { selectedBusinessUuidState } from '@/business/atoms';
import { LAST_SELECTED_BUSINESS_UUID } from '@/common/constants';
import { getFromStorage, isErrorResponse, setInStorage } from '@/common/utils';
import { GPageLoader } from '@/design-system/v3/g-page-loader';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { FETCH_USER, getUser } from '@/web/endpoints';
import { selectedLocaleState } from '@/i18n/atoms';
import { languageToLocale } from '@/i18n/utils';
import { AxiosError } from 'axios';
import { gaxios } from '@/api';
import { appState } from '@/common/atoms';

export const AuthInit = ({ children }: { children: ReactNode }) => {
  const setAuthUser = useSetRecoilState(authUserState);
  const setAuthToken = useSetRecoilState(authTokenSelector);
  const setAuthJWT = useSetRecoilState(authJWTState);
  const setSelectedBusinessUuid = useSetRecoilState(selectedBusinessUuidState);
  const setSelectedLocale = useSetRecoilState(selectedLocaleState);
  const isRefreshingToken = useRef(false);
  const { pathname } = useLocation();
  const { token, user } = useAuth();

  useQuery(
    [FETCH_USER, token],
    async () => {
      if (!token || !isTokenValid()) {
        return null;
      }

      const userResp: User = await getUser();
      setUser({ email: userResp.email, id: userResp.id });

      setAuthUser(userResp);
      setSelectedLocale(languageToLocale(userResp.language));

      const firstBusinessUuid = userResp?.businesses?.[0]?.businessId;

      if (userResp.businesses.length) {
        let lastBusinessUuid = getFromStorage(LAST_SELECTED_BUSINESS_UUID);

        if (lastBusinessUuid) {
          if (!userResp.businesses.find((bus) => bus.businessId === lastBusinessUuid)) {
            lastBusinessUuid = firstBusinessUuid;
          }
        } else {
          lastBusinessUuid = firstBusinessUuid;
        }
        setInStorage(LAST_SELECTED_BUSINESS_UUID, lastBusinessUuid);
        setSelectedBusinessUuid(lastBusinessUuid);
      }

      return userResp;
    },
    {
      onError: (e) => {
        // Logout the user if the error is an API response error.
        if (isErrorResponse(e) && e.code === AxiosError.ERR_BAD_RESPONSE) {
          return onLogout();
        }

        // Reload the page otherwise.
        window.location.reload();
      },
    },
  );

  useEffect(() => {
    const localStorageToken = getLocalToken();
    const query = new URLSearchParams(window.location.search);
    const ssoToken = query.get('sso_token');

    if (ssoToken) {
      exchangeSsoToken(ssoToken).then((token) => {
        setAuthToken(token);
        setAuthJWT(getDecodedToken());
      });
      return;
    }

    if (isTokenValid()) {
      setAuthToken(localStorageToken);
      setAuthJWT(getDecodedToken());
    }
  }, [setAuthToken, setAuthJWT]);

  useEffect(() => {
    const validationCheck = async () => {
      if (hasToken() && !isTokenValid()) {
        onLogout();
      } else if (shouldTokenRefresh() && !isRefreshingToken.current) {
        isRefreshingToken.current = true;
        const tokenResp = await refreshToken();
        setAuthToken(tokenResp.jwt);
        setAuthJWT(getDecodedToken());
        isRefreshingToken.current = false;
      }
    };

    validationCheck();
  }, [pathname, setAuthToken, setAuthUser, setAuthJWT]);

  if (!user && token) {
    return <GPageLoader withLogo />;
  }

  return <>{children}</>;
};
