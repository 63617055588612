import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: ReactNode;
  variant?: 'success' | 'danger' | 'warning' | 'info' | 'default';
}

export const GChip = ({ className, variant = 'default', label, ...rest }: Props) => {
  const classes = clsx('px-3 py-2 rounded-md leading-none text-xs inline-block', {
    'bg-gray-100 text-typo-primary dark:text-black-10 dark:bg-gray-700': variant === 'default',
    'bg-success-50 text-success-700': variant === 'success',
    'bg-warning-50 text-warning-700': variant === 'warning',
    'bg-blue-50 text-blue-700': variant === 'info',
    'bg-error-50 text-error-700': variant === 'danger',
  });
  return (
    <div className={twMerge(classes, className)} {...rest}>
      {label}
    </div>
  );
};
