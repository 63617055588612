import { useEffect, useState } from 'react';
import { Router } from './common/components/router';
import { SplashScreen } from './common/splash-screen';
import {
  FORGOT_PASSWORD_PATH,
  GUEST_ACTIVATION_PATH,
  INVITATION_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
} from './auth/routes';
import { useLocation } from 'react-router-dom';
import { getDecodedToken } from './auth/utils';
import device from 'current-device';
import { OfflineScreen } from './common/offline-screen';
import { useSetRecoilState } from 'recoil';
import { appState } from '@/common/atoms';
import { LANGUAGES_PATH, ONBOARDING_COMPLETE_PATH, PAYMENT_COMPLETE_PATH } from './web/routes';
import { DesktopAlert } from './web/views/desktop-alert';

const pagesWithDesktopScreen = [
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
  LANGUAGES_PATH,
  FORGOT_PASSWORD_PATH,
  INVITATION_PATH,
  GUEST_ACTIVATION_PATH,
  PAYMENT_COMPLETE_PATH,
  ONBOARDING_COMPLETE_PATH,
  INVITATION_PATH,
];

function App() {
  const [showDesktopAlert, setShowDesktopAlert] = useState(device.desktop() && window.innerWidth > 600);
  const location = useLocation();
  const setAppState = useSetRecoilState(appState);
  const isPageDesktopEnabled = () => {
    if (
      location.pathname.includes('onboarding') ||
      pagesWithDesktopScreen.some((path) => location.pathname.includes(path))
    )
      return true;
    if (location.pathname === '/' && !getDecodedToken()) return true;
    return false;
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--screen-height', `${window.innerHeight}px`);

    const onResize = () => {
      document.documentElement.style.setProperty('--screen-height', `${window.innerHeight}px`);
      setShowDesktopAlert(device.desktop() && window.innerWidth > 600);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    const listener = (nativeEvent) => {
      if (nativeEvent?.data === 'APP_FOREGROUND') {
        setAppState((prev) => ({ ...prev, foreground: true }));
      }

      if (nativeEvent?.data === 'APP_BACKGROUND') {
        setAppState((prev) => ({ ...prev, foreground: false }));
      }
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [setAppState]);

  if (showDesktopAlert && !isPageDesktopEnabled()) {
    return <DesktopAlert />;
  }

  return (
    <OfflineScreen>
      <SplashScreen>
        <Router />
      </SplashScreen>
    </OfflineScreen>
  );
}

export default App;
