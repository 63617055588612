export interface InstructionStepProps {
  step: number;
  title: string;
  description: string;
}

export const InstructionStep = ({ step, title, description }: InstructionStepProps) => (
  <li className="flex p-4 pe-9">
    <div className="flex flex-shrink-0 items-center justify-center h-6 w-6 bg-primary-100 rounded-full text-xs text-secondary-contrast-text font-semibold">
      {step}
    </div>
    <div className="pl-4 overflow-hidden">
      <h3 className="text-base font-semibold">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  </li>
);
