import styled from 'styled-components';
import { Black10 } from './colors';

export const Card1 = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid ${Black10};
  border-radius: 8px;
  padding: 20px;
`;
